import { injectable } from "inversify";
import { action, observable } from "mobx";

@injectable()
export class LoaderPresenter {
    @observable
    public visibility: boolean = false

    @action
    public showLoader() {
        this.visibility = true
    }

    @action
    public hideLoader() {
        this.visibility = false
    }
}