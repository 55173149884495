import { withPresenter } from 'app/withPresenter'
import { LoaderPresenter } from './LoaderPresenter'
import React from 'react'
import { FullScreenWrapper } from 'styles'
import { ThreeDots } from 'react-loader-spinner'
import { colors } from 'styles/colors'
import { observer } from 'mobx-react'

interface IProps {
  presenter: LoaderPresenter
}

@observer
class Loader extends React.Component<IProps> {
  render(): React.ReactNode {
    const { visibility } = this.props.presenter
    return (
      <FullScreenWrapper hidden={!visibility}>
        <ThreeDots
          height="80"
          width="80"
          radius="9"
          color={colors.mainBg}
          ariaLabel="three-dots-loading"
          wrapperStyle={{
            position: 'absolute',
            top: '45%',
            left: '45%'
          }}
          visible={visibility}
        />
      </FullScreenWrapper>
    )
  }
}

export default withPresenter(LoaderPresenter)(Loader)
