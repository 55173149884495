import { inject, injectable } from "inversify";
import { OverviewRepository } from "./OverviewStats/OverviewRepository";
import { toast } from "react-toastify";
import { LoaderPresenter } from "app/Shared/Loader/LoaderPresenter";
import { RecruiterStatsRepository } from "./RecruiterStats/RecruiterStatsRepository";
import { AuthenticationRepository } from "app/Authentication/AuthenticationRepository";
import { TAuthUserRole } from "app/Authentication/TAuthUserRole";
import { MonthlyStatsRepository } from "./MonthlyStats/MonthlyStatsRepository";
import { StatusRepository } from "app/StatusBar/StatusRepository";
import { INestedViewContext } from "app/Header/IModel";
import { HeaderPresenter } from "app/Header/HeaderPresenter";
import { DashboardViews } from "app/Routing/TAvailableView";

@injectable()
export class CompanyStatsPresenter {

    @inject(OverviewRepository)
    private overviewRepository: OverviewRepository

    @inject(RecruiterStatsRepository)
    private recruiterStatsRepository: RecruiterStatsRepository

    @inject(MonthlyStatsRepository)
    private monthlyStatsRepository: MonthlyStatsRepository

    @inject(AuthenticationRepository)
    private authenticationRepository: AuthenticationRepository

    @inject(LoaderPresenter)
    private loaderPresenter: LoaderPresenter

    @inject(StatusRepository)
    private statusRepository: StatusRepository

    @inject(HeaderPresenter)
    private headerPresenter: HeaderPresenter

    public async sync() {
        if (this.authenticationRepository.activeTokenContext.role !== TAuthUserRole.COMPANY_ADMIN) return
        try {
            this.loaderPresenter.showLoader();
            await this.overviewRepository.getStats();
            await this.recruiterStatsRepository.getStats();
            await this.monthlyStatsRepository.getStats();
            // await this.statusRepository.updateCoinCount(null, this.authenticationRepository.activeTokenContext.companyId)
        } catch (err) {
            toast.error(err.message)
        }
        this.loaderPresenter.hideLoader();
    }

    get nestedViewContext(): INestedViewContext {
        return this.headerPresenter.nestViewContext
    }

    get mainView(): DashboardViews {
        return this.authenticationRepository.mainView
    }

    get activeView(): DashboardViews {
        return this.authenticationRepository.activeView
    }

}