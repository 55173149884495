import { Dropdown } from 'app/Shared/Dropdown/Dropdown'
import { Modal } from 'app/Shared/Modal/Modal'
import { TextInput } from 'app/Shared/TextInput/TextInput'
import { withPresenter } from 'app/withPresenter'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AddRecruiterPresenter } from './AddRecruiterPresenter'
import {
  CaptionText,
  InputWrapper,
  Label,
  OptionWrapper,
  RecruiterWrapper
} from '../styles'
import styled from 'styled-components'
import { LottieCheckBox } from 'app/Shared/CheckBoxInput/LottieCheckBox'
import {
  FREELANCE_COMPANY_NAME,
  TAuthUserRole
} from 'app/Authentication/TAuthUserRole'
import CompanyOptions from 'app/ManageComapnies/Shared/CompanyOptions'

interface IProps {
  presenter: AddRecruiterPresenter
}

const ReleaseWrapper = styled.div`
  display: flex;
  align-items: center;
`

const ReleaseHint = styled.div`
  font-size: small;
`

@observer
class AddRecruiter extends React.Component<IProps> {
  onKeyDown = e => {
    if (e.key === 'Escape') {
      this.props.presenter.cancel()
    }
  }

  handleDropdownAdminSelection(select) {}

  render(): React.ReactNode {
    const {
      isOpen,
      selectedRecruiter,
      updateFirstName,
      updateLastName,
      updateEmailAddress,
      updateAnnualTarget,
      updateCompanyName,
      isReleaseOptionsChecked,
      showCompanyOptions,
      canSubmit,
      submitEdit,
      submit,
      cancel
    } = this.props.presenter

    const defaultCompany = {
      label: `${updateCompanyName.value || ''}`,
      value: null
    }

    const isEditMode = selectedRecruiter
    if (!isOpen) return null
    return (
      <Modal
        outsideClickClose={false}
        closeModal={cancel}
        showHeader={true}
        title={`${isEditMode ? 'Edit Recruiter' : 'Add A Recruiter'}`}
        onSaveClick={isEditMode ? submitEdit : submit}
        saveButtonDisabled={!canSubmit}
      >
        <RecruiterWrapper onKeyDown={this.onKeyDown}>
          <CaptionText className={isEditMode && 'text-center'}>
            {isEditMode
              ? 'Edit recruiter details below.'
              : 'Add recruiter details below. Once they’re added, they’ll receive an email invite link to register with Culabr.'}
          </CaptionText>

          <InputWrapper>
            <TextInput
              input={updateFirstName}
              theme="secondary"
              showAsterisk={true}
            />
            <TextInput
              input={updateLastName}
              theme="secondary"
              showAsterisk={true}
            />
          </InputWrapper>
          <InputWrapper>
            <TextInput
              input={updateEmailAddress}
              theme="secondary"
              showAsterisk={true}
            />
          </InputWrapper>
          <InputWrapper>
            <TextInput
              input={updateAnnualTarget}
              theme="secondary"
              showAsterisk={true}
            />
          </InputWrapper>

          <OptionWrapper hidden>
            <Label>
              Company<span>*</span>
            </Label>
            <Dropdown
              default={defaultCompany}
              options={[defaultCompany]}
              onSelect={this.handleDropdownAdminSelection.bind(this)}
            />
          </OptionWrapper>

          {isEditMode &&
            selectedRecruiter.role === TAuthUserRole.RECRUITER &&
            selectedRecruiter.company.name !== FREELANCE_COMPANY_NAME && (
              <ReleaseWrapper>
                <LottieCheckBox
                  isChecked={isReleaseOptionsChecked}
                  onChange={(isChecked: boolean) => {
                    this.props.presenter.updateReleaseOption(isChecked)
                  }}
                />
                <ReleaseHint>
                  Remove this recruiter from this company. <br />
                  This recruiter will be sent to the Freelance group.
                </ReleaseHint>
              </ReleaseWrapper>
            )}
          {isEditMode && showCompanyOptions && <CompanyOptions />}
        </RecruiterWrapper>
      </Modal>
    )
  }
}

export default withPresenter(AddRecruiterPresenter)(AddRecruiter)
