import { ITokenContext } from "app/Authentication/IAuthUser"
import { DashboardViews } from "app/Routing/TAvailableView"
import { IconType } from "react-icons"

export enum HeaderButtonTypes {
    ADD_CANDIDATE = "ADD CANDIDATE",
    ADD_STEP = "ADD STEP",
    ANALYTICS = "ANALYTICS",
    REPORT = "REPORT",
    ADD_COMPANY = "ADD COMPANY",
    RETURN = "Return to Admin Portal",
    ADD_RECRUITER = "ADD RECRUITER",
    EDIT_COMPANY = "EDIT COMPANY",
}

export interface HeaderButton {
    text: HeaderButtonTypes | string
    action: () => any
    allowedRoles: string[]
    allowedView: DashboardViews
    animate?: boolean
    icon?: string
    content?: IconType
    className?: string
    modal?: string
    disabled?: boolean
    hidden?: boolean
}

export interface INestedViewContext {
    view: DashboardViews
    label: string
    sourceView: DashboardViews
    token: string
    tokenContext: ITokenContext
}

export interface INestedViewContextProps {
    isNestedView?: boolean
}