import { AddCandidatePresenter } from 'app/Candidates/AddCandidate/AddCandidatePresenter'
import { JobsListPresenter } from 'app/RightSidePanel/Jobs/JobsListPresenter'
import { Icon } from 'app/Shared/Icon/Icon'
import { container } from 'config/IOC'
import * as React from 'react'
import { DragSource } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'
import Lottie from 'react-lottie'
import styled, { css } from 'styled-components'
import { colors } from 'styles/colors'
import { IFormattedCandidate } from './IFormattedCandidate'
// @ts-ignore
import * as candidateMovedAnimation from 'assets/animations/candidateMovedAnimation.json'
import { observer } from 'mobx-react'
import ActionList from './Actions/ActionList'
import { INestedViewContextProps } from 'app/Header/IModel'

const candidateHoverStyle: React.CSSProperties = {
  backgroundColor: colors.mainBg,
  color: colors.whiteText,
  cursor: 'grab'
}

interface IWrapperProps {
  disabled?: boolean
}

const Wrapper = styled.div<IWrapperProps>`
  width: 100%;
  height: 28px;

  ${(props: IWrapperProps) =>
    props.disabled &&
    css`
      opacity: 0.25;
      pointer-events: none;
    `}
`

const ListItem = styled.div`
  border-radius: 3px;
  line-height: 28px;
  text-align: center;
  padding-inline: ${({ theme }) => theme.spacing(1)};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  cursor: pointer;
  background-color: ${colors.mainBg};
  color: ${colors.whiteText};
`

const CandidateCard = styled.div<IWrapperProps>`
  z-index: 100;
  position: absolute;
  width: 46%;
  border-radius: ${({ theme }) => theme.spacing(1)};
  background-color: ${colors.candidateBg};
`

const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => theme.spacing(1, 2)};
  border-radius: ${({ theme }) => theme.spacing(1, 1, 0, 0)};

  .initialName {
    flex: 1;
    font-size: ${({ theme }) => theme.fontSizes.sm};
  }
`

const CardBody = styled.div`
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.08);
  border-radius: ${({ theme }) => theme.spacing(0, 0, 1, 1)};
`

const animationStyles: React.CSSProperties = {
  margin: '0',
  zIndex: 100,
  position: 'absolute',
  width: '100px',
  height: '100px'
}

const spec = {
  beginDrag(props: IFormattedCandidate) {
    return { ...props }
  },
  canDrag(props: IFormattedCandidate & INestedViewContextProps) {
    if (props.isNestedView) return false
    return props.jobStatus === 'OPEN' || props.stepName === 'Placement'
  }
}

const collect = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging()
})

interface ICandidateState {
  x: number
  y: number
  isHovered: boolean
}

interface IDragProps {
  connectDragSource: (element: React.ReactNode) => React.ReactNode
  connectDragPreview: any
  isDragging: boolean
}

interface ICandidateProps extends IFormattedCandidate {
  index: number
  sourceStepPosition: number
  sourceStepName?: string
  candidateToBeAnimated?: boolean
  jobsListPresenter: JobsListPresenter
}
@observer
class Candidate extends React.Component<
  ICandidateProps & IDragProps & INestedViewContextProps,
  ICandidateState
> {
  public ref = React.createRef()
  public state = {
    x: 0,
    y: 0,
    isHovered: false
  }

  public handleRef = ref => {
    const { connectDragSource } = this.props
    connectDragSource(ref)
    this.ref = ref
  }

  public handleClick(candidateId: string) {
    const candidatePresenter = container.get(AddCandidatePresenter)
    candidatePresenter.openCandidateDetailsDialog(
      candidateId,
      this.props.sourceStepName
    )
  }

  public setCoordinates() {
    const { offsetLeft, offsetTop } = this.ref as any
    const x = offsetLeft - 16
    const y = offsetTop - 38
    this.setState({ x, y })
  }

  public componentDidUpdate(prevProps) {
    if (this.props.index !== prevProps.index) {
      this.setCoordinates()
    }
  }

  public componentDidMount() {
    this.setCoordinates()
    window.addEventListener('resize', this.resizeHandler)
    const { connectDragPreview } = this.props
    if (connectDragPreview) {
      connectDragPreview(getEmptyImage(), {
        captureDraggingState: true
      })
    }
  }
  public componentWillUnmount() {
    window.removeEventListener('resize', this.resizeHandler)
  }

  private resizeHandler = () => {
    this.setCoordinates()
  }

  private handleOnCandidateMouseHover(e) {
    //Avoid hover event when it's triggered based on dnd
    if (e.nativeEvent.buttons) return
    // Avoid hovering on same candidate multiple time
    if (this.state.isHovered) return
    // Avoid if any one of candidates is locked
    // if (this.props.jobsListPresenter.filteredJobId) return

    this.setState({ isHovered: true })
    if (!this.props.jobsListPresenter.isCandidateHoverClearLocked)
      this.props.jobsListPresenter.updateJobFilterId(this.props.jobIdentifier)
  }

  private handleOnCandidateMouseLeave(e) {
    this.setState({ isHovered: false })
    if (!this.props.jobsListPresenter.isCandidateHoverClearLocked)
      this.props.jobsListPresenter.updateJobFilterId(null)
  }

  public render() {
    const {
      initials,
      candidateId,
      isDragging,
      jobStatus,
      stepName
    } = this.props
    const { x, y, isHovered } = this.state

    const {
      filteredJobId,
      isCandidateHoverClearLocked
    } = this.props.jobsListPresenter
    const showCandidateOptions = isHovered

    let wrapperStyle: React.CSSProperties = {
      opacity: isDragging ? 0 : 'unset',
      backgroundColor: this.props.backgroundColor
    }
    let listItemStyle: React.CSSProperties = {
      opacity: isHovered ? 0 : 'unset'
    }
    let cardStyle: React.CSSProperties = {
      top: `${y + 38}px`,
      left: `${x + 16}px`
    }
    let cardHeaderStyle: React.CSSProperties = {
      backgroundColor: this.props.backgroundColor,
      opacity: isHovered ? 0.75 : 'unset'
    }
    if (showCandidateOptions) {
      wrapperStyle = {
        ...candidateHoverStyle,
        ...wrapperStyle,
        ...listItemStyle
      }
      cardHeaderStyle = { ...candidateHoverStyle, ...cardHeaderStyle }
    }

    return (
      <>
        <Wrapper
          disabled={
            isCandidateHoverClearLocked &&
            this.props.jobIdentifier != filteredJobId
          }
          ref={this.handleRef}
          onMouseOver={e => {
            if (this.props.isNestedView) return
            this.handleOnCandidateMouseHover(e)
          }}
          onMouseLeave={e => {
            if (this.props.isNestedView) return
            this.handleOnCandidateMouseLeave(e)
          }}
        >
          <ListItem style={wrapperStyle}>
            {this.props.candidateToBeAnimated && (
              <Lottie
                options={{
                  autoplay: true,
                  loop: false,
                  animationData: candidateMovedAnimation.default
                }}
                style={{
                  ...animationStyles,
                  left: `${x - 25}px`,
                  top: `${y - 45}px`,
                  width: '200px',
                  height: '200px'
                }}
              />
            )}
            {initials}
          </ListItem>
          {showCandidateOptions && !isDragging && (
            <CandidateCard style={cardStyle} className="card">
              <CardHeader style={cardHeaderStyle}>
                <span className="initialName">{initials}</span>
                <Icon
                  extraStyles={{ cursor: 'pointer' }}
                  color={colors.whiteText}
                  name={'edit'}
                  size={24}
                  onClick={() => this.handleClick(candidateId)}
                />
              </CardHeader>
              <CardBody>
                <ActionList
                  isCandidateJobOpen={
                    jobStatus === 'OPEN' || stepName === 'Placement'
                  }
                  hasBorder={true}
                  candidateId={candidateId}
                  showActiveActionsOnly
                />
              </CardBody>
            </CandidateCard>
          )}
        </Wrapper>
      </>
    )
  }
}

export default DragSource<ICandidateProps & INestedViewContextProps>(
  'candidate',
  spec,
  collect
)(Candidate)
