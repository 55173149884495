import { inject, injectable, postConstruct } from "inversify";
import { RecruiterStatsRepository } from "./RecruiterStatsRepository";
import { action, computed, observable, reaction } from "mobx";
import _ from "lodash";
import { IRecruiterStatsDTO } from "../DTO";
import { RecruiterListPresenter } from "app/ManageRecruiters/RecruiterList/RecruiterListPresenter";
import { DashboardViews } from "app/Routing/TAvailableView";

@injectable()
export class RecruiterStatsPresenter {
    @inject(RecruiterStatsRepository)
    private recruiterStatsRepository: RecruiterStatsRepository

    @inject(RecruiterListPresenter)
    private recruiterListPresenter: RecruiterListPresenter

    @observable
    public topTarget: number = 0


    private updateTopTarget(stats: IRecruiterStatsDTO[]) {
        const topTarget = _.maxBy(stats, (item) => item.cumulativeDealFee)
        this.topTarget = topTarget ? topTarget.cumulativeDealFee : 0;
    }

    @postConstruct()
    public init() {
        this.updateTopTarget(this.recruiterStatsRepository.recruiterStats);

        reaction(
            () => this.recruiterStatsRepository.recruiterStats,
            (stats) => { this.updateTopTarget(stats) }
        )
    }

    @action
    public async handleOnViewAsClick(item: IRecruiterStatsDTO) {
        await this.recruiterListPresenter.viewAsRecruiter(
            item.companyId,
            item.userId,
            item.workflowId,
            DashboardViews.MANAGER_DASHBOARD,
            DashboardViews.MANAGER_DASHBOARD
        )
    }

    @computed
    get stats() {
        return this.recruiterStatsRepository.recruiterStats;
    }

    get statsFilter() {
        return this.recruiterStatsRepository.recruiterStatsFilter;
    }
}