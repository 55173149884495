import { AbstractStorageGateway } from './AbstractStorageGateway'
import { injectable } from 'inversify'
import 'reflect-metadata'

@injectable()
export class FakeStorageGateway implements AbstractStorageGateway {
  public getItem(key) {
    return 'some value'
  }

  public setItem(key, value, daysToExpiry) {}

  public removeItem(key) {}
}
