import { inject, injectable } from 'inversify'
import { action, computed } from 'mobx'
import 'reflect-metadata'
import { AddRecruiterPresenter } from '../AddRecruiter/AddRecruiterPresenter'
import { IRecruiter, ISelectedRecruiter } from '../IRecruiter'
import { ManageRecruiterRepository } from '../ManageRecruiterRepository'
import { IPaginationDTO, IPaginationResponseDTO } from 'gateways/service/stubs/dtos'
import moment from 'moment'
import { LoaderPresenter } from 'app/Shared/Loader/LoaderPresenter'
import { toast } from 'react-toastify'
import { TAuthUserRole } from 'app/Authentication/TAuthUserRole'
import { HeaderPresenter } from 'app/Header/HeaderPresenter'
import { DeleteRecruiterPresenter } from '../DeleteRecruiter/DeleteRecruiterPresenter'
import { DashboardViews } from 'app/Routing/TAvailableView'
const jwt = require('jsonwebtoken')

@injectable()
export class RecruiterListPresenter {
  @inject(ManageRecruiterRepository)
  private manageRecruiterRepository: ManageRecruiterRepository

  @inject(AddRecruiterPresenter)
  private addRecruiterPresenter: AddRecruiterPresenter

  @inject(DeleteRecruiterPresenter)
  private deleteRecruiterPresenter: DeleteRecruiterPresenter

  @inject(LoaderPresenter)
  private loaderPresenter: LoaderPresenter

  @inject(HeaderPresenter)
  private headerPresenter: HeaderPresenter

  @computed
  public get recruiterList(): IRecruiter[] {
    return this.manageRecruiterRepository.recruiterList
  }

  @computed
  public get pagination(): IPaginationResponseDTO {
    return this.manageRecruiterRepository.pagination
  }

  public get isInitialDataLoaded() {
    return this.manageRecruiterRepository.isInitialDataLoaded
  }

  @computed
  public get recruiter() {
    return this.manageRecruiterRepository.recruiterList.map(r => {
      const formattedDate = moment(r.create_date).format('DD/MM/YYYY')

      const formattedAnnualTarget = r.annual_target || 0

      return {
        firstName: r.firstName,
        lastName: r.lastName,
        email: r.email,
        annualTarget: formattedAnnualTarget,
        companyName: r.company.name,
        createDate: formattedDate,
        id: r.id,
        companyId: r.company.id,
        workflowId: r.workflowId,
        company: r.company,
        role: r.role
      }
    })
  }

  @action
  public async loadRecruiter(pagination: IPaginationDTO) {
    this.loaderPresenter.showLoader()
    try {
      this.manageRecruiterRepository.loadRecruiter(pagination.start, pagination.end)
    } catch (err) {
      toast.error(err.message)
    }
    this.loaderPresenter.hideLoader()
  }

  @action
  public handleRecruiterEditButton(recruiter: ISelectedRecruiter) {
    this.addRecruiterPresenter.setRecruiter(recruiter)
    this.addRecruiterPresenter.toggle()
  }

  @action
  public async handleOnViewAsClick(recruiter: ISelectedRecruiter) {
    await this.viewAsRecruiter(
      recruiter.companyId,
      recruiter.id,
      recruiter.workflowId,
      DashboardViews.MANAGE_RECRUITERS,
      DashboardViews.MANAGE_RECRUITERS
    )
  }

  @action
  public async viewAsRecruiter(
    companyId: number,
    userId: number,
    workflowId: string,
    label: string,
    sourceView: DashboardViews
  ) {
    this.loaderPresenter.showLoader()
    try {
      const tokenContext = {
        companyId: companyId,
        userId: userId,
        role: TAuthUserRole.RECRUITER,
        workflowId: workflowId
      };
      const result = await this.manageRecruiterRepository.getViewAsToken(tokenContext)
      if (!result.success) return
      const token = result.token
      this.headerPresenter.setNestedView({
        view: DashboardViews.MY_DASHBOARD,
        sourceView,
        token,
        tokenContext: jwt.decode(token),
        label
      })
    } catch (err) {
      toast.error(err.message)
    }
    this.loaderPresenter.hideLoader()
  }

  @action
  public handleRecruiterDeleteButtonClick(recruiter: ISelectedRecruiter) {
    this.deleteRecruiterPresenter.toggle(recruiter.id)
  }
}