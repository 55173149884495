import { inject, injectable, postConstruct } from 'inversify'
import 'reflect-metadata'
import { RoutingRepository } from "app/Routing/RoutingRepository"
import { action, computed, observable } from "mobx"
import { IAuthTokenPayload } from "app/Authentication/IAuthTokenPayload";
import { RegisterVerificationRepository } from "app/Authentication/RegisterVerification/RegisterVerificationRepository";

const jwt = require('jsonwebtoken')

@injectable()
export class RegisterVerificationPresenter {
  @inject(RoutingRepository)
  private routingRepository: RoutingRepository

  @inject(RegisterVerificationRepository)
  private registerVerificationRepository: RegisterVerificationRepository

  @postConstruct()
  public init() {
    const token = new URLSearchParams(window.location.search).get('token')

    this.setUserData(jwt.decode(token))

    this.registerVerificationRepository
      .verify(token)
      .then(
        response => {
          if (response.success) {
            this._isSuccess = true
          } else {
            this._isSuccess = false
            this.errorMessage = response.message
          }
        },
        (error) => {
          this._isSuccess = false
          this.errorMessage = error.message
        })
  }

  @observable
  public userData: IAuthTokenPayload

  @observable
  public errorMessage: string

  @observable
  private _isSuccess: boolean = null

  @computed
  public get isSuccess(): boolean {
    return this._isSuccess
  }

  @computed
  public get isManagerVerification(): boolean {
    return false
    // try {
    //   return this.userData.roles.filter(role => role === 'manager').length > 0
    // } catch (err) {
    //   this.routingRepository.goForward('login')
    // }
  }

  @action
  public submit = (): void => {
    this.routingRepository.goForward('login')
  }

  public setUserData = (data: IAuthTokenPayload): void => {
    this.userData = data
  }
}
