import { observable, action, reaction } from 'mobx'
import { inject, injectable, postConstruct } from 'inversify'
import 'reflect-metadata'
import { ICandidate } from 'app/Candidates/ICandidate'
import { IStep } from 'app/Steps/IStep'
import { AuthenticationRepository } from 'app/Authentication/AuthenticationRepository'
import { IActionsResponseDto, IStepsResponseDto } from 'gateways/service/stubs/dtos'
import { AbstractServiceGateway } from 'gateways/service/AbstractServiceGateway'
import { StatusRepository } from 'app/StatusBar/StatusRepository'
import { STEP_CANDIDATE_LIMIT } from 'config/constants'
import { IAction } from 'app/Actions/IAction'

@injectable()
export class StepsRepository {

  @inject(AuthenticationRepository)
  private authenticationRepository: AuthenticationRepository

  @inject(AbstractServiceGateway)
  private serviceGateway: AbstractServiceGateway

  @inject(StatusRepository)
  private coinsRepository: StatusRepository

  @postConstruct()
  public init() {
    this.loadSteps(
      this.authenticationRepository.authenticated,
      this.authenticationRepository.user.workflowId
    )
    reaction(
      () => this.authenticationRepository.authenticated,
      authenticated =>
        this.loadSteps(authenticated, this.authenticationRepository.user.workflowId)
    )
  }

  @observable
  public steps: IStep[] = []

  @observable
  public addingStep: boolean = false

  @observable
  public deletingStep: boolean = false

  @observable
  public totalCandidates: number = 0;

  @observable
  public totalCandidatesByStepName: Map<string, number> = new Map<string, number>();

  @action
  public async loadSteps(authenticated: boolean, workflowId: string) {
    if (authenticated && workflowId) {
      const response = await this.serviceGateway.get<IStepsResponseDto>(`/steps?workflowId=${workflowId}`)
      this.totalCandidates = 0;
      this.totalCandidatesByStepName.clear();
      this.steps = response.result.map(stepDto => {
        const customBool = stepDto.custom ? true : false
        let candidates = Array.isArray(stepDto.candidates) ? stepDto.candidates.length : 0;
        this.totalCandidates += candidates;
        this.totalCandidatesByStepName.set(stepDto.name, candidates);
        return {
          entryActions: stepDto.entryActions,
          exitActions: [],
          name: stepDto.name,
          stepColor: stepDto.stepColor,
          stepId: stepDto.stepId,
          candidates: stepDto.candidates,
          position: stepDto.position,
          custom: customBool
        } as IStep
      })
    }
  }

  @action
  public initiateCandidate = (candidate: ICandidate) => {
    this.steps[0].candidates.push(candidate)
  }

  @action
  public updateCandidate = (candidate: ICandidate) => {
    const step = this.steps.find((step: IStep) => {
      const { stepId } = step
      return stepId === candidate.stepId
    })

    const stepIndex = this.steps.indexOf(step)
    this.steps[stepIndex].candidates = this.steps[stepIndex].candidates
      .map(c => c.candidateId === candidate.candidateId ? candidate : c)
  }

  @action
  public async addStep() {
    const authenticated = true
    if (authenticated) {
      if (!this.addingStep) {
        this.addingStep = true
        const response = await this.serviceGateway.post<any>('/add-step', {
          workflowId: this.authenticationRepository.user.workflowId
        })
        this.steps = response.result
        this.addingStep = false
      }
    }
  }

  @action
  public async moveCandidate(candidateId: string, toStepId: string): Promise<IAction[]> {
    const fromStepId = this.steps.find((step: IStep) => {
      const { candidates } = step
      return !!candidates.find(
        fromStepCandidate => fromStepCandidate.candidateId === candidateId
      )
    }).stepId

    if (fromStepId === toStepId) return

    const toStep = this.steps.find(step => step.stepId === toStepId)

    if (toStep.candidates.length > STEP_CANDIDATE_LIMIT) return

    const fromStep = this.steps.find(step => step.stepId === fromStepId)

    const candidate = fromStep.candidates.find(
      fromStepCandidate => fromStepCandidate.candidateId === candidateId
    )

    this.steps = this.steps.map(step => {
      if (step.stepId === fromStepId) {
        const candidates = step.candidates.filter(stepCandidate => {
          return stepCandidate.candidateId !== candidateId
        })
        return { ...step, candidates }
      } else if (step.stepId === toStepId) {
        const candidates = step.candidates.concat(candidate)
        return { ...step, candidates }
      } else {
        return step
      }
    })

    const requestDto = {
      candidateId,
      toStepId,
      workflowId: this.authenticationRepository.user.workflowId
    }
    const response = await this.serviceGateway.post<IActionsResponseDto>('/move-candidate', requestDto)
    await this.coinsRepository.updateCoinCount()
    return response.result;
  }

  public async deleteStep(stepId: string) {
    if (!this.deletingStep) {
      this.deletingStep = true
      const response: any = await this.serviceGateway.delete<any>(`/delete-step?stepId=${stepId}`)
      this.steps = response.result
      this.deletingStep = false
    }
  }
}
