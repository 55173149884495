import { Button } from 'app/Shared/Button/Button'
import { Icon } from 'app/Shared/Icon/Icon'
import { withPresenter } from 'app/withPresenter'
import { toJS } from 'mobx'
import { observer } from 'mobx-react'
import React from 'react'
import { toast } from 'react-toastify'
import styled, { css } from 'styled-components'
import { colors } from 'styles/colors'
import { IJob } from './IJobs'
import { JobsListPresenter } from './JobsListPresenter'
import { AddJob } from './child/AddJob'
import { INestedViewContextProps } from 'app/Header/IModel'

const Wrapper = styled.div`
  min-height: 100%;
  box-sizing: border-box;
  grid-column: 2;
  grid-row: 1;
  display: flex;
  flex-direction: column;
  color: ${colors.darkText};
`

const ButtonWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing(2)};

  button {
    width: 100%;
  }
`

const JobsListWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing(0, 1)};
`

const Column = styled.div`
  padding: ${({ theme }) => theme.spacing(2, 1)};

  button {
    margin: 0;
  }

  &.actions {
    padding: ${({ theme }) => theme.spacing(1)};
  }
`

const RowHeader = styled.div`
  display: grid;
  grid-template-columns: 215px minmax(40px, 80px) minmax(40px, 80px);
  grid-row-gap: 4px;
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: 600;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: ${({ theme }) => theme.spacing(1)};
    right: ${({ theme }) => theme.spacing(1)};
    bottom: 0;
    height: 1px;
    background-color: ${colors.border};
  }
`

interface IRowProps {
  highlight: boolean
}
const Row = styled.div<IRowProps>`
  display: grid;
  cursor: pointer;
  grid-template-columns: 215px minmax(40px, 80px) minmax(40px, 80px);
  align-items: center;
  margin-bottom: 4px;
  border-radius: ${({ theme }) => theme.spacing(1)};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: ${colors.blackBg};

  &:hover {
    background-color: rgb(${colors.rowHoverRgbBg} / 0.3);
  }

  ${(props: IRowProps) =>
    props.highlight &&
    css`
      background-color: rgb(${colors.rowHoverRgbBg} / 0.3);

      &:hover {
        background-color: rgb(${colors.rowHoverRgbBg} / 0.45);
      }
    `}
`

interface IProps {
  presenter: JobsListPresenter
}

interface IState {
  showCreateJobModal: boolean
  activeJob?: IJob
  inEditMode: boolean
}
@observer
class JobsList extends React.Component<IProps & INestedViewContextProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      showCreateJobModal: false,
      inEditMode: false
    }
  }

  toggleAddJobView(activeJob?: IJob) {
    this.setState({
      showCreateJobModal: !this.state.showCreateJobModal,
      activeJob: activeJob,
      inEditMode: Boolean(activeJob)
    })
  }

  handleOnJobEditIconClick(job: IJob) {
    this.toggleAddJobView(toJS(job))
  }

  handleErrorMessage(errorMessage) {
    if (errorMessage) {
      toast.error(errorMessage)
      return
    }
    this.toggleAddJobView()
  }

  async createJob(jobName: string) {
    const errorMessage = await this.props.presenter.createJob(jobName)
    this.handleErrorMessage(errorMessage)
  }

  async handleOnJobUpdateClick() {
    const errorMessage = await this.props.presenter.updateJob(
      this.state.activeJob
    )
    this.handleErrorMessage(errorMessage)
  }

  async handleOnJobDeleteClick() {
    const errorMessage = await this.props.presenter.deleteJob(
      this.state.activeJob
    )
    this.handleErrorMessage(errorMessage)
  }

  toggleJobFilter(job: IJob) {
    let filterJobId = job.id
    if (this.props.presenter.filteredJobId == filterJobId) filterJobId = null
    this.props.presenter.updateJobFilterId(filterJobId, filterJobId != null)
  }

  render(): React.ReactNode {
    const { filteredJobId } = this.props.presenter
    return (
      <Wrapper>
        {!this.props.isNestedView && (
          <ButtonWrapper>
            <Button
              text="ADD A JOB"
              extraStyles={'margin : 0'}
              onClick={() => this.toggleAddJobView()}
            />
          </ButtonWrapper>
        )}
        <JobsListWrapper>
          <RowHeader>
            <Column>Name</Column>
            <Column>CVs</Column>
            <Column />
          </RowHeader>

          {this.props.presenter.jobs.map((item: IJob, index) => (
            <Row key={index} highlight={item.id === filteredJobId}>
              <Column onClick={() => this.toggleJobFilter(item)}>
                {item.name}
              </Column>
              <Column>{item.cvCounts}</Column>
              {!this.props.isNestedView && (
                <Column className="actions">
                  <Button
                    size={24}
                    theme="iconButton"
                    onClick={() => this.handleOnJobEditIconClick(item)}
                    text={
                      <Icon
                        extraStyles={{ color: 'white' }}
                        color={colors.darkText}
                        name={'edit'}
                        size={24}
                      />
                    }
                    //onClick={editJob}
                  />
                </Column>
              )}
            </Row>
          ))}
        </JobsListWrapper>

        {/* TODO implement job list logic this.props.presenter.jobs */}

        {this.state.showCreateJobModal && (
          <AddJob
            isEditActivity={this.state.inEditMode}
            handleOnDeleteClick={this.handleOnJobDeleteClick.bind(this)}
            jobName={this.state.activeJob ? this.state.activeJob.name : ''}
            handleOnCancelClick={this.toggleAddJobView.bind(this)}
            handleOnSubmitClick={name => {
              if (this.state.inEditMode) {
                this.state.activeJob.name = name
                this.handleOnJobUpdateClick()
                return
              }
              this.createJob(name)
            }}
          />
        )}
      </Wrapper>
    )
  }
}

export default withPresenter<INestedViewContextProps>(JobsListPresenter)(JobsList)
