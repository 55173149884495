import * as React from 'react'
import styled from 'styled-components'
import { TStepStyle } from 'app/Steps/StepsPanel/IFormattedStep'
import {colors} from "styles/colors";

interface IProps {
  color: string
  isOver: boolean
  column: number
  row: number
  width: number
}

const Border = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: ${(props: IProps) => props.width - 30}px;
  display: flex;
  ${(props: IProps) => {
    return `
      grid-column: ${props.column};
      grid-row: ${props.row};
    `
  }};
`

const Wrapper = styled.div`
  div {
    border: 1px solid;
    position: absolute;
    top: 100px;
    z-index: 50;
    left: -20px;
    border: 1px dashed ${colors.borderFocus}
  }
`

const HideBorder = styled.div`
  display: none;
`

const Mid = styled.div`
  width: 15px !important;
`

interface IProps {
  color: string
  isOver: boolean
  column: number
  row: number
  width: number
  stepStyle: TStepStyle
}

export const StepWrapper: React.FunctionComponent<IProps> = props => {
  const { color, isOver, column, row, width, stepStyle, children } = props

  return (
    <Border
      width={width}
      color={color}
      isOver={isOver}
      column={column}
      row={row}
      stepStyle={stepStyle}
    >
      {stepStyle && (
        <Wrapper>
          {stepStyle == 'mid' && <Mid />}
          {stepStyle == 'corR' && <Mid />}
          {stepStyle == 'corL' && <HideBorder />}
          {stepStyle == 'last' && column > 1 && <Mid />}
        </Wrapper>
      )}
      {children}
    </Border>
  )
}
