import AddFeedback from 'app/Feedback/AddFeedback/AddFeedback'
import { HeaderPresenter } from 'app/Header/HeaderPresenter'
import AddCompany from 'app/ManageComapnies/AddCompany/AddCompany'
import AddRecruiter from 'app/ManageRecruiters/AddRecruiter/AddRecruiter'
import { Button } from 'app/Shared/Button/Button'
import { Dropdown } from 'app/Shared/Dropdown/Dropdown'
import { TIPropsName } from 'app/Shared/Icon/Icon'
import { withPresenter } from 'app/withPresenter'
import { STEP_AND_ACTION_HEADER_HEIGHT } from 'config/constants'
import { toJS } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import styled from 'styled-components'
import { colors } from 'styles/colors'
import AddCandidate from '../Candidates/AddCandidate/AddCandidate'
import EditCompany from 'app/ManageComapnies/EditCompany/EditCompany'
import DeleteCompany from 'app/ManageComapnies/DeleteCompany/DeleteCompany'
import RecruiterEditCompany from 'app/ManageRecruiters/EditCompany/RecruiterEditCompany'
import { TAuthUserRole } from 'app/Authentication/TAuthUserRole'
import DeleteRecruiter from 'app/ManageRecruiters/DeleteRecruiter/DeleteRecruiter'
import { TextInput } from 'app/Shared/TextInput/TextInput'
// @ts-ignore
import * as backwardArrow from 'assets/backwaredArrow.svg'
import { DashboardViews } from 'app/Routing/TAvailableView'

const Wrapper = styled.div`
  display: flex;
  height: ${STEP_AND_ACTION_HEADER_HEIGHT}px;
  padding-inline: 20px 28px;
  background: ${colors.whiteBg};
  gap: 8px;
  align-items: center;
`

const EmptySpacer = styled.div`
  flex: 1;
`

const SearchFilter = styled.div`
  min-width: 20%;
  height: 100%;
  display: flex;
  align-items: center;
`

const ButtonWrapper = styled.div`
  display: inline-block;
  position: relative;

  &.feedback {
    margin-left: auto;
    padding-left: 24px;
    // border-left: 1px solid ${colors.border};
  }

  .feedback-content {
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #17b8cd;
    text-align: center;
    svg {
      color: white;
      vertical-align: middle;
      height: 100%;
      font-size: 1.4rem;
    }
  }

  button {
    margin-left: 0;
  }
`
const DropdownWrapper = styled.div`
  max-width: 250px;
  min-width: 250px;
  align-self: center;
  margin-top: 15px;
  margin-right: 8px;
`
const BackLabel = styled.div``

const BackWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  img {
    width: 40px;
    height: 40px;
  }
`

const modalMap = {
  addCandidate: <AddCandidate />,
  feedback: <AddFeedback />,
  addCompany: <AddCompany />,
  addRecruiter: <AddRecruiter />
}

interface IProps {
  presenter: HeaderPresenter
}

const Header = observer((props: IProps) => {
  const {
    buttons,
    mainView,
    adminDropdownOptions,
    tokenContext,
    nestViewContext,
    activeCompanyName
  } = props.presenter
  return (
    <>
      <Wrapper>
        {!nestViewContext && adminDropdownOptions.length > 1 && (
          <DropdownWrapper>
            <Dropdown
              default={{ label: mainView, value: mainView }}
              options={toJS(adminDropdownOptions).map(i => ({
                label: i,
                value: i
              }))}
              onSelect={opt =>
                props.presenter.renderUIBasedOnDropdownSelection(opt.value)
              }
            />
          </DropdownWrapper>
        )}
        {nestViewContext && (
          <BackWrapper onClick={() => props.presenter.switchToSourceView()}>
            <img src={backwardArrow} />
            <BackLabel>{nestViewContext.label}</BackLabel>
          </BackWrapper>
        )}
        {buttons.map(
          ({
            text,
            action,
            icon,
            modal,
            disabled,
            className = '',
            animate,
            hidden = false,
            content
          }) => {
            return (
              <ButtonWrapper className={className} key={text}>
                {content && (
                  <div
                    title={text}
                    className={`${className}-content`}
                    onClick={action}
                    hidden={hidden}
                  >
                    {React.createElement(content)}
                  </div>
                )}
                {!content && (
                  <Button
                    className={`${className}-button`}
                    text={text}
                    size={0}
                    icon={{
                      color: colors.white,
                      name: icon as TIPropsName,
                      size: 10
                    }}
                    onClick={action}
                    disabled={disabled}
                    animate={animate}
                    extraStyles={'margin-left : 10px'}
                    hidden={hidden}
                  />
                )}
                {modal && modalMap[modal]}
              </ButtonWrapper>
            )
          }
        )}

        {mainView === DashboardViews.MANAGE_COMPANIES && !nestViewContext && (
          <>
            <EmptySpacer />
            <SearchFilter>
              <TextInput
                theme="secondary"
                input={{
                  label: 'Company Name',
                  value: activeCompanyName,
                  onChange: value => props.presenter.companyName(value)
                }}
                extraStyles={'width: 200px;'}
                wrapperExtraStyles={'margin-bottom: 0 !important;'}
              />
              <Button
                text={'Filter'}
                size={0}
                onClick={() => props.presenter.applyFilter()}
                extraStyles={'margin-left : 10px'}
              />
            </SearchFilter>
          </>
        )}
      </Wrapper>
      {tokenContext && tokenContext.role === 'SUPER_ADMIN' && (
        <>
          <EditCompany />
          <DeleteCompany />
        </>
      )}

      {tokenContext && tokenContext.role === TAuthUserRole.COMPANY_ADMIN && (
        <>
          <RecruiterEditCompany />
        </>
      )}
      <DeleteRecruiter />
    </>
  )
})

export default withPresenter(HeaderPresenter)(Header)
