import {
  LOGO_HEADER_HEIGHT,
  STEP_AND_ACTION_HEADER_HEIGHT
} from 'config/constants'
import * as React from 'react'
import styled from 'styled-components'
import { colors } from 'styles/colors'
import { Icon } from '../Icon/Icon'

interface ITableProps {
  columns: string[]
  fields: string[]
  start: number
  end: number
  totalRow: number
  hasActionCol?: boolean
  actionColWidth?: string
  onNextPage?: () => void
  onPrevPage?: () => void
  onLastPage?: () => void
  onFirstPage?: () => void
  hasNextPage?: boolean
}

const extraHeight = LOGO_HEADER_HEIGHT + STEP_AND_ACTION_HEADER_HEIGHT + 105

const TableWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const TableContainer = styled.div`
  border-radius: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => theme.spacing(2)};
  background: ${colors.whiteBg};
  box-shadow: 0 3px 8px -2px rgba(0, 0, 0, 0.4);
  //height: calc(100% - 48px);
  box-sizing: border-box;
  flex: 1;
`

const TableScroll = styled.div`
  height: 100%;
  max-height: calc(100vh - ${extraHeight}px);
  overflow-y: scroll;
  position: relative;
  padding-right: 8px;

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${colors.blackBg}40;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: ${colors.blackBg}4d;
  }
`

const TableContent = styled.table`
  width: 100%;
  vertical-align: top;
  border-collapse: collapse;
  color: ${colors.titleText};
`

const TableHeaderWrapper = styled.thead`
  vertical-align: bottom;
  position: sticky;
  top: 0;
  background-color: ${colors.whiteBg};
  box-shadow: 0 1px ${colors.border};
`

const TableHeader = styled.tr``

const TableHeaderCell = styled.th<{ actionColWidth?: string }>`
  font-size: ${({ theme }) => theme.fontSizes.xs};
  color: ${colors.lightText};
  text-transform: uppercase;
  font-weight: 400;
  padding-bottom: ${({ theme }) => theme.spacing(3)};
  text-align: start;

  &.text-center {
    text-align: center;

    width: ${({ actionColWidth }) => actionColWidth || 'auto'};
  }
`

const TableBody = styled.tbody``

const PaginNav = styled.nav`
  height: ${({ theme }) => theme.spacing(4)};
  display: flex;
  gap: ${({ theme }) => theme.spacing(1)};
  justify-content: flex-end;
  align-items: center;
  padding-top: ${({ theme }) => theme.spacing(2)};
`
const PaginInfo = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.xs};
  min-width: 100px;
`
const Pagination = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(3)};
`

export class Table extends React.Component<ITableProps> {
  public render() {
    const {
      children,
      columns,
      start,
      end,
      totalRow,
      hasActionCol,
      actionColWidth,
      onNextPage,
      onPrevPage,
      onLastPage,
      onFirstPage,
      hasNextPage
    } = this.props

    const hasPrevPage = start !== 0

    return (
      <TableWrapper>
        <TableContainer>
          <TableScroll>
            <TableContent>
              <TableHeaderWrapper>
                <TableHeader>
                  {columns.map((column, index) => (
                    <TableHeaderCell key={index}>{column}</TableHeaderCell>
                  ))}
                  {hasActionCol && (
                    <TableHeaderCell
                      actionColWidth={actionColWidth}
                      className="text-center"
                    >
                      Actions
                    </TableHeaderCell>
                  )}
                </TableHeader>
              </TableHeaderWrapper>
              <TableBody>{children}</TableBody>
            </TableContent>
          </TableScroll>
        </TableContainer>
        <PaginNav>
          <PaginInfo>
            {start}-{end > totalRow ? totalRow : end} of {totalRow}
          </PaginInfo>
          <Pagination>
            <Icon
              extraStyles={{
                cursor: !hasPrevPage ? 'default' : 'pointer',
                opacity: !hasPrevPage ? '.5' : 'unset',
                pointerEvents: !hasPrevPage ? 'none' : 'auto'
              }}
              color={colors.greyText}
              name={'backward'}
              onClick={onFirstPage}
            />
            <Icon
              extraStyles={{
                cursor: !hasPrevPage ? 'default' : 'pointer',
                opacity: !hasPrevPage ? '.5' : 'unset',
                pointerEvents: !hasPrevPage ? 'none' : 'auto'
              }}
              color={colors.greyText}
              name={'previous'}
              onClick={onPrevPage}
            />
            <Icon
              extraStyles={{
                cursor: !hasNextPage ? 'default' : 'pointer',
                opacity: !hasNextPage ? '.5' : 'unset',
                pointerEvents: !hasNextPage ? 'none' : 'auto'
              }}
              color={colors.greyText}
              name={'next'}
              onClick={onNextPage}
            />
            <Icon
              extraStyles={{
                cursor: !hasNextPage ? 'default' : 'pointer',
                opacity: !hasNextPage ? '.5' : 'unset',
                pointerEvents: !hasNextPage ? 'none' : 'auto'
              }}
              color={colors.greyText}
              name={'forward'}
              onClick={onLastPage}
            />
          </Pagination>
        </PaginNav>
      </TableWrapper>
    )
  }
}
