import * as React from 'react'
import styled, { css } from 'styled-components'
import { colors } from 'styles/colors'
import { ITextInput } from './ITextInput'

const StyledInput = styled.input<IProps>`
  background-color: ${colors.white} !important;
  color: ${colors.blackText};
  border: none;
  border-bottom: 2px solid ${colors.border};
  height: 35px;
  width: 100%;
  line-height: 20px;
  font-size: 14px;
  box-sizing: border-box;

  &:focus {
    outline: none;
    border-bottom: 1px solid ${colors.borderFocus};
  }

  /* &:first-child {
    margin-right: 20px;
  } */

  border-bottom: ${({
    isDirty,
    isValid
  }: {
    isDirty: boolean
    isValid: boolean
  }) =>
    isDirty
      ? isDirty && !isValid
        ? `1px solid ${colors.borderError}`
        : `1px solid ${colors.borderSuccess}`
      : `1px solid ${colors.border}`};

  ${props =>
    props.theme === 'secondary' &&
    css`
      width: 380px;
    `}
  ${(props: Partial<IProps>) => props.extraStyles};
`

const StyledControl = styled.div<Partial<IProps>>`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.spacing(3)};
  flex: 1 0 0%;
  ${(props: Partial<IProps>) => props.wrapperExtraStyles};
`

const StyledLabel = styled.label`
  text-align: left;
  font-size: ${({ theme }) => theme.fontSizes.xs};
  color: ${colors.darkText};
  line-height: 1.25;

  span {
    font-size: ${({ theme }) => theme.fontSizes.de};
    color: ${colors.errorText};
    line-height: 0.75;
  }
`

interface IProps {
  value?: string
  theme?: string
  extraStyles?: {}
  wrapperExtraStyles?: string
  input: ITextInput
  showAsterisk?: boolean
}

export const TextInput: React.FunctionComponent<IProps> = props => {
  const { theme, extraStyles, showAsterisk = false, wrapperExtraStyles } = props

  const {
    label,
    value,
    placeholder,
    onChange,
    type = 'text',
    isDirty,
    isValid,
    autoComplete
  } = props.input

  const onChangeHandler = e => {
    onChange(e.target.value)
  }

  return theme !== 'secondary' ? (
    <StyledInput
      {...{
        value,
        onChange: onChangeHandler,
        placeholder,
        extraStyles,
        type,
        autoComplete
      }}
    />
  ) : (
    <StyledControl wrapperExtraStyles={wrapperExtraStyles}>
      <StyledLabel>
        {label}
        {showAsterisk && <span>*</span>}
      </StyledLabel>
      <StyledInput
        {...{
          theme,
          value,
          onChange: onChangeHandler,
          placeholder,
          extraStyles,
          type,
          isDirty,
          isValid,
          autoComplete
        }}
      />
    </StyledControl>
  )
}
