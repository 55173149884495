import { inject, injectable } from 'inversify'
import 'reflect-metadata'
import { StepsPresenter } from "app/Steps/StepsPanel/StepsPresenter";
import { ActionPresenter } from '../Actions/ActionPresenter';

@injectable()
export class DeleteCandidatePresenter {

  @inject(StepsPresenter)
  private stepsPresenter: StepsPresenter

  @inject(ActionPresenter)
  private actionPresenter: ActionPresenter

  public close = () => {
    this.stepsPresenter.closeDeleteDialog()
    this.actionPresenter.closeDeleteDialog()
  }

  public submit = async (candidateId: string) => {
    if (this.actionPresenter.candidateToBeRejected) return this.actionPresenter.invokeRejectAPI(candidateId)
    this.stepsPresenter.deleteCandidate(candidateId)
  }
}

