import * as React from 'react'
import styled, {css} from 'styled-components'
import {colors} from "styles/colors";
import {Icon} from "app/Shared/Icon/Icon";

interface IMenuContentProps {
  isOpen: boolean
}

const MenuWrapper = styled.div``

const MenuButton = styled.div`
  span {
    width: auto;
    height: auto;
    padding: 4px 2px;
    border: 1px solid ${colors.border};
    border-radius: 5px;
    cursor: pointer;
    
    svg {
      height: 11px;
      padding: 4px 0 0;
    }
    
    &:hover {
      background: ${colors.hoverBg}
    }
`

const MenuContent = styled.div`
  display: none;
  color: ${colors.darkText}
  cursor: pointer;
  background: ${colors.whiteBg};
  border-radius: 5px;
  z-index: 10;
  width: 220px;
  text-transform: capitalize;
  box-shadow: 0 3px 8px -2px rgba(0, 0, 0, .4);
  
  ${(props: IMenuContentProps) => props.isOpen && css`display: block;`}
`

const MenuItem = styled.div`
  padding: 8px 16px;
  letter-spacing: 1px !important;

  border-bottom: 1px solid ${colors.border};
  
  &:hover {
    cursor: pointer;
    background: ${colors.hoverBg};
  }
  
  &:first-child {
    border-radius: 5px 5px 0 0;
  }
  
  &:last-child {
    border-radius: 0 0 5px 5px;
    border-bottom: 0;
  }
`

interface IProps {
  itemList: { key: string, value: string }[]
  onClick: (key: string) => void
}

export class Menu extends React.Component<IProps, {}> {
  public isOpen = false

  public toggleMenu = () => {
    this.isOpen = !this.isOpen
    this.forceUpdate()
  }

  public selectOrder = (key: string) => {
    this.isOpen = !this.isOpen
    this.props.onClick(key)
    this.forceUpdate()
  }

  public render() {
    const {itemList} = this.props

    return (
      <MenuWrapper>

        <MenuButton onClick={() => this.toggleMenu()}>
          <Icon
            name={'arrow'}
            color={colors.darkText}
            extraStyles={{transform: this.isOpen ? 'rotate(180deg)' : 'rotate(0deg)'}}>
          </Icon>
        </MenuButton>

        <MenuContent isOpen={this.isOpen}>
          {itemList.map((item, i) => (
            <MenuItem key={i} onClick={() => this.selectOrder(item.key)}>{item.value}</MenuItem>
          ))}
        </MenuContent>
      </MenuWrapper>
    )
  }
}
