import { AbstractClassValidator } from '@logicroom/validator'
import { inject, injectable } from 'inversify'
import { action, observable } from 'mobx'
import { CompanyListRepository } from '../CompanyList/CompanyListRepository'
import { ICompany } from '../ICompany'
import { LoaderPresenter } from 'app/Shared/Loader/LoaderPresenter'
import {
  ICompanyUsersResponse,
  IEditCompanyRequestDTO
} from 'gateways/service/stubs/dtos'
import { DropdownOption } from 'app/Shared/Dropdown/Dropdown'
import { toast } from 'react-toastify'

type TDropdownOptions = DropdownOption<ICompanyUsersResponse>
@injectable()
export class EditCompanyPresenter extends AbstractClassValidator {
  serverErrors?: string[]

  @inject(CompanyListRepository)
  private manageCompanyRepository: CompanyListRepository

  @inject(LoaderPresenter)
  private loaderPresenter: LoaderPresenter

  @observable
  public isOpen: boolean = false

  @observable
  public activeCompany: ICompany

  @observable
  public activeUsers: ICompanyUsersResponse[] = []

  @observable
  public dropdownOptions: TDropdownOptions[] = []

  @observable
  public selectedUser: ICompanyUsersResponse

  @observable
  public updatedCompanyName: string

  get canSubmit() {
    return this.updatedCompanyName && this.selectedUser
  }

  @action
  public toggle(company?: ICompany) {
    this.activeCompany = company
    this.isOpen = !this.isOpen
    this.updatedCompanyName = this.activeCompany ? this.activeCompany.name : ''
    this.fetchRecruiters()
  }

  @action
  public updateCompanyName(name: string) {
    this.updatedCompanyName = name
  }

  @action
  public async fetchRecruiters() {
    if (!this.activeCompany) return
    this.loaderPresenter.showLoader()
    const result = await this.manageCompanyRepository
      .fetchRecruiters(this.activeCompany)
      .finally(() => {
        this.loaderPresenter.hideLoader()
      })
    if (result.success) {
      this.activeUsers = result.result
      this.updateDropdownOptions()
    }
  }

  public updateDropdownOptions() {
    this.dropdownOptions = this.activeUsers.map(i => {
      return {
        label: `${i.firstName} ${i.lastName}`,
        value: i
      }
    })
  }

  public handleOnDropdownUserSelection(selection: TDropdownOptions) {
    this.selectedUser = selection.value
  }

  @action
  public async handleOnSaveClick() {
    this.loaderPresenter.showLoader()
    const payload: IEditCompanyRequestDTO = {
      companyName: this.updatedCompanyName,
      adminId: this.selectedUser.id,
      companyId: this.activeCompany.id
    }
    this.manageCompanyRepository
      .editCompany(payload)
      .then(result => {
        if (result.success) this.toggle()
        else toast.error(result.message)
      })
      .catch(err => {
        console.error(err)
        toast.error(err.message)
      })
      .finally(() => {
        this.loaderPresenter.hideLoader()
      })
  }
}
