export const colors = {
  aquaHaze: '#EFF5F6',
  porcelain: '#FBFCFC',
  dodgerBlue: '#3680FF',
  malibu: '#7FB4FD',
  bunker: '#192326',
  aluminium: '#AAB0B8',
  white: '#FBFCFC',
  yourPink: '#FFCEC9',
  java: '#17B8CD',
  gray: '#959595',
  midGray: '#626368',
  deepCyan: '#075E69',

  darkText: '#33617A',
  errorText: '#DE462B',
  lightText: '#17B8CD',
  whiteText: '#FFFFFF',
  blackText: '#000000',
  greyText: '#8C8C8D',
  unFocus: '#D8E6F2',
  titleText: '#3D5F79',

  buttonBg: '#17B8CD',
  buttonDarkBg: '#33617A',

  mainBg: '#17B8CD',
  hoverBg: '#b8e9ee',
  whiteBg: '#FFFFFF',
  darkBg: '#33617A',
  blackBg: '#000000',
  candidateBg: '#F0F5F6',
  dashboardBg: '#EFF5F6',
  infoLineBg: '#F4F4F4',

  borderError: '#C22000',
  borderSuccess: '#4FAB0E',
  borderFocus: '#17B8CD',
  border: '#D8E6F2',

  stepTitleText: '#0C1A30',

  rowHoverRgbBg: '122 158 211',


  lightSkyBlue: "#E1FCFF",
  lightGreen: "#E6FAEA",
  lightBlue: "#F2F5FF",
  lightPurple: "#F8EDFF",
  lightYellow: "#FFFDED",
  lightGray: "rgba(0, 1, 67, 0.10)",


  midSkyBlue: '#176B91'
}

export const stepColors = [
  '#61A0FC',
  '#F49D8D',
  '#78CE31',
  '#8C68C6',
  '#3DD8A9'
]
