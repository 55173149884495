import * as React from 'react'
import { viewBox } from '../Icon'

export const ElipsisIcon: React.StatelessComponent<{
  fill: string
}> = props => (
  <svg fill={props.fill} viewBox={viewBox}>
    <circle cx="10" cy="25" r="5" />
    <circle cx="25" cy="25" r="5" />
    <circle cx="40" cy="25" r="5" />
  </svg>
)
