import * as React from 'react'
import { Modal } from 'app/Shared/Modal/Modal'
import styled from 'styled-components'
import { colors } from 'styles/colors'
import * as badgeCashColor from 'assets/badgeCashColor.svg'
import * as badgeCVColor from 'assets/badgeCVColor.svg'
import * as badgeCulabrCoinsColor from 'assets/badgeCulabrCoinsColor.svg'
import * as badgeJobsColor from 'assets/badgeJobsColor.svg'

const Wrapper = styled.div`
  color: ${colors.darkText};
  min-width: 400px;

  button {
    margin: 24px auto;
  }
`

const ButtonWrapper = styled.div`
  display: flex;

  button {
    width: 180px;
  }
`

const Record = styled.div`
  display: flex;
  height: 40px;
  align-items: center;
  margin-bottom: 3px;
`

const Image = styled.img`
  width: 40px;
`

const Message = styled.div`
  text-align: center;
  width: 100%;
`

const Title = styled.h3`
  text-align: center;
`

const ContentWrapper = styled.div`
  margin-top: 8px;
  max-height: 400px;
  overflow: auto;
`

interface IProps {
  loading: boolean
  records: any[]

  onClose: () => void
}

export class BadgeHistoryDialog extends React.Component<IProps> {
  private emptyList() {
    if (this.props.records.length === 0) {
      return <p style={{ textAlign: 'center' }}>No data available</p>
    }
  }

  private renderBadges() {
    if (this.props.records.length > 0) {
      return this.props.records.map((badge, index) => {
        return (
          <Record key={'' + index}>
            {badge.type === 'cumulativeDealFee' && (
              <Image src={badgeCashColor} />
            )}
            {badge.type === 'cvSubmits' && <Image src={badgeCVColor} />}
            {badge.type === 'coins' && <Image src={badgeCulabrCoinsColor} />}
            {badge.type === 'cvPlaced' && <Image src={badgeJobsColor} />}

            <Message>{badge.message}</Message>
          </Record>
        )
      })
    }
  }

  public render() {
    return (
      <Modal closeModal={() => this.props.onClose()} hasSubmit={false}>
        <Title>Your Badge History</Title>
        {this.props.loading ? (
          <Wrapper>
            <p>Loading...</p>
          </Wrapper>
        ) : (
          <Wrapper>
            <ContentWrapper>
              {this.emptyList()}
              {this.renderBadges()}
            </ContentWrapper>
          </Wrapper>
        )}
      </Modal>
    )
  }
}
