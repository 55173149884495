import * as React from 'react'
import styled from 'styled-components'

const Wrapper = styled.a`
  color: white;
  text-decoration: none;
`

interface IProps {
  to?: string
  onClick?: () => void
  preventDefault?: boolean
  children?: string
}

export const Link: React.FunctionComponent<IProps> = props => {
  const { to, children, onClick, preventDefault } = props
  const tagProps = {
    href: to,
    onClick: onClick
      ? e => {
          if (preventDefault) {
            e.preventDefault()
          }
          onClick()
        }
      : undefined
  }
  return <Wrapper {...tagProps}>{children}</Wrapper>
}
