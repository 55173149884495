import * as React from 'react'
import { DragLayer, XYCoord } from 'react-dnd'
import { IFormattedCandidate } from 'app/Candidates/IFormattedCandidate'
import {colors} from "styles/colors";

interface IProps {
  item?: IFormattedCandidate
  itemType?: string
  initialOffset?: XYCoord
  currentOffset?: XYCoord
  isDragging?: boolean
}

const CandidateDrag: React.StatelessComponent<IProps> = props => {
  const { item, isDragging, currentOffset } = props

  if (!isDragging || !currentOffset) {
    return null
  }

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        pointerEvents: 'none',
        top: 0,
        left: '14px',
        zIndex: 20
      }}
    >
      <div
        style={{
          top: currentOffset.y,
          left: currentOffset.x,
          zIndex: 20,
          padding: '7px 13px',
          borderRadius: '3px',
          fontSize: '0.75rem',
          background: colors.mainBg,
          color: colors.whiteText,
          position: 'absolute'
        }}
      >
        {item.initials}
      </div>
    </div>
  )
}

export default DragLayer<IProps>((monitor: any) => {
  return {
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging()
  }
})(CandidateDrag)
