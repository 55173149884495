import { injectable } from 'inversify'
import 'reflect-metadata'
import { createBrowserHistory } from 'history'
import { observable } from 'mobx'
import { AbstractWindowGateway } from './AbstractWindowGateway'

const history = createBrowserHistory()

@injectable()
export class WindowGateway implements AbstractWindowGateway {
  constructor() {
    this.pathname = history.location.pathname
    this.queryParamsUrlString = history.location.search
    history.listen(location => {
      this.pathname = location.pathname
      this.queryParamsUrlString = location.search
    })
  }

  @observable
  public pathname: string = ''

  @observable
  public queryParamsUrlString: string = ''

  public getQueryParamForKey = (queryParamName: string): string => {
    const queryReader = new URLSearchParams(this.queryParamsUrlString)
    return queryReader.get(queryParamName)
  }

  public goForward = (path: string) => history.push(path)

  public goBack = () => history.goBack()

  public replacePath = (path: string) => history.replace(path)
}
