import { observable, action, computed } from 'mobx'
import { ResetPasswordPageRepository } from './ResetPasswordPageRepository'
import {
  validated,
  AbstractClassValidator,
  MAX_LENGTH,
  MIN_LENGTH
} from '@logicroom/validator'
import { ITextInput } from 'app/Shared/TextInput/ITextInput'
import { injectable, inject } from 'inversify'
import 'reflect-metadata'

@injectable()
export class ResetPasswordPagePresenter extends AbstractClassValidator {
  @inject(ResetPasswordPageRepository)
  private resetPasswordPageRepository: ResetPasswordPageRepository

  @observable
  public serverErrors = []

  @validated({
    mandatory: true,
    rules: [
      {
        rule: MAX_LENGTH(25),
        errorMessage: 'Password too long, up to 25 characters allowed.'
      },
      {
        rule: MIN_LENGTH(5),
        errorMessage: 'Password must be at least 5 characters long.'
      }
    ]
  })
  public password: ITextInput = {
    type: 'password',
    label: 'New Password',
    autoComplete: 'current-password'
  }

  @validated({
    mandatory: true,
    rules: [
      {
        rule: MAX_LENGTH(25),
        errorMessage: 'Password too long, up to 25 characters allowed.'
      },
      {
        rule: MIN_LENGTH(5),
        errorMessage: 'Password must be at least 5 characters long.'
      }
    ]
  })
  public passwordConfirm: ITextInput = {
    type: 'password',
    label: 'Confirm New Password*',
    autoComplete: 'current-password'
  }

  @computed
  public get buttonIsDisabled(): boolean {
    return !this.formIsValid
  }

  public resetForm(): void {
    this.password.value = ''
    this.passwordConfirm.value = ''
  }

  @action
  public isPasswordMatching(): boolean {
    if (this.password.value !== this.passwordConfirm.value) {
      this.serverErrors = ['Passwords must match.']
      return false
    }
    return true
  }

  @action
  public submit = async (): Promise<void> => {
    if (this.isPasswordMatching()) {
      try {
        await this.resetPasswordPageRepository.passwordReset(this.password.value)
        this.resetForm()
      } catch (ex) {
        this.serverErrors = [ex.message]
      }
    }
  }
}

