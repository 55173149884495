import Candidate from 'app/Candidates/Candidate'
import { IFormattedCandidate } from 'app/Candidates/IFormattedCandidate'
import DealComponent from 'app/Deal/DealComponent'
import { JobsListPresenter } from 'app/RightSidePanel/Jobs/JobsListPresenter'
import { Icon } from 'app/Shared/Icon/Icon'
import { IFormattedStep } from 'app/Steps/StepsPanel/IFormattedStep'
import { StepsPresenter } from 'app/Steps/StepsPanel/StepsPresenter'
import { container } from 'config/IOC'
import { observer } from 'mobx-react'
import * as React from 'react'
import { DropTarget } from 'react-dnd'
import styled from 'styled-components'
import { colors } from 'styles/colors'
import { StepMenu } from './StepMenu'
import { StepWrapper } from './StepWrapper'
import { INestedViewContextProps } from 'app/Header/IModel'

interface IWrapperProps {
  color: string
  faded: boolean
  isOver: boolean
}

const Wrapper = styled.div`
  min-height: 100%;
  max-height: 100%;
  width: 100%;
  box-sizing: border-box;
  border-radius: 30px;
  background-color: ${(props: IWrapperProps) =>
    props.isOver ? colors.hoverBg : colors.white};
  box-shadow: 0 3px 8px -2px rgba(0, 0, 0, 0.4);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  opacity: ${(props: IWrapperProps) => (props.faded ? '0.5' : 'unset')};
`

const Title = styled.h1`
  color: ${colors.stepTitleText};
  font-size: 1rem;
  position: absolute;
  top: 5px;
  margin-left: 16px;
  text-transform: uppercase;
  left: 0;
  right: 0;
`

const Candidates = styled.div`
  width: auto;
  margin: 40px 16px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 4px;
  row-gap: 4px;
  grid-template-rows: repeat(5, 28px);
  text-align: start;

  /* > div:nth-child(3n) > .card {
    left: auto !important;
    right: ${({ theme }) => theme.spacing(2)}
  } */
`

const MenuButton = styled.button`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 10px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
`

const spec = {
  drop: (props: IFormattedStep, monitor) => {
    const { candidateId, sourceStepPosition } = monitor.getItem()
    const { stepId: targetStepId, name: targetStepName } = props
    const stepsPresenter = container.get(StepsPresenter)
    stepsPresenter.moveCandidate(
      candidateId,
      sourceStepPosition,
      targetStepId,
      targetStepName
    )
  }
}

const collect = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  canDrop: monitor.canDrop()
})

interface IPassedProps extends IFormattedStep {
  openStepId: string
  candidateToBeAnimated: string
}

interface IProps extends IPassedProps {
  connectDropTarget: (element: React.ReactNode) => React.ReactNode
  isOver: boolean
}

const Step = observer((props: IProps & INestedViewContextProps) => {
  const {
    formattedCandidates,
    stepColor,
    name,
    position,
    connectDropTarget,
    isOver,
    column,
    row,
    width,
    stepStyle,
    menuIsOpen,
    deletionInProgress,
    toggleMenu,
    deleteStep,
    confirmDeletion,
    deletionConfirmationIsOpen,
    droppedDialogOpen,
    droppedDialogType,
    droppedDialogCandidateName,
    droppedDialogCandidateId,
    droppedDialogJobIdentifier,
    droppedDialogJobName,
    candidateToBeAnimated
  } = props

  const stepWrapperProps = {
    color: stepColor,
    isOver,
    column,
    row,
    width,
    stepStyle
  }

  const stepMenuProps = {
    onRequestClose: toggleMenu,
    color: stepColor,
    deleteStep,
    confirmDeletion,
    deletionConfirmationIsOpen
  }

  return (
    <StepWrapper {...stepWrapperProps}>
      {droppedDialogOpen && (
        <DealComponent
          candidateId={droppedDialogCandidateId}
          formattedCandidateName={droppedDialogCandidateName}
          jobIdentifier={droppedDialogJobName}
          droppedDialogType={droppedDialogType}
        />
      )}

      <Title>{name}</Title>
      <Wrapper
        isOver={isOver}
        faded={deletionInProgress}
        color={stepColor}
        ref={instance => connectDropTarget(instance as any)}
      >
        {menuIsOpen ? (
          <StepMenu {...stepMenuProps} />
        ) : toggleMenu ? (
          <MenuButton onClick={toggleMenu}>
            <Icon name="elipsis" />
          </MenuButton>
        ) : null}
        <Candidates>
          {formattedCandidates.map(
            (candidate: IFormattedCandidate, index: number) => (
              // To avoid same key error here we adding key with item index.
              <Candidate
                {...candidate}
                key={`${index}_${candidate.candidateId}`}
                sourceStepPosition={position}
                index={index}
                sourceStepName={name}
                candidateToBeAnimated={
                  candidateToBeAnimated === candidate.candidateId
                }
                jobsListPresenter={container.get(JobsListPresenter)}
                isNestedView={props.isNestedView}
              />
            )
          )}
        </Candidates>
      </Wrapper>
    </StepWrapper>
  )
})

export default DropTarget<IPassedProps & INestedViewContextProps>(
  'candidate',
  spec,
  collect
)(Step)
