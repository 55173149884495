import { withPresenter } from 'app/withPresenter'
import React from 'react'
import styled from 'styled-components'
import HeaderComponent from 'app/Header/HeaderComponent'
import { observer } from 'mobx-react'
import { ManageRecruitersPresenter } from './ManageRecruitersPresenter'
import RecruiterListComponent from 'app/ManageRecruiters/RecruiterList/RecruiterListComponent'
import StepsComponent from 'app/Steps/StepsPanel/StepsComponent'
import RightSidePanelWrapper from 'app/RightSidePanel/RightSidePanelWrapper'
import { StepsAndJobsWrapper } from 'styles'
import DeleteRecruiter from './DeleteRecruiter/DeleteRecruiter'
import { ManageRecruiterRepository } from './ManageRecruiterRepository'
import { DashboardViews } from 'app/Routing/TAvailableView'

interface IProps {
  presenter: ManageRecruitersPresenter
}

const Wrapper = styled.div`
  height: 100%;
`

@observer
class ManageRecruiters extends React.Component<IProps> {
  componentDidMount(): void {
    this.props.presenter.loadInitialData({
      start: 0,
      end: ManageRecruiterRepository.NUMBER_OF_ROWS_PER_PAGE
    })
  }

  render(): React.ReactNode {
    const { nestedViewContext } = this.props.presenter
    return (
      <Wrapper>
        {!nestedViewContext && (
          <>
            <HeaderComponent />
            <RecruiterListComponent />
          </>
        )}

        {nestedViewContext &&
          nestedViewContext.view === DashboardViews.MY_DASHBOARD && (
            <StepsAndJobsWrapper>
              <StepsComponent isNestedView />
              <RightSidePanelWrapper isNestedView />
            </StepsAndJobsWrapper>
          )}
        <DeleteRecruiter />
      </Wrapper>
    )
  }
}

export default withPresenter(ManageRecruitersPresenter)(ManageRecruiters)
