import {
  IRequestResetPasswordDto,
  IRequestResetPasswordResDto
} from 'app/Authentication/IRequestResetPasswordDto'
import { observable } from 'mobx'
import { RoutingRepository } from 'app/Routing/RoutingRepository'
import { AbstractServiceGateway } from 'gateways/service/AbstractServiceGateway'
import { injectable, inject } from 'inversify'
import 'reflect-metadata'

@injectable()
export class ResetRequestPageRepository {
  @inject(RoutingRepository)
  private routingRepository: RoutingRepository

  @inject(AbstractServiceGateway)
  private serviceGateway: AbstractServiceGateway

  @observable
  public resetRequestRes: any = { success: null, message: null }

  public async requestReset(emailArg: string) {
    const requestResetDto: IRequestResetPasswordDto = { email: emailArg }
    const responseDto = await this.serviceGateway.post<IRequestResetPasswordResDto>('/reset-request', requestResetDto)

    if (responseDto.success === true) {
      this.resetRequestRes = {
        success: responseDto.success,
        message: responseDto.message
      }
      this.routingRepository.goForward('resetSuccess')
    } else {
      throw new Error(responseDto.message)
    }
  }
}
