import * as React from 'react'
import {observer} from 'mobx-react'
import {RegisterSuccessPresenter} from './RegisterSuccessPresenter'
import styled from 'styled-components'
import {withPresenter} from 'app/withPresenter'
import {AuthenticationPageWrapper} from '../AuthenticationPageWrapper/AuthenticationPageWrapper'
import * as logoIcon from 'assets/logoBlue.svg'
import {Button} from "app/Shared/Button/Button";

const Logo = styled.img`
  margin: 20px 0;
  width: 90px;
`
const Paragraph = styled.p`
  margin: 30px 0 0;
  line-height: 1.5em;
  text-align: justify;
`

const Title = styled.h1`
  margin-bottom: 20px;
`

const Centered = styled.div`
  width: 100%;
  text-align: center;
  
  button { 
    margin: 40px auto 5px;
  }
`

interface IProps {
  presenter: RegisterSuccessPresenter
}

const RegisterSuccessComponent = observer((props: IProps) => {
  const {
    submit
  } = props.presenter
  return (
    <AuthenticationPageWrapper>
      <Logo src={logoIcon} />
      <Title>Verify your Email</Title>

      <Paragraph>
        Thanks for registering your account with us.
        We’ve sent a link to your inbox - please verify your address and click ‘Okay’ when you’re done.
        To complete your registration you need to verify your email.
      </Paragraph>

      <Centered>
        <Button
          theme="secondary"
          text="Proceed to Sign in"
          onClick={submit}
        />
      </Centered>
    </AuthenticationPageWrapper>
  )
})

export default withPresenter(RegisterSuccessPresenter)(RegisterSuccessComponent)
