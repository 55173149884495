import { inject, injectable, postConstruct } from 'inversify'
import 'reflect-metadata'
import { observable, computed, action, reaction } from 'mobx'
import { IUserResDto } from 'app/Authentication/IUserResDto'
import { ILoginReqDto } from 'app/Authentication/ILoginReqDto'
import { TAuthUserRole } from 'app/Authentication/TAuthUserRole'
import { IAuthUser, ITokenContext } from 'app/Authentication/IAuthUser'
import { RoutingRepository } from 'app/Routing/RoutingRepository'
import { AbstractServiceGateway } from 'gateways/service/AbstractServiceGateway'
import { AbstractStorageGateway } from 'gateways/storage/AbstractStorageGateway'
import { container } from 'config/IOC'
import { INestedViewContext } from 'app/Header/IModel'
import { DashboardViews } from 'app/Routing/TAvailableView'
const jwt = require('jsonwebtoken')

@injectable()
export class AuthenticationRepository {

  @inject(AbstractStorageGateway)
  private storageGateway: AbstractStorageGateway

  @inject(AbstractServiceGateway)
  private serviceGateway: AbstractServiceGateway

  @postConstruct()
  public init() {
    this.resetUser()

    reaction(
      () => this.serviceGateway.forceLogout,
      (forceLogout) => {
        if (forceLogout) this.logout()
      }
    )
  }

  @observable
  public user: IAuthUser

  @observable
  public mainView: DashboardViews = DashboardViews.MY_DASHBOARD

  @observable
  public tokenContext: ITokenContext

  private nestedViewContext: INestedViewContext[] = []

  @observable
  public activeNestedViewContext: INestedViewContext

  @observable
  public registerRes: any = { success: null, message: null }

  get activeView() {
    if (this.activeNestedViewContext && this.activeNestedViewContext.view) return this.activeNestedViewContext.view
    return this.mainView
  }

  @computed
  public get authenticated() {
    return this.user && this.user.token !== null
  }

  get activeTokenContext(): ITokenContext {
    const contextLength = this.nestedViewContext.length
    if (contextLength === 0) return this.tokenContext
    return this.nestedViewContext[contextLength - 1].tokenContext
  }

  private activeViewContextInternal(): INestedViewContext {
    const contextLength = this.nestedViewContext.length
    if (contextLength === 0) return null
    return this.nestedViewContext[contextLength - 1]
  }

  @action
  public setMainView(view: DashboardViews) {
    this.mainView = view;
  }

  @action
  public setNestedViewContext(context: INestedViewContext) {
    this.nestedViewContext.push(context)
    this.activeNestedViewContext = context
  }

  @action
  public popNestedToken() {
    this.nestedViewContext.pop()
    this.activeNestedViewContext = this.activeViewContextInternal()
  }

  @computed
  public get token() {
    return this.user.token
  }

  @computed
  public get isTokenStored(): boolean {
    return this.storageGateway.getItem('token') !== null
  }

  public async recoverSession(): Promise<void> {
    const token = this.storageGateway.getItem('token')
    if (token) {
      try {
        await this.reloadActiveUser(token)
        return
      } catch (ex) {
        throw new Error('Reloading a user failed.' + ex)
      }
    } else {
      this.resetUser()
    }
  }

  public async login(email: string, password: string) {
    const loginDto: ILoginReqDto = { email, password }
    const responseDto = await this.serviceGateway.post<IUserResDto>(
      '/login',
      loginDto
    )

    if (responseDto.success) {
      this.setUser(responseDto)
    } else {
      this.logout()
      throw new Error(responseDto.message)
    }
  }

  public logout(): void {
    this.removeAuth()
    container.get(RoutingRepository).goForward('login')
  }

  public async reloadActiveUser(token) {
    const sessionDto = { token }
    try {
      const responseDto = await this.serviceGateway.post<IUserResDto>(
        '/session',
        sessionDto,
        { 'Authorization': token }
      )

      if (responseDto.success) {
        this.setUser(responseDto)
      } else {
        this.logout()
      }
    } catch (ex) {
      this.logout()
    }
  }

  public async registerNewUser(
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    passwordConfirm: string,
    phoneNumber: string,
    managerFirstName: string,
    managerLastName: string,
    roles: string,
    managerEmail: string
  ) {
    const registerDto = {
      firstName,
      lastName,
      email,
      password,
      passwordConfirm: passwordConfirm,
      phonenumber: phoneNumber,
      managerFirstName,
      managerLastName,
      roles,
      managerEmail
    }
    const responseDto = await this.serviceGateway.post<{
      success: boolean
      message: string
    }>('/register', registerDto)

    if (responseDto.success === true) {
      this.registerRes = {
        success: responseDto.success,
        message: responseDto.message
      }
      container.get(RoutingRepository).goForward('registerSuccess')
    } else {
      throw new Error(responseDto.message)
    }
  }

  @action
  public setUser(responseDto: IUserResDto): void {
    try {
      const userRoles: TAuthUserRole = responseDto.role
      this.storageGateway.setItem('token', responseDto.token)
      this.serviceGateway.accessToken = responseDto.token
      this.resetUser()
      this.user = {
        id: responseDto.id,
        firstName: responseDto.firstName,
        lastName: responseDto.lastName,
        username: responseDto.username,
        companyId: responseDto.companyId,
        company: responseDto.company,
        email: responseDto.email,
        token: responseDto.token,
        roles: [userRoles],
        workflowId: responseDto.workflowId
      }
      this.tokenContext = jwt.decode(responseDto.token)
    } catch (e) {
      console.error(e)
    }
  }

  public removeAuth(): void {
    this.storageGateway.removeItem('token')
    this.serviceGateway.accessToken = null
    this.tokenContext = undefined
    this.resetUser()
  }

  public resetUser(): void {
    this.user = {
      id: null,
      firstName: null,
      lastName: null,
      username: null,
      company: null,
      companyId: null,
      email: null,
      token: null,
      roles: [],
      workflowId: null
    }
  }
}
