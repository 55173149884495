import React from 'react'
import styled, { css } from 'styled-components'
import { colors } from 'styles/colors'

interface IProps {
  isChecked: boolean
  label: string
  onClick: () => void
}

const RadioButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;

  span {
    vertical-align: middle;
    align-self: center;
    justify-self: center;
    font-size: 14px;
  }
`

const RadioCircle = styled.div<any>`
  width: 15px;
  height: 15px;
  border: 2px solid #bbb;
  border-radius: 50%;
  display: inline-block;
  position: relative;

  ${(props: any) =>
    props.checked &&
    css`
      border: 3px solid ${colors.mainBg};
    `}
`

const RadioDot = styled.div<any>`
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: ${colors.mainBg};
  border-radius: 50%;
  text-align: center;
  top: 2.5px;
  left: 2.5px;
`

export class RadioButton extends React.Component<IProps> {
  constructor(props) {
    super(props)
  }

  render(): React.ReactNode {
    return (
      <RadioButtonWrapper onClick={this.props.onClick}>
        <RadioCircle checked={this.props.isChecked}>
          {this.props.isChecked && <RadioDot />}
        </RadioCircle>
        <span>{this.props.label}</span>
      </RadioButtonWrapper>
    )
  }
}
