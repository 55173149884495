import {
  IResetPasswordDto,
  IRequestResetPasswordResDto
} from 'app/Authentication/IRequestResetPasswordDto'
import { RoutingRepository } from 'app/Routing/RoutingRepository'
import { observable } from 'mobx'
import { AbstractServiceGateway } from 'gateways/service/AbstractServiceGateway'
import { injectable, inject } from 'inversify'
import 'reflect-metadata'

@injectable()
export class ResetPasswordPageRepository {

  @inject(RoutingRepository)
  private routingRepository: RoutingRepository

  @inject(AbstractServiceGateway)
  private serviceGateway: AbstractServiceGateway

  @observable
  public resetRequestRes: any = { success: null, message: null }

  public async passwordReset(passwordArg: string) {
    const queryParams = this.routingRepository.getParams(['token'])
    const requestResetDto: IResetPasswordDto = {
      token: queryParams.token,
      password: passwordArg
    }
    const responseDto = await this.serviceGateway.post<IRequestResetPasswordResDto>(
      '/reset-password',
      requestResetDto
    )

    if (responseDto.success) {
      this.resetRequestRes = {
        success: responseDto.success,
        message: responseDto.message
      }
      this.routingRepository.goForward('resetPasswordSuccess')
    } else {
      throw new Error(responseDto.message)
    }
  }
}

