import { observable, computed, action, toJS } from 'mobx'
import { IFormattedStep, IPositionStep, TStepStyle } from 'app/Steps/StepsPanel/IFormattedStep'
import { STEP_WIDTH } from '../../../config/constants'
import { padStart } from 'lodash'
import { IStep } from 'app/Steps/IStep'
import { getTextColor } from 'helpers/getTextColor'
import { ICandidate } from 'app/Candidates/ICandidate'
import { StepsRepository } from 'app/Steps/StepsRepository'
import { CandidatesRepository } from 'app/Candidates/CandidatesRepository'
import { injectable, inject } from 'inversify'
import 'reflect-metadata'
import { AuthenticationRepository } from "app/Authentication/AuthenticationRepository";
import { ActionListPresenter } from 'app/Actions/ActionList/ActionListPresenter'
import { colors } from 'styles/colors'
import { ActionPresenter } from 'app/Candidates/Actions/ActionPresenter'

@injectable()
export class StepsPresenter {
  @inject(StepsRepository)
  private stepsRepository: StepsRepository

  @inject(CandidatesRepository)
  private candidatesRepository: CandidatesRepository

  @inject(AuthenticationRepository)
  private authenticationRepository: AuthenticationRepository

  @inject(ActionListPresenter)
  private actionListPresenter: ActionListPresenter

  @inject(ActionPresenter)
  private actionPresenter: ActionPresenter

  @observable
  public openStepMenuId: string = null

  @observable
  public openDeletionConfirmationId: string = null

  @observable
  public deletionInProgressId: string = null

  @observable
  public droppedDialogOpenStepId: string = null

  @observable
  public droppedDialogCandidateName: string = null

  @observable
  public droppedDialogJobIdentifier: number = null

  @observable
  public droppedDialogJobName: string = null

  @observable
  public droppedDialogCandidateId: string = null

  @observable
  public droppedDialogType: string = null

  @observable
  public showDeleteDialog: boolean = false

  @observable
  public candidateToBeDeleted: ICandidate = null

  @observable
  public containerWidth: number = STEP_WIDTH

  @observable
  public stepsDelta: number = 390

  @observable
  public candidateToBeAnimated: string = ""

  @computed
  public get rowCount(): number {
    return Math.ceil(this.stepsRepository.steps.length / this.columnCount)
  }

  @computed
  public get columnCount(): number {
    return Math.floor((this.containerWidth - this.stepsDelta) / STEP_WIDTH)
  }

  @computed
  public get formattedSteps(): IFormattedStep[] {
    return this.stepsRepository.steps.map((step: IStep, index: number) => {
      const { name, candidates, stepColor, stepId, position, custom } = step
      const nextStep =
        this.stepsRepository.steps.length > position + 1
          ? this.stepsRepository.steps.find(
            stepTest => stepTest.position === position + 1
          )
          : null

      const isLastCustomStep = nextStep && !nextStep.custom && custom
      const formattedPosition = padStart(position.toString(), 2, '0')
      const formattedCandidates = this.elaborateCandidates(candidates, name)

      const previousStep = this.stepsRepository.steps[index - 1]
      const destinationIds = previousStep ? [previousStep.stepId] : []
      const menuIsOpen = this.openStepMenuId === stepId
      const deletionInProgress = this.deletionInProgressId === stepId
      const deletionConfirmationIsOpen = this.openDeletionConfirmationId === stepId

      const toggleMenu = isLastCustomStep
        ? () => {
          this.toggleStepMenu(stepId)
        }
        : undefined

      const optIProps = {
        deleteStep: undefined,
        confirmDeletion: undefined
      }

      if (menuIsOpen) {
        optIProps.deleteStep = () => {
          this.toggleDeletionConfirmation(stepId)
        }
      }

      if (deletionConfirmationIsOpen) {
        optIProps.confirmDeletion = (confirmed: boolean) => {
          if (confirmed) {
            this.deleteStep(stepId)
          } else {
            this.toggleDeletionConfirmation(stepId)
          }
        }
      }

      const droppedDialogOpen = this.droppedDialogOpenStepId === stepId

      const droppedDialogCandidateName = this.droppedDialogCandidateName
      const droppedDialogCandidateId = this.droppedDialogCandidateId
      const droppedDialogJobIdentifier = this.droppedDialogJobIdentifier
      const droppedDialogJobName = this.droppedDialogJobName

      const droppedDialogType = this.droppedDialogType

      return {
        formattedCandidates,
        name,
        stepColor,
        stepId,
        formattedPosition,
        width: STEP_WIDTH,
        droppedDialogOpen,
        droppedDialogCandidateName,
        droppedDialogCandidateId,
        droppedDialogJobIdentifier,
        droppedDialogJobName,
        droppedDialogType,
        destinationIds,
        menuIsOpen,
        deletionInProgress,
        deletionConfirmationIsOpen,
        position: step.position,
        toggleMenu,
        ...this.findStyleForStep(step),
        ...optIProps
      }
    })
  }

  private elaborateCandidates(candidates: ICandidate[], stepName: string): any[] {
    return candidates.map((candidate: ICandidate) => {
      if (!candidate) return null


      const { firstName, lastName, jobIdentifier, candidateId, jobName, jobStatus } = candidate
      let { candidateColor } = candidate

      if (jobStatus !== 'OPEN' && stepName !== "Placement") candidateColor = colors.gray

      const initials = `${firstName.slice(0, 9)} ${lastName.charAt(0)}`
      const tooltip = `${firstName} ${lastName} ${jobIdentifier}`
      const formattedName = `${firstName} ${lastName}`
      const textColor = getTextColor(candidateColor)
      const workflowId = this.authenticationRepository.user.workflowId

      return {
        initials,
        tooltip,
        formattedName,
        jobIdentifier,
        jobName,
        jobStatus,
        backgroundColor: candidateColor,
        textColor,
        candidateId,
        stepName,
        workflowId
      }
    })
      .filter(candidate => candidate)
  }

  private findStyleForStep(step: IStep): IPositionStep {
    const length = this.stepsRepository.steps.length

    const { position } = step

    const row = Math.ceil(position / this.columnCount)
    const module = position % this.columnCount
    const column = module === 0 ? this.columnCount : module

    let stepStyle: TStepStyle

    if (module === 0) {
      stepStyle = position === length ? 'last' : 'corR'
    } else if (module === 1) {
      stepStyle = row === 1 ? 'first' : 'corL'
    } else {
      stepStyle = 'mid'
    }

    if (position === length) {
      stepStyle = 'last'
    }

    if (this.columnCount === 1) {
      stepStyle = 'corL'
    }

    return {
      row,
      column,
      stepStyle
    }
  }

  public async closeStep() {
    this.droppedDialogOpenStepId = null
  }

  public async moveCandidate(candidateId: string, sourceStepPosition: number, stepId: string, name: string) {
    const updatedActions = await this.stepsRepository.moveCandidate(candidateId, stepId)
    //moveCandidate return updated action details so refresh the steps is enough
    this.actionPresenter.updateAllActions(updatedActions)
    this.candidatesRepository.loadLatestStepsAndJobs(true);


    this.afterCandidateMoved(stepId, sourceStepPosition, name, candidateId)
  }

  private clearActionListFilter() {
    if (this.actionListPresenter.isCandidateFilterLocked) return
    // here we are reset false positive candidate hover filter during drag and drop
    this.actionListPresenter.candidateHoverFilter("")
  }

  private handleCandidateDropAnimation(droppedStep: IFormattedStep, sourceStepPosition: number, candidateId: string) {
    const isCandidateMovedForwardDirection: boolean = droppedStep.position > sourceStepPosition
    if (isCandidateMovedForwardDirection) {
      this.candidateToBeAnimated = candidateId
      setTimeout(() => {
        this.candidateToBeAnimated = null
        this.clearActionListFilter()
      }, 2001)
      return
    }
    this.clearActionListFilter()
  }

  private afterCandidateMoved(stepId: string, sourceStepPosition: number, name: string, candidateId: string) {
    const droppedStep = this.formattedSteps.filter(step => {
      return step.name === name
    })[0]

    this.handleCandidateDropAnimation(droppedStep, sourceStepPosition, candidateId)
    const droppedCandidate = droppedStep.formattedCandidates.filter(candidate => {
      return candidate.candidateId === candidateId
    })[0]

    if (droppedCandidate && (name === 'Offer' || name === 'Placement')) {
      this.droppedDialogOpenStepId = stepId
      this.droppedDialogCandidateName = droppedCandidate.formattedName
      this.droppedDialogCandidateId = droppedCandidate.candidateId
      this.droppedDialogJobIdentifier = droppedCandidate.jobIdentifier
      this.droppedDialogJobName = droppedCandidate.jobName

      if (name === 'Offer') this.droppedDialogType = 'Add'
      if (name === 'Placement') this.droppedDialogType = 'Confirm'

    } else {
      this.clearDealData()
    }
  }

  public clearDealData() {
    this.droppedDialogOpenStepId = null
    this.droppedDialogCandidateName = null
    this.droppedDialogCandidateId = null
    this.droppedDialogJobIdentifier = null
    this.droppedDialogJobName = null
  }

  public openDeleteDialog(candidateId: string, workflowId: string) {
    this.candidatesRepository
      .findCandidateById(candidateId, workflowId)
      .then(c => {
        this.candidateToBeDeleted = c
        this.showDeleteDialog = true
      })
  }

  public closeDeleteDialog() {
    this.candidateToBeDeleted = null
    this.showDeleteDialog = false
  }

  public async deleteCandidate(candidateId: string) {
    await this.candidatesRepository.deleteCandidate(candidateId)

    this.closeDeleteDialog()
  }

  public toggleStepMenu(stepId: string) {
    if (this.openStepMenuId === stepId) {
      this.openStepMenuId = null
    } else {
      this.openStepMenuId = stepId
    }
  }

  public toggleDeletionConfirmation(stepId: string) {
    if (this.openDeletionConfirmationId === stepId) {
      this.openDeletionConfirmationId = null
    } else {
      this.openDeletionConfirmationId = stepId
    }
  }

  @action
  public deleteStep = async stepId => {
    this.clearDealData()
    this.deletionInProgressId = stepId
    this.openDeletionConfirmationId = null
    await this.stepsRepository.deleteStep(stepId)
    this.deletionInProgressId = null
  }
}
