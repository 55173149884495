import { LOGO_HEADER_HEIGHT, STEP_AND_ACTION_HEADER_HEIGHT, ACTION_WIDTH } from "config/constants";
import { colors } from 'styles/colors'
import styled from "styled-components";

export const FullScreenWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 9999;
`

export const EmptyElement = styled.div`
  display: none;
`

export const StepsAndJobsWrapper = styled.div`
  min-height: calc(100vh - ${LOGO_HEADER_HEIGHT + STEP_AND_ACTION_HEADER_HEIGHT}px);
  max-height: calc(100vh - ${LOGO_HEADER_HEIGHT}px);
  max-width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: auto ${ACTION_WIDTH}px;
  grid-template-rows: 100%;
`

export const Label = styled.div`
  font-size: 12px;
  color: ${colors.midGray};
`

export const RadioButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-top: 8px;
  margin-bottom: 16px;
  cursor: pointer;
`

export const IconWrapper = styled.div`
  position: relative;

  svg {
    transform: scale(1.85) !important;
  }
`