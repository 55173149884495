import {inject, injectable} from 'inversify'
import {AbstractServiceGateway} from 'gateways/service/AbstractServiceGateway'
import 'reflect-metadata'
import {ILeaderBoardDto, TLeaderBoardField, TLeaderBoardTimeFrame} from "app/Actions/LeaderBoardList/ILeaderBoardDto";
import {IBaseResponse} from "gateways/service/IBaseResponse";
import {AuthenticationRepository} from "app/Authentication/AuthenticationRepository";

@injectable()
export class LeaderBoardRepository {

  @inject(AbstractServiceGateway)
  private serviceGateway: AbstractServiceGateway

  @inject(AuthenticationRepository)
  private authenticationRepository: AuthenticationRepository

  public async fetchData(order: TLeaderBoardField, type: TLeaderBoardTimeFrame): Promise<ILeaderBoardDto[]> {
    const responseDto = await this.serviceGateway.get<IBaseResponse<ILeaderBoardDto[]>>('/leader-board?' +
      'companyId=' + this.authenticationRepository.user.companyId + '&' +
      'type=' + type + '&' +
      'field=' + order
    )

    if (responseDto.success) {
      return responseDto.result
    } else {
      throw new Error(responseDto.message)
    }
  }
}
