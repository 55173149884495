import { AddCandidatePresenter } from 'app/Candidates/AddCandidate/AddCandidatePresenter'
import { RadioButton } from 'app/Shared/CheckBoxInput/RadioButton'
import { Dropdown } from 'app/Shared/Dropdown/Dropdown'
import { Modal } from 'app/Shared/Modal/Modal'
import { TextInput } from 'app/Shared/TextInput/TextInput'
import { withPresenter } from 'app/withPresenter'
import { toJS } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import styled from 'styled-components'
import { colors } from 'styles/colors'
import ActionList from '../Actions/ActionList'
import { Label, RadioButtonWrapper } from 'styles'

const Wrapper = styled.div`
  color: ${colors.darkText};
  min-width: 400px;
  max-height: 600px;
`

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;

  input {
    padding-left: 0;
    width: 100%
  }
`
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  button {
    width: 180px;
  }
`

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  gap: 12px;
`

interface IProps {
  presenter: AddCandidatePresenter
}

@observer
class AddCandidate extends React.Component<IProps> {
  onKeyDown = e => {
    if (e.key === 'Enter' && this.props.presenter.canSubmit) {
      this.props.presenter.submit()
    } else if (e.key === 'Escape') {
      this.props.presenter.cancel()
    }
  }

  handleDropdownJobSelection(select) {
    this.props.presenter.updateActiveJob(select.value)
  }

  render(): React.ReactNode {
    const {
      isOpen,
      candidate,
      updateFirstName,
      updateLastName,
      updateJobIdentifier,
      canSubmit,
      activeJob,
      activeJobType,
      submit,
      submitEdit,
      cancel
    } = this.props.presenter

    let defaultJob
    if (activeJob) {
      const job = toJS(activeJob)
      defaultJob = { label: job.name, value: job }
    }

    const isEditMode = candidate === null ? false : true
    if (!isOpen) return null
    return (
      <Modal
        outsideClickClose={false}
        closeModal={cancel}
        showHeader={true}
        title={` ${isEditMode ? 'CV Details' : 'Add a new candidate'}`}
        dialogWidth="700px"
        onSaveClick={isEditMode ? submitEdit : submit}
        saveButtonDisabled={!canSubmit}
      >
        <Wrapper onKeyDown={this.onKeyDown}>
          <InputWrapper>
            <TextInput input={updateFirstName} theme="secondary" />
            <TextInput input={updateLastName} theme="secondary" />
          </InputWrapper>
          <Label>Job Type *</Label>
          <RadioButtonWrapper>
            <RadioButton
              isChecked={activeJobType == 'fromOldJob'}
              label="Choose Existing Job"
              onClick={() => this.props.presenter.updateJobType('fromOldJob')}
            />
            <RadioButton
              isChecked={activeJobType == 'createNewJob'}
              label="Create New Job"
              onClick={() => this.props.presenter.updateJobType('createNewJob')}
            />
          </RadioButtonWrapper>
          {this.props.presenter.activeJobType == 'fromOldJob' && (
            <>
              <Label>Job Identifier</Label>
              <Dropdown
                default={defaultJob}
                options={this.props.presenter.jobs}
                onSelect={this.handleDropdownJobSelection.bind(this)}
              />
            </>
          )}
          {this.props.presenter.activeJobType == 'createNewJob' && (
            <TextInput
              extraStyles={'width: 100%'}
              input={updateJobIdentifier}
              theme="secondary"
            />
          )}
          {isEditMode && (
            <ActionsWrapper>
              <ActionList
                isCandidateJobOpen={
                  this.props.presenter.candidate.jobStatus === 'OPEN'
                }
                label="Actions"
                showActiveActionsOnly
                candidateId={this.props.presenter.candidate.candidateId}
              />
              <ActionList
                isCandidateJobOpen={
                  this.props.presenter.candidate.jobStatus === 'OPEN'
                }
                label="Completed Actions"
                showCompletedOnly
                candidateId={this.props.presenter.candidate.candidateId}
              />
            </ActionsWrapper>
          )}
        </Wrapper>
      </Modal>
    )
  }
}

export default withPresenter(AddCandidatePresenter)(AddCandidate)
