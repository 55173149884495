import { AuthenticationRepository } from "app/Authentication/AuthenticationRepository";
import { AbstractServiceGateway } from "gateways/service/AbstractServiceGateway";
import { HTTPBaseResponse } from "gateways/service/stubs/dtos";
import { inject, injectable } from "inversify";
import _ from "lodash";
import { observable } from "mobx";
import { toast } from 'react-toastify';
import { IFeedbackModel } from "./IFeedback";


@injectable()
export class AddFeedbackPresenter {
    @observable
    public isOpen: boolean = false

    @observable
    public isUpdateInprogres: boolean = false


    @inject(AbstractServiceGateway)
    private serviceGateway: AbstractServiceGateway
    @inject(AuthenticationRepository)
    private authenticationRepository: AuthenticationRepository

    public toggle = () => {
        this.isOpen = !this.isOpen
        this.isUpdateInprogres = false
    }

    public cancel = async () => {
        this.toggle();
    }

    public submit = async (content: string): Promise<boolean> => {
        this.isUpdateInprogres = true
        let user = this.authenticationRepository.user
        let feedback: IFeedbackModel = {
            content,
            userId: user.id,
            companyId: user.companyId,
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName
        }
        let result = await this.serviceGateway.post<HTTPBaseResponse>("/submit-feedback", feedback)
        if(!result.success) {
            toast.error(result.message);
            this.isUpdateInprogres = false;
            return false
        }
        this.isUpdateInprogres = false
        toast.success("The feedback has been sent!")
        this.toggle();
        return true
    }

}