import * as React from 'react'
import { observer } from 'mobx-react'
import { ResetRequestPagePresenter } from './ResetRequestPagePresenter'
import { withPresenter } from 'app/withPresenter'
import { Button } from 'app/Shared/Button/Button'
import { Link } from 'app/Shared/Link/Link'
import { AuthenticationPageWrapper } from '../AuthenticationPageWrapper/AuthenticationPageWrapper'
import styled from 'styled-components'
import { TextInput } from 'app/Shared/TextInput/TextInput'
import * as logoIcon from 'assets/logoBlue.svg'
import {colors} from "styles/colors";

const Form = styled.form`
  display: flex;
  align-items: center;
  flex-direction: column;
`

const Paragraph = styled.p`
  margin: 30px 40px;
  padding: 0;
  text-align: justify;
  line-height: 1.5em;
`

const BackParagraph = styled.p`
  margin: 30px 0 10px;
  padding: 0;
  
  a {
    color: ${colors.lightText};
  }
`

const Error = styled.p`
  align-self: center;
  font-weight: normal;
  color: ${colors.errorText};
  margin: 0;
  padding: 4px 0;
`

const Logo = styled.img`
  margin: 20px 0;
  width: 90px;
`

const Title = styled.h1`
  margin-bottom: 20px;
`

interface IProps {
  presenter: ResetRequestPagePresenter
}

const ResetRequestPageComponent = observer((props: IProps) => {
  const {
    email,
    submit,
    linkClick,
    buttonIsDisabled,
    errors
  } = props.presenter
  return (
    <AuthenticationPageWrapper>
      <Logo src={logoIcon} />
      <Title>Forgot your password?</Title>

      {errors.map((message, i) => (
        <Error key={i + message}>{message}</Error>
      ))}

      <Form onSubmit={e => e.preventDefault()}>
        <TextInput input={email} theme="secondary" />

        <Paragraph>
          Please enter the email you used to register with Culabr.
          You’ll get instructions sent to this address.
        </Paragraph>

        <Button
          theme={'secondary'}
          text="Submit"
          disabled={buttonIsDisabled}
          onClick={submit}
        />

        <BackParagraph>
          Remember your password now? &nbsp;
          <Link
            preventDefault={true}
            onClick={linkClick.bind(this, 'login')}
            to="/login"
          >
            Go to login
          </Link>
        </BackParagraph>
      </Form>
    </AuthenticationPageWrapper>
  )
})

export default withPresenter(ResetRequestPagePresenter)(
  ResetRequestPageComponent
)
