import { inject, injectable, postConstruct } from 'inversify'
import 'reflect-metadata'
import { observable, action, computed } from 'mobx'
import { RoutingRepository } from 'app/Routing/RoutingRepository'
import { AuthenticationRepository } from '../AuthenticationRepository'
import {
  validated,
  AbstractClassValidator,
  MAX_LENGTH,
  MUST_BE_EMAIL,
  MIN_LENGTH
} from '@logicroom/validator'
import { ITextInput } from 'app/Shared/TextInput/ITextInput'

@injectable()
export class LoginPagePresenter extends AbstractClassValidator {

  @postConstruct()
  public init() {
    this.routingRepository.viewIdListener(
      'login',
      () => {
        this.resetForm()
      },
      () => {
      }
    )
  }

  @inject(RoutingRepository)
  private routingRepository: RoutingRepository

  @inject(AuthenticationRepository)
  private authenticationRepository: AuthenticationRepository

  @observable
  public serverErrors = []

  @validated({
    mandatory: true,
    rules: [
      {
        rule: MUST_BE_EMAIL,
        errorMessage: 'Your entry does not look like an email.'
      }
    ]
  })
  public email: ITextInput = { type: 'email', label: 'Email*', autoComplete: 'username-email'}

  @validated({
    mandatory: true,
    rules: [
      {
        rule: MAX_LENGTH(25),
        errorMessage: 'Password too long, up to 25 characters allowed.'
      },
      {
        rule: MIN_LENGTH(5),
        errorMessage: 'Password must be at least 5 characters long.'
      }
    ]
  })
  public password: ITextInput = { type: 'password', label: 'Password*', autoComplete: 'current-password'}

  @action
  public submit = async (): Promise<void> => {
    try {
      await this.authenticationRepository.login(
        this.email.value,
        this.password.value
      )
      this.resetForm()
    } catch (ex) {
      this.serverErrors = [ex.message]
    }
  }

  public noAccount = {
    blurb: 'No account?',
    label: 'Create Account',
    onClick: () => {
      this.routingRepository.goForward('register')
    }
  }

  public forgotPassword = {
    blurb: 'Forgot password?',
    label: 'Reset password',
    onClick: () => {
      this.routingRepository.goForward('reset')
    }
  }

  public resetForm(): void {
    this.email.value = ''
    this.password.value = ''
  }

  @computed
  public get buttonIsDisabled(): boolean {
    return !this.formIsValid
  }
}

