import { Modal } from "app/Shared/Modal/Modal";
import { withPresenter } from "app/withPresenter";
import { observer } from "mobx-react";
import React from "react";
import styled from "styled-components";
import { colors } from "styles/colors";
import { AddFeedbackPresenter } from "./AddFeedbackPresenter";


const Wrapper = styled.div`
  color: ${colors.darkText};
  min-width: 600px;
  max-height: 600px;

  button {
    margin: 24px auto;
  }
`

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  small {
    margin-bottom: 12px;
  }
`

const Title = styled.h2`
  margin: 20px 0 20px 0
`

const TextArea = styled.textarea`
  width: calc( 100% - 50px );
  min-height: 250px;
  outline: none;
  border: 1px solid #C9C9C9;
`

const ButtonWrapper = styled.div`
  display: flex;

  button {
    width: 180px;
  }

`

interface IProps {
  presenter: AddFeedbackPresenter
}


@observer
class AddFeedback extends React.Component<IProps, { [key: string]: any }> {
  constructor(props) {
    super(props)
    this.state = { feedback: "", canSubmit: false }
  }

  onKeyDown = e => {
    if (e.key === 'Enter') {
      this.submit()
    } else if (e.key === 'Escape') {
      this.props.presenter.cancel()
    }
  }

  handleOnFeedbackChange = e => {
    this.setState({
      feedback: e.target.value,
      canSubmit: Boolean(e.target.value)
    })
  }

  submit = async () => {
    let isSubmitted = await this.props.presenter.submit(this.state.feedback);
    if (isSubmitted) this.setState({ feedback: "", canSubmit: false });
  }

  render(): React.ReactNode {
    const { isOpen, cancel } = this.props.presenter
    if (!isOpen) return null
    return (
      <Modal
        closeModal={cancel}
        onSaveClick={() => { this.submit() }}
        saveButtonDisabled={!this.state.canSubmit || this.props.presenter.isUpdateInprogres}
      >
        <Wrapper onKeyDown={this.onKeyDown}>
          <HeaderWrapper>
            <Title>Your Feedback is Important</Title>
            <small>Tell us how we can improve Culabr</small>
          </HeaderWrapper>

          <TextArea
            value={this.state.feedback}
            maxLength={2000}
            onChange={this.handleOnFeedbackChange}
          ></TextArea>
        </Wrapper>
      </Modal>
    )
  }


}
export default withPresenter(AddFeedbackPresenter)(AddFeedback)