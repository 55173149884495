import { createMemoryHistory } from 'history'
import { AbstractWindowGateway } from './AbstractWindowGateway'
import { injectable } from 'inversify'
import 'reflect-metadata'

const history = createMemoryHistory({ initialEntries: ['/'] })

@injectable()
export class FakeWindowGateway implements AbstractWindowGateway {
  constructor() {
    this.pathname = history.location.pathname
    this.queryParamsUrlString = history.location.search
    history.listen(location => {
      this.pathname = location.pathname
      this.queryParamsUrlString = location.search
    })
  }

  public pathname: string = ''

  public queryParamsUrlString: string = ''

  public getQueryParamForKey = (queryParamName: string): string => {
    const queryReader = new URLSearchParams(this.queryParamsUrlString)
    return queryReader.get(queryParamName)
  }

  public goForward = (path: string) => history.push(path)

  public goBack = () => history.goBack()

  public replacePath = (path: string) => history.replace(path)
}
