import * as React from 'react';
import styled from 'styled-components';
import { colors } from 'styles/colors';

interface IProps {
  badgeContent?: string;
  bgColor?: string;
  borderColor?: string;
  hasBorder?: boolean;
}

const BadgeWrapper = styled.span<IProps>`
  background-color: ${props => props.bgColor || colors.deepCyan};
  border: ${props => props.hasBorder ? `2px solid ${props.borderColor || colors.mainBg}` : 'none'};
  font-size: 11px;
  color: ${colors.whiteText};
  padding: 2px 4px 0px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 16px;
  height: 14px;
  box-sizing: content-box;
  transform: scale(1) translate(100%, -50%);
  position: absolute;
  top: 4px;
  right: 10px;
`

export const Badge: React.FunctionComponent<IProps> = props => {
  const {
    badgeContent,
    bgColor,
    borderColor,
    hasBorder = false
  } = props

  return (
    <BadgeWrapper
      bgColor={bgColor}
      borderColor={borderColor}
      hasBorder={hasBorder}>
      {badgeContent}
    </BadgeWrapper>
  )
}