import { AdditionalCoinsDialog } from 'app/Actions/AdditionalCoinsDialog/AdditionalCoinsDialog'
import { IAction } from 'app/Actions/IAction'
import { RewardDialog } from 'app/Actions/RewardDialog/RewardDialog'
import { LottieCheckBox } from 'app/Shared/CheckBoxInput/LottieCheckBox'
import { withPresenter } from 'app/withPresenter'
import _ from 'lodash'
import { observer } from 'mobx-react'
import React from 'react'
import styled, { css } from 'styled-components'
import { colors } from 'styles/colors'
import { ActionPresenter } from './ActionPresenter'
import DeleteCandidateModal from '../DeleteCandidate/DeleteCandidateModal'

interface ITProps {
  candidateId: string
  isCandidateJobOpen: boolean
  showCompletedOnly?: boolean
  showActiveActionsOnly?: boolean
  label?: string
  hasBorder?: boolean
}

interface IProps extends ITProps {
  presenter: ActionPresenter
}

interface IState {}

const Wrapper = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
`

const ListWrapper = styled.div`
  min-height: 120px;
  max-height: 120px;
  overflow-y: auto;
`

const ItemWrapper = styled.div<any>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;

  ${(props: IProps) =>
    props.hasBorder &&
    css`
      padding-inline: 16px 6px;
      font-size: ${({ theme }) => theme.fontSizes.xs};
      box-shadow: 0 1px 0 ${colors.border};

      &:last-of-type {
        box-shadow: none;
      }
    `}
`

const Label = styled.div`
  font-size: 12px;
  margin-top: 8px;
  margin-bottom: 8px;
  color: ${colors.midGray};
`

const Action = styled.div<any>`
  ${(props: any) =>
    props.isCompleted &&
    css`
      text-decoration: line-through;
    `}
`

@observer
class ActionList extends React.Component<IProps, IState> {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount(): void {
    this.props.presenter.getActions(this.props.candidateId)
  }

  async handleActionToggle(completed, action: IAction) {
    await this.props.presenter.toggleCompleted(
      action.actionId,
      action.actionTypeId,
      this.props.candidateId,
      completed
    )
  }

  render(): React.ReactNode {
    let filletedActions = []
    const { activeCandidateActions } = this.props.presenter
    const [completedActions, activeActions] = _.partition(
      activeCandidateActions,
      { completed: true }
    )
    if (this.props.showCompletedOnly) filletedActions = completedActions
    if (this.props.showActiveActionsOnly) filletedActions = activeActions
    if (!this.props.isCandidateJobOpen && this.props.showActiveActionsOnly) {
      filletedActions = _.filter(activeCandidateActions, {
        actionTypeId: '100'
      })
    }
    return (
      <Wrapper>
        {this.props.label && <Label>{this.props.label}</Label>}
        <ListWrapper>
          {filletedActions.map(action => {
            return (
              <ItemWrapper
                key={`${action.actionId}`}
                hasBorder={this.props.hasBorder}
              >
                <Action isCompleted={action.completed}>
                  {action.actionName}
                </Action>
                <LottieCheckBox
                  isChecked={action.completed}
                  onChange={completed =>
                    this.handleActionToggle(completed, action)
                  }
                />
              </ItemWrapper>
            )
          })}
        </ListWrapper>
        <div>
          {this.props.presenter.showRewardDialog ? (
            <RewardDialog
              onClose={() => this.props.presenter.rewardDialogCloseHandler()}
            />
          ) : null}

          {this.props.presenter.showAdditionalCoinsDialog ? (
            <AdditionalCoinsDialog
              amount={this.props.presenter.additionalCoinsAdded}
              onClose={() => this.props.presenter.hideAdditionalCoinsDialog()}
            />
          ) : null}

          {this.props.presenter.showDeleteDialog && (
            <DeleteCandidateModal
              candidate={this.props.presenter.candidateToBeRejected}
              isRejectOperation={true}
            />
          )}
        </div>
      </Wrapper>
    )
  }
}

export default withPresenter<ITProps>(ActionPresenter)(ActionList)
