import { inject, injectable, postConstruct } from "inversify";
import { MonthlyStatsRepository } from "./MonthlyStatsRepository";
import { action, observable, reaction } from "mobx";
import { IMonthlyStatsDTO } from "../DTO";
import _ from "lodash";

@injectable()
export class MonthlyStatsPresenter {

    @inject(MonthlyStatsRepository)
    private monthlyStatsRepository: MonthlyStatsRepository


    @observable
    public topBilling: number = 0

    public activeViewIndex: number = 0;

    @observable
    public totalIndex: number = 0;

    @observable
    public stats: IMonthlyStatsDTO[][] = [];

    @observable
    public activeStats: IMonthlyStatsDTO[] = [];

    @action
    public switchViewTo(position: number) {
        if (position < 0 || position >= this.stats.length) return
        this.activeViewIndex = position
        this.activeStats = this.stats[position] || []
    }

    private updateTopBilling(stats: IMonthlyStatsDTO[]) {
        const topTarget = _.maxBy(stats, (item) => item.billing)
        this.topBilling = topTarget ? topTarget.billing : 0
        this.stats = _.chunk(stats, 6)
        this.switchViewTo(this.stats.length - 1)
    }

    @postConstruct()
    public init() {
        this.updateTopBilling(this.monthlyStatsRepository.monthlyStats);

        reaction(
            () => this.monthlyStatsRepository.monthlyStats,
            (stats) => { this.updateTopBilling(stats) }
        )
    }
}