import { inject, injectable } from "inversify";
import { OverviewRepository } from "./OverviewRepository";

@injectable()
export class OverViewPresenter {

    @inject(OverviewRepository)
    private overViewRepository: OverviewRepository

    get stats() {
        return this.overViewRepository.candidateOverviewStats;
    }
}