import { action, computed, observable, toJS } from 'mobx'
import { upperFirst } from 'lodash'
import { StepsRepository } from 'app/Steps/StepsRepository'
import { AbstractClassValidator, MAX_LENGTH, MIN_LENGTH, validated } from '@logicroom/validator'
import { ITextInput } from 'app/Shared/TextInput/ITextInput'
import { CandidatesRepository } from '../CandidatesRepository'
import { inject, injectable } from 'inversify'
import 'reflect-metadata'
import { STEP_CANDIDATE_LIMIT } from "config/constants";
import { ICandidate } from "app/Candidates/ICandidate";
import { AuthenticationRepository } from "app/Authentication/AuthenticationRepository";
import { JobsRepository } from 'app/RightSidePanel/Jobs/JobsRepository'
import { IJob } from 'app/RightSidePanel/Jobs/IJobs'
import { ActionPresenter } from '../Actions/ActionPresenter'


export type TJob = 'createNewJob' | 'fromOldJob';
@injectable()
export class AddCandidatePresenter extends AbstractClassValidator {

  @inject(CandidatesRepository)
  private candidatesRepository: CandidatesRepository

  @inject(JobsRepository)
  private jobsRepository: JobsRepository

  @inject(StepsRepository)
  private stepsRepository: StepsRepository

  @inject(AuthenticationRepository)
  private authenticationRepository: AuthenticationRepository

  @inject(ActionPresenter)
  private actionPresenter: ActionPresenter

  @observable
  public serverErrors = []

  private candidateActiveStepName: string

  @validated({
    mandatory: true,
    rules: [
      {
        rule: MAX_LENGTH(20),
        errorMessage: 'First name too long, up to 20 characters allowed.'
      },
      {
        rule: MIN_LENGTH(2),
        errorMessage: 'First name must be at least 3 characters long.'
      }
    ]
  })
  public firstName: ITextInput = {
    placeholder: 'First Name',
    label: 'First Name'
  }

  @validated({
    mandatory: true,
    rules: [
      {
        rule: MAX_LENGTH(20),
        errorMessage: 'Last name too long, up to 20 characters allowed.'
      },
      {
        rule: MIN_LENGTH(2),
        errorMessage: 'Last name must be at least 3 characters long.'
      }
    ]
  })
  public lastName: ITextInput = { placeholder: 'Last Name', label: 'Last Name' }

  @validated({
    mandatory: true,
    rules: [
      {
        rule: MAX_LENGTH(255),
        errorMessage: 'Job Identifier too long, up to 255 characters allowed.'
      },
      {
        rule: MIN_LENGTH(2),
        errorMessage: 'Job Identifier should be at least 3 chars'
      }
    ]
  })
  public jobIdentifier: ITextInput = { placeholder: 'Job Identifier', label: 'Job Identifier' }

  @observable
  public jobType: TJob = 'fromOldJob';

  @action
  public updateJobType(type: TJob) {
    this.jobType = type;
  }

  public get activeJobType(): TJob {
    return this.jobType;
  }

  @observable
  public activeJob: IJob;

  @action
  public updateActiveJob(job: IJob) {
    this.activeJob = job;
  }

  @observable
  public isOpen: boolean = false

  @observable
  public isCandidateDetailsOpen: boolean = false

  @observable
  public candidate: ICandidate = null

  @computed
  public get canSubmit(): boolean {
    const activeJob = toJS(this.activeJob) || {}
    let jobHasValidInput: boolean = false
    if (this.activeJobType === 'createNewJob') jobHasValidInput = this.jobIdentifier.isValid
    if (this.activeJobType == 'fromOldJob') jobHasValidInput = Boolean(activeJob.id)
    return (
      this.lastName.isValid &&
      this.firstName.isValid &&
      jobHasValidInput &&
      this.stepsRepository.steps[0].candidates.length < STEP_CANDIDATE_LIMIT
    )
  }

  @computed
  public get updateFirstName() {
    return { ...this.firstName, onChange: this.upperFirstName }
  }

  @computed
  public get updateLastName() {
    return { ...this.lastName, onChange: this.upperLastName }
  }

  @computed
  public get updateJobIdentifier() {
    return { ...this.jobIdentifier, onChange: this.lowerCaseJobIdentifier }
  }

  @action
  public upperFirstName = (value): void => {
    this.firstName.onChange(upperFirst(value))
  }

  @action
  public upperLastName = (value): void => {
    this.lastName.onChange(upperFirst(value))
  }

  @action
  public lowerCaseJobIdentifier = (value): void => {
    this.jobIdentifier.onChange(value)
  }

  public submitEdit = async () => {
    if (this.canSubmit) {
      this.candidatesRepository.editCandidate(
        this.firstName.value,
        this.lastName.value,
        this.jobType == 'createNewJob' ? this.jobIdentifier.value : this.activeJob.id,
        this.authenticationRepository.user.workflowId,
        this.candidate.candidateId,
        this.candidateActiveStepName
      )
      this.cancel()
    }
  }
  public submit = async () => {
    if (this.canSubmit) {
      if (this.candidate === null) {
        const startStepId = this.stepsRepository.steps[0].stepId

        if (this.activeJobType === 'createNewJob') {
          this.candidatesRepository.addCandidate(
            this.firstName.value,
            this.lastName.value,
            this.jobIdentifier.value,
            startStepId,
          )
        }

        if (this.activeJobType === 'fromOldJob') {
          this.candidatesRepository.addCandidateWithJobId(
            this.firstName.value,
            this.lastName.value,
            this.activeJob.id,
            startStepId,
          )
        }
      }

      this.cancel()
    }
  }

  public cancel = async () => {
    this.isOpen = false
    this.isCandidateDetailsOpen = false
    this.firstName.value = ''
    this.lastName.value = ''
    this.jobIdentifier.value = ''
    this.candidate = null
    this.activeJob = undefined
  }

  public toggle = () => {
    this.isOpen = !this.isOpen
  }

  public openCandidateDetailsDialog = async (candidateId: string, stepName: string) => {
    this.candidateActiveStepName = stepName
    const workflowId = this.authenticationRepository.user.workflowId
    const candidate = await this.candidatesRepository
      .findCandidateById(
        candidateId,
        workflowId,
      )

    await this.actionPresenter.getActions(candidateId)
    this.candidate = candidate

    this.firstName.value = this.candidate.firstName
    this.lastName.value = this.candidate.lastName
    this.activeJob = this.jobsRepository.getJob(candidate.jobId)
    this.jobIdentifier.value = ""
    this.jobType = 'fromOldJob'
    this.toggle()
  }

  @computed
  public get canAdd() {
    return this.candidatesRepository.canAdd
  }

  @computed
  public get jobs() {
    return this.jobsRepository.jobs.map(job => {
      return { label: job.name, value: job }
    })
  }
}
