import { AuthenticationRepository } from "app/Authentication/AuthenticationRepository";
import { AbstractServiceGateway } from "gateways/service/AbstractServiceGateway";
import { inject, injectable } from "inversify";
import { ICompany } from "../ICompany";
import { action, observable } from "mobx";
import { ICompanyResponseDto } from "gateways/service/stubs/dtos";

@injectable()
export class CompanyOptionsRepository {
    @inject(AbstractServiceGateway)
    private serviceGateway: AbstractServiceGateway

    @inject(AuthenticationRepository)
    private authenticationRepository: AuthenticationRepository

    @observable
    public companyList: ICompany[] = []

    public getCompany(id: number): ICompany {
        return this.companyList.find(i => i.id == id)
    }

    @action
    public async loadCompany() {
        let myParams: any = { start: 0, end: 10000 }
        let parems = new URLSearchParams(myParams).toString();
        const response = await this.serviceGateway.get<ICompanyResponseDto>(
            `/culabr-admin/company?${parems}`,
            { 'Authorization': this.authenticationRepository.token } //Override the nested view token
        )
        if (!response.success) return
        this.companyList = response.result.data
    }
}