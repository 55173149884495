import { AbstractServiceGateway } from "gateways/service/AbstractServiceGateway";
import { inject, injectable } from "inversify";
import { observable } from "mobx";
import { IMonthlyStatsDTO } from "../DTO";
import { IBaseResponseDTO } from "gateways/service/stubs/dtos";
import { toast } from "react-toastify";

@injectable()
export class MonthlyStatsRepository {
    @inject(AbstractServiceGateway)
    private serviceGateway: AbstractServiceGateway


    @observable
    public monthlyStats: IMonthlyStatsDTO[] = []

    public async getStats() {
        const result = await this.serviceGateway.get<IBaseResponseDTO<IMonthlyStatsDTO[]>>('/company-admin/stats/monthly')
        if (!result.success) return toast.error(result.message)
        this.monthlyStats = result.result;
    }
}