import { Modal } from 'app/Shared/Modal/Modal'
import { TextInput } from 'app/Shared/TextInput/TextInput'
import { withPresenter } from 'app/withPresenter'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AddCompanyPresenter } from './AddCompanyPresenter'
import { Wrapper, CaptionText, InputWrapper } from '../styles'
interface IProps {
  presenter: AddCompanyPresenter
}

@observer
class AddCompany extends React.Component<IProps> {
  onKeyDown = e => {
    if (e.key === 'Enter' && this.props.presenter.canSubmit) {
      this.props.presenter.submit()
    } else if (e.key === 'Escape') {
      this.props.presenter.cancel()
    }
  }

  handleDropdownAdminSelection(select) {}

  render(): React.ReactNode {
    const {
      isOpen,
      isDelete,
      selectedCompany,
      updateFirstName,
      updateLastName,
      updateEmailAddress,
      updateCompanyName,
      canSubmit,
      submitEdit,
      submit,
      cancel
    } = this.props.presenter

    const isEditMode = selectedCompany === null ? false : true
    if (!isOpen) return null
    return (
      <Modal
        outsideClickClose={false}
        closeModal={cancel}
        showHeader={true}
        title={'Add a Company'}
        onSaveClick={isEditMode ? submitEdit : submit}
        saveButtonDisabled={!canSubmit}
        saveButtonColor={isDelete && 'danger'}
      >
        <Wrapper onKeyDown={this.onKeyDown}>
          <CaptionText className={isDelete && isEditMode && 'text-center'}>
            Add company details below. Once created, the admin will receive an
            email invite link to register with Culabr.
          </CaptionText>
          {!isEditMode && !isDelete && (
            <>
              <InputWrapper>
                <TextInput
                  input={updateFirstName}
                  theme="secondary"
                  showAsterisk={true}
                />
                <TextInput
                  input={updateLastName}
                  theme="secondary"
                  showAsterisk={true}
                />
              </InputWrapper>
              <InputWrapper>
                <TextInput
                  input={updateEmailAddress}
                  theme="secondary"
                  showAsterisk={true}
                />
              </InputWrapper>
              <InputWrapper>
                <TextInput
                  input={updateCompanyName}
                  theme="secondary"
                  showAsterisk={true}
                />
              </InputWrapper>
            </>
          )}
        </Wrapper>
      </Modal>
    )
  }
}

export default withPresenter(AddCompanyPresenter)(AddCompany)
