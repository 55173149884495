import { AbstractServiceGateway } from 'gateways/service/AbstractServiceGateway'
import { IAddRecruiterRequestDTO, IBaseResponseDTO, ICulabrAdminEditRecruiterRequestDTO, IDeleteRecruiterRequestDTO, IEditRecruiterRequestDTO, IPaginationResponseDTO, IRecruiterEditCompanyRequestDTO, IRecruiterResponseDto } from 'gateways/service/stubs/dtos'
import { inject, injectable, postConstruct } from 'inversify'
import { action, observable, reaction } from 'mobx'
import { IRecruiter } from './IRecruiter'
import { AuthenticationRepository } from 'app/Authentication/AuthenticationRepository'
import { TAuthUserRole } from 'app/Authentication/TAuthUserRole'
import { toast } from 'react-toastify'
import { ITokenContext } from 'app/Authentication/IAuthUser'

@injectable()
export class ManageRecruiterRepository {
  static NUMBER_OF_ROWS_PER_PAGE = 15

  @inject(AbstractServiceGateway)
  private serviceGateway: AbstractServiceGateway

  @inject(AuthenticationRepository)
  private authenticationRepository: AuthenticationRepository

  @postConstruct()
  public init() {
    reaction(
      () => this.authenticationRepository.tokenContext,
      async (tokenContext: ITokenContext) => {
        if (!tokenContext) return;
        if (this.authenticationRepository.tokenContext.role !== TAuthUserRole.COMPANY_ADMIN) return
        if (!this.isInitialDataLoaded)
          await this.loadRecruiter(0, ManageRecruiterRepository.NUMBER_OF_ROWS_PER_PAGE)
        await this.loadRecruiter()
      }
    )
  }

  @observable
  public isInitialDataLoaded: boolean = false

  @observable
  public recruiterList: IRecruiter[] = []

  @observable
  public pagination: IPaginationResponseDTO

  public getRecruiter(id: number): IRecruiter {
    return this.recruiterList.find(i => i.id == id)
  }

  @action
  public async editCompany(payload: IRecruiterEditCompanyRequestDTO) {
    return await this.serviceGateway.post<IBaseResponseDTO>(
      '/company-admin/edit-company',
      payload
    )
  }

  @action
  public async loadRecruiter(start?: number, end?: number) {
    let isReload = !Boolean(start || end)
    if (isReload && this.pagination) {
      start = this.pagination.active.start
      end = this.pagination.active.end
    }
    const response = await this.serviceGateway.get<IRecruiterResponseDto>(
      `/company-admin/recruiter?start=${start}&end=${end}`
    )
    if (response.success) {
      this.recruiterList = response.result.data
      this.pagination = response.result.pagination
      this.isInitialDataLoaded = true
    } else toast.error(response.message)
  }

  @action
  public async getViewAsToken(payload: Partial<ITokenContext>) {
    return await this.serviceGateway.post<IBaseResponseDTO>(
      '/company-admin/recruiter/token',
      payload
    )
  }

  @action
  public async addRecruiter(payload: IAddRecruiterRequestDTO) {
    const result = await this.serviceGateway.post<IBaseResponseDTO>(
      '/company-admin/recruiter/add',
      payload
    )
    if (result.success) await this.loadRecruiter()
    return result
  }

  @action
  public async editRecruiter(payload: IEditRecruiterRequestDTO) {
    const result = await this.serviceGateway.post<IBaseResponseDTO>(
      '/company-admin/recruiter/edit',
      payload
    )
    if (result.success) await this.loadRecruiter()
    return result
  }

  @action
  public async deleteRecruiter(payload: IDeleteRecruiterRequestDTO) {
    const result = await this.serviceGateway.post<IBaseResponseDTO>(
      '/company-admin/recruiter/delete',
      payload
    )
    if (result.success) await this.loadRecruiter()
    return result
  }

  @action
  public async culabrAdminEditRecruiter(requestDto: ICulabrAdminEditRecruiterRequestDTO) {
    const result = await this.serviceGateway.post<IBaseResponseDTO>(
      `/culabr-admin/edit-recruiter`,
      requestDto,
      { 'Authorization': this.authenticationRepository.token } //Override the nested view token
    )
    if (result.success) await this.loadRecruiter()
    return result
  }
}
