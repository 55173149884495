import {
  ILeaderBoardDto,
  TLeaderBoardField,
  TLeaderBoardTimeFrame,
  menuOrderOptions, menuTimeOptions
} from "app/Actions/LeaderBoardList/ILeaderBoardDto";
import { LeaderBoardRepository } from "app/Actions/LeaderBoardList/LeaderBoardRepository";
import { inject, injectable, postConstruct } from 'inversify';
import { action, computed, observable } from "mobx";
import 'reflect-metadata';

@injectable()
export class LeaderBoardListPresenter {

  @inject(LeaderBoardRepository)
  private leaderBoardRepository: LeaderBoardRepository

  @observable
  public selectedOrder: TLeaderBoardField = 'cumulativeDealFee'

  @observable
  public selectedTimeFrame: TLeaderBoardTimeFrame = 'month'

  @observable
  public orderedList: ILeaderBoardDto[] = []

  private timeoutLimit = 30e3
  private runningTime = 0

  @postConstruct()
  public async init() {
    await this.poll()
  }

  @action
  public selectOrder = (order: TLeaderBoardField): void => {
    this.setSelectedOrder(order)
  }

  @action
  public selectTimeFrame = (time: TLeaderBoardTimeFrame): void => {
    this.setSelectedTimeFrame(time)
  }

  @computed
  public get selectedOrderValue(): string {
    return menuOrderOptions.find(item => item.key === this.selectedOrder).value
  }

  @computed
  public get selectedTimeFrameValue(): string {
    return menuTimeOptions.find(item => item.key === this.selectedTimeFrame).value
  }

  private async poll() {
    this.runningTime = this.runningTime + 1000

    if (this.runningTime >= this.timeoutLimit) {
      throw new Error('Timeout, refresh the page')
    } else {
      await this.fetchData()
      await setTimeout(() => this.poll(), 5e3)
    }
  }

  public setSelectedOrder = (order: TLeaderBoardField): void => {
    this.selectedOrder = order
    this.fetchData()
  }

  public setSelectedTimeFrame = (type: TLeaderBoardTimeFrame): void => {
    this.selectedTimeFrame = type
    this.fetchData()
  }

  private fetchData() {
    this.leaderBoardRepository
      .fetchData(this.selectedOrder, this.selectedTimeFrame)
      .then(
        data => {
          this.runningTime = 0
          this.setData(data)
        },
        err => console.log(err)
      )
  }

  private setData(data: ILeaderBoardDto[]) {
    this.orderedList = data
  }
}
