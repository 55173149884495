import * as React from 'react'
import styled from 'styled-components'

const Input = styled.input`
  height: 25px;
  width: 25px;
  margin-left: 20px;
  cursor: pointer;
  
  &:before {
    background-color: black;
  }
  
  ${(props: Partial<IProps>) => props.extraStyles};
`

interface IProps {
  name?: string
  type?: string
  value?: string
  onChange?: (value: boolean) => void
  disabled?: boolean
  extraStyles?: {}
}

export const CheckboxInput: React.FunctionComponent<IProps> = props => {
  const {
    type = 'checkbox',
    name,
    value,
    extraStyles,
    onChange = () => {},
    disabled = false
  } = props

  const IProps = {
    name,
    extraStyles,
    type,
    value,
    disabled,
    onChange: e => {
      onChange(e.target.checked)
    }
  }

  return <Input {...IProps} />
}
