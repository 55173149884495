import { Icon } from 'app/Shared/Icon/Icon'
import { Table } from 'app/Shared/Table/table'
import { withPresenter } from 'app/withPresenter'
import * as React from 'react'
import styled from 'styled-components'
import { colors } from 'styles/colors'
import { ISelectedCompany } from '../ICompany'
import { CompanyListPresenter } from './CompanyListPresenter'
import { observer } from 'mobx-react'
import { IPaginationDTO } from 'gateways/service/stubs/dtos'
import { CompanyListRepository } from './CompanyListRepository'
import { DashboardViews } from 'app/Routing/TAvailableView'

interface IProps {
  presenter: CompanyListPresenter
}

const Wrapper = styled.div`
  background: ${colors.dashboardBg};
  padding: ${({ theme }) => theme.spacing(1, 2, 2, 2)};
  height: 90%;
  flex: 1;
`

const ActionWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(3)};
  justify-content: flex-end;
`

const TableRow = styled.tr`
  &:first-of-type {
    td {
      padding-top: 20px;
    }
  }
`

const TableCell = styled.td`
  padding: 10px 24px 10px 0;
  font-size: ${({ theme }) => theme.fontSizes.sm};

  &:last-of-type {
    padding-right: 0;
  }
`

const Link = styled.a`
  text-decoration: underline;
  cursor: pointer;
`

const columns = [
  'Company',
  'First Name',
  'Last Name',
  'Email Address',
  'Date Added'
]
const fields = ['name', 'firstName', 'lastName', 'email', 'createDate']

@observer
class CompanyListComponent extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props)
  }

  componentDidMount(): void {
    this.props.presenter.loadCompany({
      start: 0,
      end: CompanyListRepository.NUMBER_OF_ROWS_PER_PAGE
    })
  }

  handleCompanyEditIconClick(company: ISelectedCompany) {
    this.props.presenter.handleCompanyEditButton(company)
  }

  handleCompanyDeleteIconClick(company: ISelectedCompany) {
    this.props.presenter.handleOnDeleteIconClick(company)
  }

  handleViewAsClick(company: ISelectedCompany, view: DashboardViews) {
    this.props.presenter.handleOnViewAsClick(company, view)
  }

  reloadCompany(pagination: IPaginationDTO) {
    this.props.presenter.loadCompany(pagination)
  }

  public render() {
    if (!this.props.presenter.pagination) return <div />
    const {
      last,
      active: { start, end },
      next,
      previous,
      hasNext,
      first,
      totalRows
    } = this.props.presenter.pagination

    return (
      <Wrapper>
        {this.props.presenter.company.length > 0 && (
          <Table
            columns={columns}
            fields={fields}
            actionColWidth={'170px'}
            hasActionCol={true}
            start={start}
            end={end}
            totalRow={totalRows}
            onNextPage={() => this.reloadCompany(next)}
            onPrevPage={() => this.reloadCompany(previous)}
            onFirstPage={() => {
              this.reloadCompany(first)
            }}
            onLastPage={() => this.reloadCompany(last)}
            hasNextPage={hasNext}
          >
            {this.props.presenter.company.map((item, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Link
                    onClick={() =>
                      this.handleViewAsClick(
                        item,
                        DashboardViews.MANAGE_RECRUITERS
                      )
                    }
                  >
                    {item.name}
                  </Link>
                </TableCell>
                <TableCell>{item.firstName}</TableCell>
                <TableCell>{item.lastName}</TableCell>
                <TableCell>{item.email}</TableCell>
                <TableCell>{item.createDate}</TableCell>
                <TableCell>
                  <ActionWrapper>
                    <Icon
                      extraStyles={{ cursor: 'pointer' }}
                      color={colors.darkText}
                      name={'edit'}
                      title={'Edit'}
                      onClick={() => this.handleCompanyEditIconClick(item)}
                    />
                    <Icon
                      extraStyles={{ cursor: 'pointer' }}
                      color={colors.darkText}
                      name={'barchart'}
                      title={''}
                      onClick={() => {
                        this.handleViewAsClick(
                          item,
                          DashboardViews.MANAGER_DASHBOARD
                        )
                      }}
                    />
                    <Icon
                      extraStyles={{ cursor: 'pointer' }}
                      color={colors.darkText}
                      name={'eye'}
                      title={'View as User'}
                      onClick={() => {
                        this.handleViewAsClick(
                          item,
                          DashboardViews.MANAGE_RECRUITERS
                        )
                      }}
                    />
                    <Icon
                      extraStyles={{ cursor: 'pointer' }}
                      color={colors.darkText}
                      name={'bin'}
                      title={'Delete'}
                      onClick={() => this.handleCompanyDeleteIconClick(item)}
                    />
                  </ActionWrapper>
                </TableCell>
              </TableRow>
            ))}
          </Table>
        )}
      </Wrapper>
    )
  }
}

export default withPresenter(CompanyListPresenter)(CompanyListComponent)
