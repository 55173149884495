import * as React from 'react'
import {observer} from 'mobx-react'
import {RegisterVerificationPresenter} from './RegisterVerificationPresenter'
import styled from 'styled-components'
import {withPresenter} from 'app/withPresenter'
import {AuthenticationPageWrapper} from '../AuthenticationPageWrapper/AuthenticationPageWrapper'
import * as logoIcon from 'assets/logoBlue.svg'
import {Button} from "app/Shared/Button/Button";
import {colors} from "styles/colors";

const Logo = styled.img`
  margin: 20px 0;
  width: 90px;
`
const Paragraph = styled.p`
  margin: 30px 0 0;
  line-height: 1.5em;
  text-align: justify;
`

const Title = styled.h1`
  margin-bottom: 20px;
`

const Centered = styled.div`
  width: 100%;
  text-align: center;
  
  button { 
    margin: 40px auto 5px;
  }
`

const Error = styled.div`
  align-self: center;
  font-weight: normal;
  font-size: 1em;
  color: ${colors.errorText};
  padding: 14px;
  margin: 20px 30px;
  border: 1px dashed ${colors.borderError};
  padding: 4px;
`

const Wrapper = styled.div``

interface IProps {
  presenter: RegisterVerificationPresenter
}

const RegisterVerificationComponent = observer((props: IProps) => {
  const {
    userData,
    errorMessage,
    isSuccess,
    isManagerVerification,
    submit,
  } = props.presenter
  return (
    <AuthenticationPageWrapper>
      <Logo src={logoIcon} />

      {isSuccess === false &&
        <Wrapper>
          <Error>{errorMessage}</Error>
        </Wrapper>
      }

      {isSuccess === true && !isManagerVerification &&
        <Wrapper>
          <Title>Your account is now verified</Title>
          <Paragraph>
            Thanks for verifying your email, {userData.firstName} {userData.lastName}.
            You are now ready to start using Culabr.
            Click the button below and proceed to the Sign In.
          </Paragraph>

        </Wrapper>
      }

      {isSuccess === true && isManagerVerification &&
        <Wrapper>
          <Title>Your agent is now verified</Title>
          <Paragraph>
            Thanks {userData.managerFirstName} {userData.managerLastName} for verifying {userData.firstName} {userData.lastName} email.
            We will send an email to your agent to complete the account verification on their side.
            You can now close this page.
          </Paragraph>
        </Wrapper>
      }

      {!isManagerVerification &&
        <Centered>
          <Button
            theme="secondary"
            text="Back to Sign in"
            onClick={submit}
          />
        </Centered>
      }

    </AuthenticationPageWrapper>
  )
})

export default withPresenter(RegisterVerificationPresenter)(RegisterVerificationComponent)
