import { AbstractServiceGateway } from "gateways/service/AbstractServiceGateway";
import { IBaseResponseDTO } from "gateways/service/stubs/dtos";
import { inject, injectable, postConstruct } from "inversify";
import { toast } from "react-toastify";
import { ICandidateOverviewStatsDTO } from "../DTO";
import { observable, reaction } from "mobx";
import { StatusRepository } from "app/StatusBar/StatusRepository";
import { TStatsFilter } from "../RecruiterStats/RecruiterStatsRepository";
import { AuthenticationRepository } from "app/Authentication/AuthenticationRepository";
import { TAuthUserRole } from "app/Authentication/TAuthUserRole";
import { DashboardViews } from "app/Routing/TAvailableView";

@injectable()
export class OverviewRepository {
    @inject(AbstractServiceGateway)
    private serviceGateway: AbstractServiceGateway

    @inject(StatusRepository)
    private statusRepository: StatusRepository

    @inject(AuthenticationRepository)
    private authenticationRepository: AuthenticationRepository

    @postConstruct()
    private init() {
        //@ts-ignore
        this.statsFilter = this.statusRepository.activeCoinFilter
        reaction(
            () => this.statusRepository.activeCoinFilter,
            async (filter: TStatsFilter) => {
                let { view } = this.authenticationRepository.activeNestedViewContext || {}
                const isCompanyAdmin = this.authenticationRepository.activeTokenContext.role === TAuthUserRole.COMPANY_ADMIN;
                const isNestedViewIsManagerView = view === DashboardViews.MANAGER_DASHBOARD;
                if (isCompanyAdmin || isNestedViewIsManagerView) {
                    this.statsFilter = filter;
                    await this.getStats(filter);
                }
            }
        )
    }

    @observable
    public statsFilter: TStatsFilter = 'currentMonth'

    @observable
    public candidateOverviewStats: ICandidateOverviewStatsDTO[] = []

    public async getStats(filter = this.statsFilter) {
        const result = await this.serviceGateway.get<IBaseResponseDTO<ICandidateOverviewStatsDTO[]>>(`/company-admin/stats/candidates?filter=${filter}`)
        if (!result.success) return toast.error(result.message)
        this.candidateOverviewStats = result.result;
    }
}