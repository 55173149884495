import { AbstractClassValidator } from '@logicroom/validator'
import { DropdownOption } from 'app/Shared/Dropdown/Dropdown'
import { inject, injectable } from 'inversify'
import { action, observable } from 'mobx'
import { ICompany } from '../ICompany'
import { CompanyListRepository } from '../CompanyList/CompanyListRepository'
import { LoaderPresenter } from 'app/Shared/Loader/LoaderPresenter'

export enum DeleteActions {
  DELETE_RECRUITERS = 'DELETE_RECRUITERS',
  MOVE_RECRUITERS_TO_FREELANCE = 'MOVE_RECRUITERS_TO_FREELANCE'
}

@injectable()
export class DeleteCompanyPresenter extends AbstractClassValidator {
  serverErrors?: string[]

  @inject(CompanyListRepository)
  private manageCompanyRepository: CompanyListRepository

  @inject(LoaderPresenter)
  private loaderPresenter: LoaderPresenter

  @observable
  public deleteOptions: DropdownOption[] = [
    {
      value: DeleteActions.DELETE_RECRUITERS,
      label: 'Delete Company, Delete Recruiters'
    },
    {
      value: DeleteActions.MOVE_RECRUITERS_TO_FREELANCE,
      label: 'Delete Company, Move Recruiters to Freelance'
    }
  ]

  @observable
  public activeDeleteAction: DropdownOption

  @observable
  public activeCompany: ICompany;

  @observable
  public isOpen: boolean = false

  @action
  public toggle(company?: ICompany) {
    this.isOpen = !this.isOpen
    this.activeCompany = company
  }

  @action
  public updatedActiveSelection(selected: DropdownOption) {
    this.activeDeleteAction = selected
  }

  @action
  public async deleteCompany() {
    this.loaderPresenter.showLoader()
    const result = await this.manageCompanyRepository.deleteCompany(
      this.activeCompany,
      this.activeDeleteAction.value
    ).finally(() => {
      this.loaderPresenter.hideLoader()
    })
    if (result.success) this.toggle();
  }
}
