import React, { CSSProperties } from 'react'
import styled, { css } from 'styled-components'
import { IconWrapper } from 'styles'
import { colors } from 'styles/colors'
import { Badge } from 'app/Shared/Badge/Badge'
import Lottie from 'react-lottie'
// @ts-ignore
import * as coinAnimation from 'assets/animations/coin.json'
// @ts-ignore
import * as cvsAnimation from 'assets/animations/cvs.json'
// @ts-ignore
import * as moneyAnimation from 'assets/animations/deal.json'
// @ts-ignore
import * as jobAnimation from 'assets/animations/job.json'
import { withPresenter } from 'app/withPresenter'
import { RecruiterStatsPresenter } from './RecruiterStatsPresenter'
import { IPresenter } from 'config/Config'
import { observer } from 'mobx-react'
import CurrencyUtil from 'app/CurrencyUtil'

const TargetWrapper = styled.div`
  min-height: 285px;
  max-height: 285px;
  border: 1px solid #e6e6e6;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 1%;
  overflow-y: auto;
`

const TargetItemWrapper = styled.div<CSSProperties>`
  height: 50px;
  margin-bottom: 8px;

  display: grid;
  align-content: end;
  justify-content: center;
  grid-template-rows: 1;
  grid-template-columns: [name] 200px repeat(4, 80px) [target-bar] 1fr [target-percentage] 100px [last-column];
  grid-template-areas: 'name placement jobs cvs coins target-bar target-percentage';
  gap: 18px;
`
const Name = styled.div`
  cursor: pointer;
  color: #000143;
  grid-area: name;
  align-self: center;
  font-size: 14px;
  text-decoration-line: underline;
`
const Placement = styled.div`
  grid-area: placement;
  margin-top: 5px;
  font-size: smaller;
  justify-self: center;
`
const Jobs = styled.div`
  grid-area: jobs;
  margin-top: 5px;
  font-size: smaller;
  justify-self: center;
`
const CV = styled.div`
  grid-area: cvs;
  margin-top: 5px;
  font-size: smaller;
  justify-self: center;
`
const Coins = styled.div`
  grid-area: coins;
  margin-top: 5px;
  font-size: smaller;
  justify-self: center;
`

const TargetBarWrapper = styled.div<any>`
  grid-area: target-bar;

  ${props =>
    props.max &&
    css`
      grid-column-end: last-column;
    `}
`
const TargetBarHint = styled.div`
  text-align: end;
  font-size: x-small;
  height: 6px;
  margin-bottom: 8px;
  color: ${colors.midSkyBlue};
`

const TargetBar = styled.div<CSSProperties>`
  border-radius: 20px;
  position: relative;
  background: ${colors.lightGray};
  height: 30px;
`

const TargetProgress = styled.div<CSSProperties>`
  position: absolute;
  border-radius: 20px;
  background: ${colors.mainBg};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: end;
  color: ${colors.whiteText};
  padding: 7px;

  ${props =>
    props.width &&
    css`
      right: ${100 - (Number(props.width) || 0)}%;
    `}

  ${props =>
    props.width &&
    Number(props.width) === 0 &&
    css`
      display: none;
    `}
`

const TargetPercentage = styled.div`
  grid-area: target-percentage;
  align-self: center;
  justify-self: end;
  padding-right: 5px;
`
@observer
class RecruiterStats extends React.Component<
  IPresenter<RecruiterStatsPresenter>
> {
  render(): React.ReactNode {
    const { stats, statsFilter, topTarget } = this.props.presenter
    return (
      <TargetWrapper>
        <TargetItemWrapper>
          <Placement> Placement</Placement>
          <Jobs>Jobs</Jobs>
          <Coins>Coins</Coins>
          <CV>CVs</CV>
          {statsFilter !== 'total' && (
            <TargetPercentage style={{ fontSize: 'small' }}>
              {statsFilter === 'currentMonth' ? 'Monthly' : 'Annual'} Target
            </TargetPercentage>
          )}
        </TargetItemWrapper>

        {stats.map((item, index) => {
          let target = 0
          if (statsFilter == 'currentMonth') target = item.monthly_target
          if (statsFilter == 'currentYear') target = item.annual_target
          if (statsFilter == 'total') target = topTarget
          let progressPercentage = item.monthly_target
            ? (item.cumulativeDealFee / target) * 100
            : 0

          progressPercentage = Math.ceil(progressPercentage)
          return (
            <TargetItemWrapper key={index}>
              <Name
                onClick={() => this.props.presenter.handleOnViewAsClick(item)}
              >
                {item.firstName} {item.lastName}
              </Name>
              <Placement>
                <IconWrapper>
                  <Lottie
                    options={{
                      loop: false,
                      autoplay: false,
                      animationData: moneyAnimation.default
                    }}
                    width={35}
                    height={35}
                    style={{ margin: '0' }}
                    isStopped={true}
                  />
                  <Badge
                    badgeContent={`${item.placements}`}
                    hasBorder={true}
                    borderColor={colors.white}
                  />
                </IconWrapper>
              </Placement>
              <Jobs>
                <IconWrapper>
                  <Lottie
                    options={{
                      loop: false,
                      autoplay: false,
                      animationData: jobAnimation.default
                    }}
                    width={35}
                    height={35}
                    style={{ margin: '0' }}
                    isStopped={true}
                  />
                  <Badge
                    badgeContent={`${item.jobs}`}
                    hasBorder={true}
                    borderColor={colors.white}
                  />
                </IconWrapper>
              </Jobs>
              <CV>
                <IconWrapper>
                  <Lottie
                    options={{
                      loop: false,
                      autoplay: false,
                      animationData: cvsAnimation.default
                    }}
                    width={35}
                    height={35}
                    style={{ margin: '0' }}
                    isStopped={true}
                  />
                  <Badge
                    badgeContent={`${item.cvSubmits}`}
                    hasBorder={true}
                    borderColor={colors.white}
                  />
                </IconWrapper>
              </CV>
              <Coins>
                <IconWrapper>
                  <Lottie
                    options={{
                      loop: false,
                      autoplay: false,
                      animationData: coinAnimation.default
                    }}
                    width={35}
                    height={35}
                    style={{ margin: '0' }}
                    isStopped={true}
                  />
                  <Badge
                    badgeContent={`${item.coins}`}
                    hasBorder={true}
                    borderColor={colors.white}
                  />
                </IconWrapper>
              </Coins>
              <TargetBarWrapper max={statsFilter === 'total'}>
                {statsFilter !== 'total' && (
                  <TargetBarHint>
                    Target: {CurrencyUtil.format(Math.ceil(target))}
                  </TargetBarHint>
                )}
                <TargetBar>
                  <TargetProgress
                    width={
                      progressPercentage > 100
                        ? '100'
                        : progressPercentage.toString()
                    }
                  >
                    {CurrencyUtil.format(item.cumulativeDealFee)}
                  </TargetProgress>
                </TargetBar>
              </TargetBarWrapper>
              {statsFilter !== 'total' && (
                <TargetPercentage>{progressPercentage}%</TargetPercentage>
              )}
            </TargetItemWrapper>
          )
        })}
      </TargetWrapper>
    )
  }
}

export default withPresenter(RecruiterStatsPresenter)(RecruiterStats)
