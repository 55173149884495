import * as React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react'
import Lottie from 'react-lottie'
import { FullScreenAnimationPresenter } from 'app/Shared/FullScreen/FullScreenAnimationPresenter'
import { withPresenter } from 'app/withPresenter'
import { EmptyElement } from 'styles'

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1001;
`

interface IPresenterProps {
  presenter: FullScreenAnimationPresenter
}

const FullScreenAnimation = observer((props: IPresenterProps) => {
  let { loop, autoplay, animationData } = props.presenter
  if (!animationData) {
    return <EmptyElement />
  }

  return (
    <Wrapper>
      <Lottie
        options={{
          loop,
          autoplay,
          animationData: animationData.default
        }}
      />
    </Wrapper>
  )
})

export default withPresenter(FullScreenAnimationPresenter)(FullScreenAnimation)
