import * as React from 'react'
import { container } from 'config/IOC'

export function withPresenter<TProps>(presenter: any) {
  return (
    Component: React.ComponentType<TProps & { presenter: any }>
  ): React.FunctionComponent<TProps> => {
    return props => {
      const data = { presenter: container.get(presenter) }
      return <Component {...props} {...data} />
    }
  }
}
