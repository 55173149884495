import { IStatusBadge, TStatusLevel } from "app/StatusBar/IStatusDto"

export type TLeaderBoardField = 'cumulativeDealFee' | 'cvSubmits' | 'coins'
export type TLeaderBoardTimeFrame = 'month' | 'year'

export interface ILeaderBoardOrder {
  type: TLeaderBoardField
}

export interface ILeaderBoardDto {
  index: number,
  userId: number,
  agentName: string,
  companyId: number,
  cumulativeDealFee: number,
  cvSubmits: number,
  coins: number,
  leadingCandidateProgress: number,
  badges: IStatusBadge,
  level: TStatusLevel
}

export const menuOrderOptions = [
  {
    key: 'cumulativeDealFee',
    value: 'Top Biller',
  },
  {
    key: 'coins',
    value: 'Highest Coin Count',
  },
  {
    key: 'cvSubmits',
    value: 'Highest CVs Submitted',
  }
]

export const menuTimeOptions = [
  {
    key: 'month',
    value: 'Monthly',
  },
  {
    key: 'year',
    value: 'Annual',
  }
]

