import { EditPencil } from 'app/Shared/Icon/icons/EditPencil'
import { Logout } from 'app/Shared/Icon/icons/Logout'
import * as React from 'react'
import styled from 'styled-components'
import { colors } from 'styles/colors'
import { ArrowIcon } from './icons/ArrowIcon'
import { BarChart } from './icons/BarChart'
import { BinIcon } from './icons/BinIcon'
import { CheckboxIcon } from './icons/CheckboxIcon'
import { CloseIcon } from './icons/CloseIcon'
import { ElipsisIcon } from './icons/ElipsisIcon'
import { EyeIcon } from './icons/EyeIcon'
import { NextIcon } from './icons/NextIcon'
import { PersonIcon } from './icons/PersonIcon'
import { PlusIcon } from './icons/PlusIcon'
import { PreviousIcon } from './icons/PreviousIcon'
import { SquareIcon } from './icons/SquareIcon'
import { StepBackward } from './icons/StepBackward'
import { StepForward } from './icons/StepForward'

interface IProps {
  size: number
  title: string
  extraStyles: {}
}

const Wrapper = styled.span`
  display: inline-block;
  vertical-align: baseline;
  width: ${(props: IProps) => `${props.size}px`};
  height: ${(props: IProps) => `${props.size}px`};
  ${(props: IProps) => props.extraStyles};
`

export const viewBox = '0 0 50 50'

export type TIPropsName =
  | 'arrow'
  | 'checkbox'
  | 'square'
  | 'person'
  | 'plus'
  | 'elipsis'
  | 'bin'
  | 'logout'
  | 'edit'
  | 'close'
  | 'barchart'
  | 'eye'
  | 'forward'
  | 'backward'
  | 'previous'
  | 'next'

export interface IIconProps {
  name: TIPropsName
  size?: number
  color?: string
  extraStyles?: {},
  title?: string
  onClick?: () => void
}

const iconMap = {
  arrow: ArrowIcon,
  checkbox: CheckboxIcon,
  square: SquareIcon,
  person: PersonIcon,
  plus: PlusIcon,
  elipsis: ElipsisIcon,
  bin: BinIcon,
  logout: Logout,
  edit: EditPencil,
  close: CloseIcon,
  barchart: BarChart,
  eye: EyeIcon,
  forward: StepForward,
  backward: StepBackward,
  previous: PreviousIcon,
  next: NextIcon
}

export const Icon: React.FunctionComponent<IIconProps> = props => {
  const {
    name,
    size = 24,
    extraStyles,
    title,
    color = colors.aluminium,
    onClick = () => {}
  } = props
  const NamedIcon = iconMap[name]
  return (
    <Wrapper size={size} extraStyles={extraStyles} title={title} onClick={onClick}>
      <NamedIcon fill={color}/>
    </Wrapper>
  )
}
