import { Dropdown } from 'app/Shared/Dropdown/Dropdown'
import { Modal } from 'app/Shared/Modal/Modal'
import { TextInput } from 'app/Shared/TextInput/TextInput'
import { withPresenter } from 'app/withPresenter'
import React from 'react'
import { EditCompanyPresenter } from './EditCompanyPresenter'
import { observer } from 'mobx-react'
import {
  Wrapper,
  CaptionText,
  OptionWrapper,
  Label,
  InputWrapper
} from '../styles'

interface IProps {
  presenter: EditCompanyPresenter
}

@observer
class EditCompany extends React.Component<IProps> {
  render(): React.ReactNode {
    const {
      isOpen,
      dropdownOptions,
      updatedCompanyName,
      canSubmit
    } = this.props.presenter
    if (!isOpen) return <div />
    return (
      <Modal
        closeModal={() => this.props.presenter.toggle()}
        title="Edit Company"
        showHeader
        onSaveClick={() => this.props.presenter.handleOnSaveClick()}
        saveButtonDisabled={!canSubmit}
      >
        <Wrapper>
          <CaptionText>Edit company details below.</CaptionText>
          <InputWrapper>
            <TextInput
              input={{
                value: updatedCompanyName,
                label: 'Company Name',
                onChange: value => {
                  this.props.presenter.updateCompanyName(value)
                }
              }}
              theme="secondary"
              showAsterisk={true}
            />
          </InputWrapper>
          <OptionWrapper>
            <Label>
              Change Admin<span>*</span>
            </Label>
            <Dropdown
              options={dropdownOptions}
              onSelect={select =>
                this.props.presenter.handleOnDropdownUserSelection(select)
              }
            />
          </OptionWrapper>
        </Wrapper>
      </Modal>
    )
  }
}

export default withPresenter(EditCompanyPresenter)(EditCompany)
