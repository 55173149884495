import * as logoIcon from 'assets/logoBlue.svg'
import * as React from 'react'
import styled, { css } from 'styled-components'
import { colors } from 'styles/colors'
import { Button } from '../Button/Button'
import { Icon } from '../Icon/Icon'

interface IProps {
  closeModal: () => void
  showHeader?: boolean
  hasButton?: boolean
  title?: string
  outsideClickClose?: boolean
  dialogWidth?: string
  onSaveClick?: () => void
  saveButtonColor?: string
  saveButtonText?: string
  saveButtonDisabled?: boolean
  hasDelete?: boolean
  hasSubmit?: boolean
  onDeleteClick?: () => void
  deleteButtonColor?: string
  deleteButtonText?: string
  deleteButtonDisabled?: boolean
}

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`

const Dialog = styled.div<IProps>`
  position: relative;
  margin: ${({ theme }) => theme.spacing(4)} auto;
  width: auto;
  max-width: 600px;

  display: flex;
  align-items: center;
  min-height: calc(100% - ${({ theme }) => theme.spacing(4)} * 2);

  ${props =>
    props.dialogWidth &&
    css`
      min-width: ${props.dialogWidth};
      max-width: ${props.dialogWidth};
    `}
`

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${colors.white};
  border-radius: ${({ theme }) => theme.spacing(1)};
  padding: ${({ theme }) => theme.spacing(3)};
  box-sizing: border-box;
`

const Backdrop = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: ${colors.blackBg};
  opacity: 0.3;
  z-index: 1000;
`

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: ${({ theme }) => theme.spacing(3)};
  color: ${colors.titleText};
`

const Logo = styled.img`
  margin: auto;
  width: ${({ theme }) => theme.spacing(4)};
`

const Title = styled.h2`
  margin: 0;
  text-align: center;
  font-weight: normal;
  line-height: 1.25;
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};
  padding-top: ${({ theme }) => theme.spacing(3)};

  button {
    width: 145px;
    margin: 0;
  }
`

export class Modal extends React.Component<IProps, {}> {
  public ref = React.createRef<any>()

  public componentDidMount() {
    document.addEventListener('click', this.handleClick)
  }

  public componentWillUnmount() {
    document.removeEventListener('click', this.handleClick)
  }

  public handleClick = e => {
    const inside = this.ref.current.contains(e.target)
    if (!inside && this.props.outsideClickClose) {
      this.props.closeModal()
    }
  }

  public render() {
    const {
      children,
      showHeader: hasHeader = false,
      hasButton = true,
      title,
      onSaveClick,
      saveButtonText,
      saveButtonDisabled,
      saveButtonColor,
      hasDelete: isHasDelete,
      hasSubmit = true,
      onDeleteClick,
      deleteButtonText,
      deleteButtonDisabled
    } = this.props

    return (
      <>
        <Backdrop />
        <Wrapper ref={this.ref}>
          <Dialog {...this.props}>
            <Content>
              {hasButton && (
                <Icon
                  extraStyles={{
                    cursor: 'pointer',
                    position: 'absolute',
                    right: 24,
                    top: 24
                  }}
                  color={colors.blackBg}
                  name={'close'}
                  size={24}
                  title={'Close'}
                  onClick={() => this.props.closeModal()}
                />
              )}
              {hasHeader && (
                <HeaderWrapper>
                  <Logo src={logoIcon} />
                  <Title>{title}</Title>
                </HeaderWrapper>
              )}
              {children}
              {hasButton && (
                <ButtonWrapper>
                  {isHasDelete && onDeleteClick && (
                    <Button
                      text={deleteButtonText || 'Delete'}
                      theme="danger"
                      disabled={deleteButtonDisabled}
                      onClick={onDeleteClick}
                    />
                  )}
                  {hasSubmit && (
                    <Button
                      onClick={onSaveClick}
                      text={saveButtonText || 'Submit'}
                      theme={saveButtonColor || 'secondary'}
                      disabled={saveButtonDisabled}
                    />
                  )}
                </ButtonWrapper>
              )}
            </Content>
          </Dialog>
        </Wrapper>
      </>
    )
  }
}
