const config = {
  host: process.env.CULABR_BACKEND
}
export default config


export interface IPresenter<T> {
  presenter: T
}

export interface INestedViewComponentProps {
  isNestedView?: boolean
}