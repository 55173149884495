import { TAuthUserRole } from 'app/Authentication/TAuthUserRole'
import { IViewConfigEntry } from 'app/Routing/IViewConfigEntry'

export const componentConfig: IViewConfigEntry[] = [
  {
    viewId: 'login',
    pathname: '/login',
    title: 'Login',
    allowedRoles: [TAuthUserRole.RECRUITER]
  },
  {
    viewId: 'resetPassword',
    pathname: '/reset-password',
    title: 'Reset Password',
    allowedRoles: [TAuthUserRole.RECRUITER]
  },
  {
    viewId: 'resetPasswordSuccess',
    pathname: '/reset-password-success',
    title: 'Reset Password Success',
    allowedRoles: [TAuthUserRole.RECRUITER]
  },
  {
    viewId: 'reset',
    pathname: '/reset',
    title: 'Reset',
    allowedRoles: [TAuthUserRole.RECRUITER]
  },
  {
    viewId: 'resetSuccess',
    pathname: '/reset-success',
    title: 'Reset Success',
    allowedRoles: [TAuthUserRole.RECRUITER]
  },
  {
    viewId: 'register',
    pathname: '/register',
    title: 'Register',
    allowedRoles: [TAuthUserRole.RECRUITER]
  },
  {
    viewId: 'registerSuccess',
    pathname: '/register-success',
    title: 'Register Success',
    allowedRoles: [TAuthUserRole.RECRUITER]
  },
  {
    viewId: 'registerVerification',
    pathname: '/register-verification',
    title: 'Register Verification',
    allowedRoles: [TAuthUserRole.RECRUITER]
  },
  {
    viewId: 'dashboard',
    pathname: '/dashboard',
    title: 'Dashboard',
    allowedRoles: [TAuthUserRole.RECRUITER, TAuthUserRole.COMPANY_ADMIN, TAuthUserRole.SUPER_ADMIN]
  },
  {
    viewId: 'adminDashboard',
    pathname: '/admin-dashboard',
    title: 'Admin Dashboard',
    allowedRoles: [TAuthUserRole.SUPER_ADMIN]
  },
  {
    viewId: 'manageCompanies',
    pathname: '/manage-compaines',
    title: 'Manage Companies',
    allowedRoles: [TAuthUserRole.COMPANY_ADMIN]
  }
]
