import { DashboardViews } from "app/Dashboard/DashboardPresenter";
import { HeaderPresenter } from "app/Header/HeaderPresenter";
import { INestedViewContext } from "app/Header/IModel";
import { inject, injectable } from "inversify";
import { action } from "mobx";

@injectable()
export class ManageCompaniesPresenter {
    @inject(HeaderPresenter)
    private headerPresenter: HeaderPresenter

    get activeView(): DashboardViews {
        return this.headerPresenter.mainView
    }

    get nestedViewContext(): INestedViewContext {
        return this.headerPresenter.nestViewContext
    }

    @action
    public switchToNestedView(context: INestedViewContext) {
        this.headerPresenter.setNestedView(context)
    }

}