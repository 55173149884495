import { DealPresenter } from 'app/Deal/DealPresenter'
import { Button } from 'app/Shared/Button/Button'
import { Modal } from 'app/Shared/Modal/Modal'
import { TextInput } from 'app/Shared/TextInput/TextInput'
import { withPresenter } from 'app/withPresenter'
import { observer } from 'mobx-react'
import * as React from 'react'
import Lottie from 'react-lottie'
import styled from 'styled-components'
import { colors } from 'styles/colors'
// @ts-ignore
import * as dealAnimation from 'assets/animations/offer.json'
// @ts-ignore
import * as placementAnimation from 'assets/animations/placement.json'

const Wrapper = styled.div`
  color: ${colors.darkText};
`

const ContentWrapper = styled.div`
  text-align: center;
  position: relative;

  button {
    margin: 24px auto;
  }
`

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled.h2`
  margin: -100px 0 20px;
  text-align: center;
`

const Paragraph = styled.p`
  width: 410px;
  padding: 0 20px;
  line-height: 1.3em;
`

const Highlight = styled.span`
  color: ${colors.lightText};
`

const InputWrapper = styled.div`
  display: flex;
  width: 200px;
  margin: auto;

  input {
    font-size: 1.7em;
  }
`

const Pound = styled.span`
  color: ${colors.lightText};
  margin: 12px 16px 0 0;
`

interface IPresenterProps extends IPassedProps {
  presenter: DealPresenter,
}

interface IPassedProps {
  candidateId: string,
  formattedCandidateName: string,
  jobIdentifier: string,
  droppedDialogType: string
}

const DealComponent = observer((props: IPresenterProps) => {

  const {
    candidateId,
    formattedCandidateName,
    jobIdentifier,
    droppedDialogType
  } = props

  const {
    close,
    submit,
    canSubmit,
    updatePlacementFee
  } = props.presenter

  props.presenter.candidateId = candidateId
  props.presenter.type = droppedDialogType

  return (
    <Modal hasButton={false} closeModal={close} outsideClickClose>
      <Wrapper>
        <HeaderWrapper>
          <Lottie
            options={{
              loop: false,
              autoplay: true,
              animationData: droppedDialogType === 'Add' ? dealAnimation.default : placementAnimation.default
            }}
            style={{ margin: '-50px auto 0px' }}
            height={450}
            width={450}
          />

          <Title>{droppedDialogType} the candidate placement fee</Title>
        </HeaderWrapper>

        <ContentWrapper>
          <Paragraph>
            Please confirm the placement fee for the candidate <br/>
            <Highlight>{formattedCandidateName}</Highlight> for <Highlight>{jobIdentifier}</Highlight>
          </Paragraph>

          <InputWrapper>
            <Pound>£</Pound>
            <TextInput input={updatePlacementFee}/>
          </InputWrapper>

          <Button
            text="Submit"
            disabled={!canSubmit}
            theme="secondary"
            onClick={() => {
              submit()
              window.document.getElementsByTagName('body')[ 0 ].click()
            }}
          />
        </ContentWrapper>

      </Wrapper>
    </Modal>
  )
})

export default withPresenter<IPassedProps>(DealPresenter)(DealComponent)
