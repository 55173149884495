import * as React from 'react'
import styled from 'styled-components'
import { withPresenter } from 'app/withPresenter'
import { observer } from 'mobx-react'
import { colors } from 'styles/colors'
import { Button } from 'app/Shared/Button/Button'
import { Modal } from 'app/Shared/Modal/Modal'
import * as logoIcon from 'assets/logoBlue.svg'
import { DeleteCandidatePresenter } from 'app/Candidates/DeleteCandidate/DeleteCandidatePresenter'
import { ICandidate } from 'app/Candidates/ICandidate'

const Wrapper = styled.div`
  color: ${colors.darkText};
  min-width: 400px;
  max-height: 600px;

  button {
    margin: 24px auto;
  }
`

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Logo = styled.img`
  margin: 24px auto;
  width: 90px;
`

const Title = styled.h2`
  margin: 20px 0;
  text-align: center;
`

const Paragraph = styled.p`
  text-align: center;
`

interface IPresenterProps extends IPassedProps {
  presenter: DeleteCandidatePresenter
}

interface IPassedProps {
  candidate: ICandidate
  isRejectOperation: boolean
}

const DeleteCandidateModal = observer((props: IPresenterProps) => {
  const { candidate, isRejectOperation } = props

  const { close, submit } = props.presenter

  const title = isRejectOperation ? 'Reject' : 'Delete'
  return (
    <Modal
      closeModal={close}
      onSaveClick={() => {
        submit(candidate.candidateId)
      }}
      showHeader
      title={`${title} candidate confirmation`}
    >
      <Wrapper>
        <Paragraph>
          Are you sure you want to {title.toLowerCase()} this Candidate?
        </Paragraph>
        {candidate !== null && (
          <Paragraph>
            {candidate.firstName + ' ' + candidate.lastName} -{' '}
            {candidate.jobName}
          </Paragraph>
        )}
      </Wrapper>
    </Modal>
  )
})

export default withPresenter<IPassedProps>(DeleteCandidatePresenter)(
  DeleteCandidateModal
)
