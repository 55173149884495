import * as React from 'react'
import styled from 'styled-components'
import { IFormattedStep } from 'app/Steps/StepsPanel/IFormattedStep'
import HTML5Backend from 'react-dnd-html5-backend'
import { DragDropContext, DropTarget } from 'react-dnd'
import { colors } from 'styles/colors'
import { observer } from 'mobx-react'
import { ACTION_WIDTH, STEP_WIDTH } from '../../../config/constants'
import CandidateDragLayer from '../../Candidates/CandidateDragLayer'
import DeleteCandidateModal from '../../Candidates/DeleteCandidate/DeleteCandidateModal'
import { withPresenter } from 'app/withPresenter'

import { StepsPresenter } from 'app/Steps/StepsPanel/StepsPresenter'
import Step from './Step'
import HeaderComponent from 'app/Header/HeaderComponent'
import { INestedViewContextProps } from 'app/Header/IModel'

const Wrapper = styled.div`
  min-height: 100%;
  box-sizing: border-box;
  grid-column: 1;
  grid-row: 1;
  display: flex;
  flex-direction: column;
  background-color: ${(props: { isOver: boolean }) =>
    props.isOver ? colors.aluminium : colors.aquaHaze};
  transition: 1s;
`

const Main = styled.div`
  overflow-y: auto;
  box-sizing: border-box;
  justify-items: center;
  display: flex;
  max-width: calc(100vw - ${ACTION_WIDTH}px);
  height: 100%;
  padding: 10px 10px;
`

const Content = styled.div`
  display: grid;
  width: 100%;
  max-width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
  align-items: start;
  justify-items: center;
  column-gap: 20px;
  ${(props: { rowCount: number; columnCount: number }) => {
    return `
      grid-template-columns: repeat(auto-fit, minmax(${STEP_WIDTH +
        40}px, auto));
      grid-template-rows: repeat(${props.rowCount}, ${STEP_WIDTH - 10}px);
    `
  }};
`

const spec = {
  drop: (props: { presenter: StepsPresenter }, monitor) => {
    const landedOnAStep: boolean = monitor.didDrop()
    if (!landedOnAStep) {
      const { candidateId, workflowId } = monitor.getItem()
      props.presenter.openDeleteDialog(candidateId, workflowId)
    }
  }
}

const collect = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver({ shallow: true })
})

interface IDropProps {
  connectDropTarget: (element: React.ReactNode) => React.ReactNode
  isOver: boolean
}

interface IProps extends IDropProps {
  presenter: StepsPresenter
}

@observer
class StepsComponent extends React.Component<IProps & INestedViewContextProps, {}> {
  constructor(props) {
    super(props)
    this.handleResize = this.handleResize.bind(this)
  }

  public handleResize() {
    const { innerWidth } = window

    this.props.presenter.stepsDelta = innerWidth >= 1520 ? 390 : 240
    this.props.presenter.containerWidth = innerWidth - ACTION_WIDTH
  }

  public componentDidMount() {
    this.handleResize()
    window.addEventListener('resize', this.handleResize)
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  public render() {
    const { presenter, connectDropTarget, isOver } = this.props
    const {
      formattedSteps,
      columnCount,
      rowCount,
      droppedDialogOpenStepId,
      showDeleteDialog,
      candidateToBeDeleted,
      candidateToBeAnimated
    } = presenter

    return (
      <Wrapper
        isOver={isOver}
        ref={instance => connectDropTarget(instance as any)}
      >
        <CandidateDragLayer />
        <HeaderComponent />
        {showDeleteDialog && (
          <DeleteCandidateModal
            candidate={candidateToBeDeleted}
            isRejectOperation={false}
          />
        )}
        <Main>
          <Content columnCount={columnCount} rowCount={rowCount}>
            {formattedSteps.map((step: IFormattedStep, index) => (
              // To avoid same key error here we adding key with item index.
              <Step
                {...step}
                key={`${index}_${step.stepId}`}
                openStepId={droppedDialogOpenStepId}
                candidateToBeAnimated={candidateToBeAnimated}
                isNestedView={this.props.isNestedView}
              />
            ))}
          </Content>
        </Main>
      </Wrapper>
    )
  }
}

export default withPresenter<INestedViewContextProps>(StepsPresenter)(
  DragDropContext(HTML5Backend)(
    DropTarget('candidate', spec, collect)(StepsComponent)
  )
)
