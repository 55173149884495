import { AxiosInstance } from 'axios'
import { injectable } from 'inversify'
import 'reflect-metadata'

@injectable()
export abstract class AbstractServiceGateway {

  public abstract accessToken: string
  public abstract nestedToken: string
  public abstract forceLogout: boolean
  public abstract axios: AxiosInstance

  public abstract post: <TResponseDto>(
    path: string,
    requestDto: {},
    headers?: {}
  ) => Promise<TResponseDto>

  public abstract get: <TResponseDto>(path: string, headers?: {}) => Promise<TResponseDto>

  public abstract delete: <TResponseDto>(path: string) => Promise<TResponseDto>

  public abstract download(method: string, path: string)
}
