import { AbstractServiceGateway } from './AbstractServiceGateway'
import { injectable } from 'inversify'
import 'reflect-metadata'

@injectable()
export class FakeServiceGateway implements AbstractServiceGateway {

  public accessToken: string

  public async post<TResponseDto>(
    path: string,
    requestDto: {}
  ): Promise<TResponseDto> {
    return Promise.resolve<TResponseDto>({} as any)
  }

  public async get<TResponseDto>(path: string): Promise<TResponseDto> {
    return Promise.resolve<TResponseDto>({} as any)
  }

  public async delete<TResponseDto>(path: string): Promise<TResponseDto> {
    return Promise.resolve<TResponseDto>({} as any)
  }
}
