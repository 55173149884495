import { observer } from 'mobx-react'
import React from 'react'
import { CaptionText, RecruiterWrapper } from '../styles'
import { Modal } from 'app/Shared/Modal/Modal'
import { withPresenter } from 'app/withPresenter'
import { DeleteRecruiterPresenter } from './DeleteRecruiterPresenter'

interface IProps {
  presenter: DeleteRecruiterPresenter
}

@observer
class DeleteRecruiter extends React.Component<IProps> {
  render(): React.ReactNode {
    const { isOpen } = this.props.presenter
    if (!isOpen) return null
    return (
      <Modal
        closeModal={() => this.props.presenter.toggle()}
        onSaveClick={() => this.props.presenter.deleteRecruiter()}
        showHeader
        title="Delete Recruiter"
        saveButtonText="Delete"
        saveButtonColor="danger"
      >
        <RecruiterWrapper>
          <CaptionText className={'text-center'}>
            Once this action is applied, it cannot be undone
          </CaptionText>
        </RecruiterWrapper>
      </Modal>
    )
  }
}

export default withPresenter(DeleteRecruiterPresenter)(DeleteRecruiter)
