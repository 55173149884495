import { withPresenter } from 'app/withPresenter'
import React from 'react'
import ManageCompaniesPageComponent from './CompanyList/CompanyListComponent'
import { ManageCompaniesPresenter } from './ManageCompaniesPresenter'
import styled from 'styled-components'
import HeaderComponent from 'app/Header/HeaderComponent'
import { observer } from 'mobx-react'
import RecruiterListComponent from 'app/ManageRecruiters/RecruiterList/RecruiterListComponent'
import CompanyStatesComponent from 'app/CompanyStats/CompanyStatesComponent'
import RightSidePanelWrapper from 'app/RightSidePanel/RightSidePanelWrapper'
import StepsComponent from 'app/Steps/StepsPanel/StepsComponent'
import { StepsAndJobsWrapper } from 'styles'
import { DashboardViews } from 'app/Routing/TAvailableView'

interface IProps {
  presenter: ManageCompaniesPresenter
}

const Wrapper = styled.div`
  height: 100%;
`

@observer
class ManageCompanies extends React.Component<IProps> {
  render(): React.ReactNode {
    const { nestedViewContext } = this.props.presenter
    return (
      <Wrapper>
        {!nestedViewContext && (
          <>
            <HeaderComponent />
            <ManageCompaniesPageComponent />
          </>
        )}

        {nestedViewContext &&
          nestedViewContext.view === DashboardViews.MANAGE_RECRUITERS && (
            <>
              <HeaderComponent />
              <RecruiterListComponent isNestedView />
            </>
          )}

        {nestedViewContext &&
          nestedViewContext.view === DashboardViews.MANAGER_DASHBOARD && (
            <>
              <HeaderComponent />
              <CompanyStatesComponent isNestedView />
            </>
          )}

        {nestedViewContext &&
          nestedViewContext.view === DashboardViews.MY_DASHBOARD && (
            <StepsAndJobsWrapper>
              <StepsComponent isNestedView />
              <RightSidePanelWrapper isNestedView />
            </StepsAndJobsWrapper>
          )}
      </Wrapper>
    )
  }
}

export default withPresenter(ManageCompaniesPresenter)(ManageCompanies)
