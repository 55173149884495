import { Icon } from 'app/Shared/Icon/Icon'
import { Table } from 'app/Shared/Table/table'
import { withPresenter } from 'app/withPresenter'
import * as React from 'react'
import styled, { css } from 'styled-components'
import { colors } from 'styles/colors'
import { ISelectedRecruiter } from '../IRecruiter'
import { RecruiterListPresenter } from './RecruiterListPresenter'
import { observer } from 'mobx-react'
import { EmptyElement } from 'styles'
import { IPaginationDTO } from 'gateways/service/stubs/dtos'
import { TAuthUserRole } from 'app/Authentication/TAuthUserRole'
import CurrencyUtil from 'app/CurrencyUtil'

interface IProps {
  presenter: RecruiterListPresenter
}

const Wrapper = styled.div`
  background: ${colors.dashboardBg};
  padding: ${({ theme }) => theme.spacing(1, 2, 2, 2)};
  flex: 1;
  height: 90%;
`

const ActionWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(3)};
  justify-content: center;
`

const TableRow = styled.tr`
  &:first-of-type {
    td {
      padding-top: 20px;
    }
  }
`

const TableCell = styled.td<any>`
  padding: 10px 24px 10px 0;
  font-size: ${({ theme }) => theme.fontSizes.sm};

  &:last-of-type {
    padding-right: 0;
  }

  ${props =>
    props &&
    [TAuthUserRole.SUPER_ADMIN, TAuthUserRole.COMPANY_ADMIN].includes(
      props.role
    ) &&
    css`
      font-weight: bold;
      color: black;
    `}
`

const Link = styled.a`
  text-decoration: underline;
  cursor: pointer;
`

const columns = [
  'First Name',
  'Last Name',
  'Email Address',
  'Annual Target',
  'Company',
  'Date Added'
]
const fields = [
  'firstName',
  'lastName',
  'email',
  'annualTarget',
  'company',
  'createDate'
]

interface TProps {
  isNestedView?: boolean
}

@observer
class RecruiterListComponent extends React.Component<IProps & TProps> {
  constructor(props: IProps) {
    super(props)
  }

  handleRecruiterEditIconClick(recruiter: ISelectedRecruiter) {
    this.props.presenter.handleRecruiterEditButton(recruiter)
  }

  handleRecruiterDeleteIconClick(recruiter: ISelectedRecruiter) {
    this.props.presenter.handleRecruiterDeleteButtonClick(recruiter)
  }

  handleRecruiterOnViewAsClick(recruiter: ISelectedRecruiter) {
    this.props.presenter.handleOnViewAsClick(recruiter)
  }

  reloadRecruiters(pagination: IPaginationDTO) {
    this.props.presenter.loadRecruiter(pagination)
  }

  public render() {
    if (!this.props.presenter.isInitialDataLoaded) return <EmptyElement />
    const {
      last,
      active: { start, end },
      next,
      previous,
      hasNext,
      first,
      totalRows
    } = this.props.presenter.pagination
    return (
      <Wrapper>
        {this.props.presenter.recruiter.length > 0 && (
          <Table
            columns={columns}
            fields={fields}
            actionColWidth={'140px'}
            hasActionCol={true}
            start={start}
            end={end}
            totalRow={totalRows}
            onNextPage={() => this.reloadRecruiters(next)}
            onPrevPage={() => this.reloadRecruiters(previous)}
            onFirstPage={() => this.reloadRecruiters(first)}
            onLastPage={() => this.reloadRecruiters(last)}
            hasNextPage={hasNext}
          >
            {this.props.presenter.recruiter.map((item, index) => (
              <TableRow key={index}>
                <TableCell role={item.role}>{item.firstName}</TableCell>
                <TableCell role={item.role}>{item.lastName}</TableCell>
                <TableCell>{item.email}</TableCell>
                <TableCell>{CurrencyUtil.format(item.annualTarget)}</TableCell>
                <TableCell>
                  <Link>{item.companyName}</Link>
                </TableCell>
                <TableCell>{item.createDate}</TableCell>
                <TableCell>
                  <ActionWrapper>
                    <Icon
                      extraStyles={{ cursor: 'pointer' }}
                      color={colors.darkText}
                      name={'edit'}
                      title={'Edit'}
                      onClick={() => this.handleRecruiterEditIconClick(item)}
                    />
                    <Icon
                      extraStyles={{ cursor: 'pointer' }}
                      color={colors.darkText}
                      name={'eye'}
                      title={'View as User'}
                      onClick={() => {
                        this.handleRecruiterOnViewAsClick(item)
                      }}
                    />
                    <Icon
                      extraStyles={{ cursor: 'pointer' }}
                      color={colors.darkText}
                      name={'bin'}
                      title={'Delete'}
                      onClick={() => this.handleRecruiterDeleteIconClick(item)}
                    />
                  </ActionWrapper>
                </TableCell>
              </TableRow>
            ))}
          </Table>
        )}
      </Wrapper>
    )
  }
}

export default withPresenter<TProps>(RecruiterListPresenter)(
  RecruiterListComponent
)
