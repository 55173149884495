import { inject, injectable } from 'inversify'
import 'reflect-metadata'
import { observable, action, computed } from 'mobx'
import {
  validated,
  AbstractClassValidator,
  MAX_LENGTH,
  MUST_BE_EMAIL,
  MIN_LENGTH,
  MUST_BE_NUMBER
} from '@logicroom/validator'
import { ITextInput } from 'app/Shared/TextInput/ITextInput'
import { AuthenticationRepository } from '../AuthenticationRepository'
import {RoutingRepository} from "app/Routing/RoutingRepository";

@injectable()
export class RegisterPresenter extends AbstractClassValidator {
  @inject(RoutingRepository)
  private routingRepository: RoutingRepository

  @inject(AuthenticationRepository)
  private authenticationRepository: AuthenticationRepository

  public roles: any = {
    user: '1'
  }

  @observable
  public serverErrors = []

  @observable
  public isAgencySelected: boolean = false

  @validated({
    mandatory: true,
    rules: [
      {
        rule: MAX_LENGTH(20),
        errorMessage: 'First name too long, up to 20 characters allowed.'
      },
      {
        rule: MIN_LENGTH(2),
        errorMessage: 'First name must be at least 3 characters long.'
      }
    ]
  })
  public firstName: ITextInput = { label: 'First Name*', autoComplete: 'user-firstName' }

  @validated({
    mandatory: true,
    rules: [
      {
        rule: MAX_LENGTH(20),
        errorMessage: 'Last name too long, up to 20 characters allowed.'
      },
      {
        rule: MIN_LENGTH(2),
        errorMessage: 'Last name must be at least 3 characters long.'
      }
    ]
  })
  public lastName: ITextInput = { label: 'Last Name*', autoComplete: 'user-lastName' }

  @validated({
    mandatory: true,
    rules: [
      {
        rule: MUST_BE_EMAIL,
        errorMessage: 'Your entry does not look like an email.'
      }
    ]
  })
  public email: ITextInput = { type: 'email', label: 'Email*', autoComplete: 'username-email' }

  @validated({
    mandatory: false,
    rules: [
      {
        rule: MAX_LENGTH(15),
        errorMessage: 'Number too long, up to 15 digits allowed.'
      },
      {
        rule: MIN_LENGTH(10),
        errorMessage: 'Number must be at least 11 digits long.'
      },
      {
        rule: MUST_BE_NUMBER,
        errorMessage: 'Only numbers accepted.'
      }
    ]
  })
  public phoneNumber: ITextInput = { label: 'Phone Number', autoComplete: 'user-phone' }

  @validated({
    mandatory: true,
    rules: [
      {
        rule: MAX_LENGTH(25),
        errorMessage: 'Password too long, up to 25 characters allowed.'
      },
      {
        rule: MIN_LENGTH(5),
        errorMessage: 'Password must be at least 5 characters long.'
      }
    ]
  })
  public password: ITextInput = { type: 'password', label: 'Create Password*', autoComplete: 'current-password' }

  @validated({
    mandatory: true,
    rules: [
      {
        rule: MAX_LENGTH(25),
        errorMessage: 'Password too long, up to 25 characters allowed.'
      },
      {
        rule: MIN_LENGTH(5),
        errorMessage: 'Password must be at least 5 characters long.'
      }
    ]
  })
  public passwordConfirm: ITextInput = { type: 'password', label: 'Confirm Password*', autoComplete: 'current-password' }

  @validated({
    mandatory: false,
    rules: [
      {
        rule: MAX_LENGTH(20),
        errorMessage: 'First name too long, up to 20 characters allowed.'
      },
      {
        rule: MIN_LENGTH(2),
        errorMessage: 'First name must be at least 3 characters long.'
      }
    ]
  })
  public managerFirstName: ITextInput = { label: 'Manager First Name' }

  @validated({
    mandatory: false,
    rules: [
      {
        rule: MAX_LENGTH(20),
        errorMessage: 'Last name too long, up to 20 characters allowed.'
      },
      {
        rule: MIN_LENGTH(2),
        errorMessage: 'Last name must be at least 3 characters long.'
      }
    ]
  })
  public managerLastName: ITextInput = { label: 'Manager Last Name' }

  @validated({
    mandatory: false,
    rules: [
      {
        rule: MUST_BE_EMAIL,
        errorMessage: 'Your entry does not look like an email.'
      }
    ]
  })
  public managerEmail: ITextInput = { type: 'email', label: 'Manager Email' }

  @computed
  public get buttonIsDisabled(): boolean {
    return !this.formIsValid
  }

  @action
  public isAgencyChecked = (value: boolean): void => {
    this.isAgencySelected = value
  }

  @action
  public submit = async (): Promise<void> => {
    if (this.password.value !== this.passwordConfirm.value) {
      this.serverErrors = ['Passwords must be the same.']
      return
    }

    if (
      this.managerEmail.value !== '' &&
      this.managerEmail.value === this.email.value
    ) {
      this.serverErrors = ['You email must be different from your manager\'s email.']
      return
    }

    if (
      this.managerEmail.value !== '' &&
      this.getEmailDomain(this.managerEmail.value) !== this.getEmailDomain(this.email.value)
    ) {
      this.serverErrors = ['You email domain must be the same of the manager\'s email domain.']
      return
    }

    try {
      await this.authenticationRepository.registerNewUser(
        this.firstName.value,
        this.lastName.value,
        this.email.value,
        this.password.value,
        this.passwordConfirm.value,
        this.phoneNumber.value,
        this.managerFirstName.value,
        this.managerLastName.value,
        this.roles.user,
        this.managerEmail.value
      )
      this.firstName.value = ''
      this.lastName.value = ''
      this.email.value = ''
      this.password.value = ''
      this.passwordConfirm.value = ''
      this.phoneNumber.value = ''
      this.managerFirstName.value = ''
      this.managerLastName.value = ''
      this.managerEmail.value = ''
    } catch (ex) {
      this.serverErrors = [ex.message]
    }
  }

  public backToLogin = {
    onClick: () => {
      this.routingRepository.goForward('login')
    }
  }

  private getEmailDomain(value: string) {
    return value.split('@')[1]
  }
}
