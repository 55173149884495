import React from 'react'
import { CompanyOptionsPresenter } from './CompanyOptionsPresenter'
import { withPresenter } from 'app/withPresenter'
import { Label, RadioButtonWrapper } from 'styles'
import { RadioButton } from 'app/Shared/CheckBoxInput/RadioButton'
import { TextInput } from 'app/Shared/TextInput/TextInput'
import { Dropdown } from 'app/Shared/Dropdown/Dropdown'
import { observer } from 'mobx-react'

interface IProps {
  presenter: CompanyOptionsPresenter
}

@observer
class CompanyOptions extends React.Component<IProps> {
  componentDidMount(): void {
    this.props.presenter.loadInitialData()
  }
  render(): React.ReactNode {
    const { activeCompanyType, newCompanyName } = this.props.presenter
    return (
      <>
        <Label>Company settings</Label>
        <RadioButtonWrapper>
          <RadioButton
            isChecked={activeCompanyType == 'existing'}
            label="Choose Existing Company"
            onClick={() => this.props.presenter.updateCompanyType('existing')}
          />
          <RadioButton
            isChecked={activeCompanyType == 'create'}
            label="Create New Company"
            onClick={() => this.props.presenter.updateCompanyType('create')}
          />
        </RadioButtonWrapper>
        {this.props.presenter.activeCompanyType == 'existing' && (
          <>
            <Dropdown
              options={this.props.presenter.companies}
              onSelect={item =>
                this.props.presenter.updateCompanySelection(item)
              }
            />
          </>
        )}
        {this.props.presenter.activeCompanyType == 'create' && (
          <TextInput
            extraStyles={'width: 100%'}
            input={{
              label: 'Company name',
              value: newCompanyName,
              onChange: (value: string) =>
                this.props.presenter.updateCompanyName(value)
            }}
            showAsterisk
            theme="secondary"
          />
        )}
      </>
    )
  }
}

export default withPresenter(CompanyOptionsPresenter)(CompanyOptions)
