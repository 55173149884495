import { observable, action, computed } from 'mobx'
import { RoutingRepository } from 'app/Routing/RoutingRepository'
import { ResetRequestPageRepository } from './ResetRequestPageRepository'
import { TAvailableView } from 'app/Routing/TAvailableView'
import {
  MUST_BE_EMAIL,
  validated,
  AbstractClassValidator
} from '@logicroom/validator'
import { ITextInput } from 'app/Shared/TextInput/ITextInput'
import { injectable, inject } from 'inversify'
import 'reflect-metadata'

@injectable()
export class ResetRequestPagePresenter extends AbstractClassValidator {

  @inject(ResetRequestPageRepository)
  private resetRequestPageRepository: ResetRequestPageRepository

  @inject(RoutingRepository)
  private routingRepository: RoutingRepository

  @observable
  public serverErrors = []

  @validated({
    mandatory: true,
    rules: [
      {
        rule: MUST_BE_EMAIL,
        errorMessage: 'Your entry does not look like an email.'
      }
    ]
  })
  public email: ITextInput = { type: 'email', label: 'Email*', autoComplete: 'username-email' }

  @computed
  public get buttonIsDisabled(): boolean {
    return !this.formIsValid
  }

  @action
  public submit = async (): Promise<void> => {
    try {
      await this.resetRequestPageRepository.requestReset(this.email.value)
      this.email.value = ''
    } catch (ex) {
      this.serverErrors = [ex.message]
    }
  }

  @action
  public linkClick = (viewId: TAvailableView): void => {
    this.routingRepository.goForward(viewId)
  }
}

