import { AbstractClassValidator } from "@logicroom/validator";
import { AuthenticationRepository } from "app/Authentication/AuthenticationRepository";
import { LoaderPresenter } from "app/Shared/Loader/LoaderPresenter";
import { inject, injectable, postConstruct } from "inversify";
import { action, observable, reaction } from "mobx";
import { toast } from "react-toastify";
import { ManageRecruiterRepository } from "../ManageRecruiterRepository";
import { DropdownOption } from "app/Shared/Dropdown/Dropdown";
import { IRecruiterEditCompanyRequestDTO } from "gateways/service/stubs/dtos";

@injectable()
export class RecruiterEditCompanyPresenter extends AbstractClassValidator {
    serverErrors?: string[]

    @inject(LoaderPresenter)
    private loaderPresenter: LoaderPresenter

    @inject(AuthenticationRepository)
    private authenticationRepository: AuthenticationRepository

    @inject(ManageRecruiterRepository)
    private manageRecruiterRepository: ManageRecruiterRepository

    @observable
    public isOpen: boolean = false

    @observable
    public dropdownOptions: DropdownOption[] = []

    @observable
    public selectedUser: any

    @observable
    public updatedCompanyName: string

    get canSubmit() {
        return this.updatedCompanyName
    }

    @postConstruct()
    public init() {
        reaction(
            () => this.manageRecruiterRepository.recruiterList,
            (list) => {
                this.dropdownOptions = list.map(recruiter => {
                    return {
                        label: `${recruiter.firstName} ${recruiter.lastName}`,
                        value: recruiter
                    }
                })
                this.updateCompanyName(this.authenticationRepository.user.company)
            }
        )
    }

    @action
    public toggle() {
        this.isOpen = !this.isOpen
    }

    @action
    public updateCompanyName(name: string) {
        this.updatedCompanyName = name
    }

    public handleOnDropdownUserSelection(selection: any) {
        this.selectedUser = selection.value
    }

    @action
    public async handleOnSaveClick() {
        this.loaderPresenter.showLoader()
        try {
            const payload: IRecruiterEditCompanyRequestDTO = {
                companyName: this.updatedCompanyName
            }
            if (this.selectedUser) payload.adminId = this.selectedUser.id
            const result = await this.manageRecruiterRepository.editCompany(payload);
            this.loaderPresenter.hideLoader()
            if (!result.success) return toast.error(result.message)
            this.toggle()
            if (this.selectedUser) return this.authenticationRepository.logout()
            this.manageRecruiterRepository.loadRecruiter()
        } catch (err) {
            toast.error(err.message)
            this.loaderPresenter.hideLoader()
        }
    }
}
