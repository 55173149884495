import { injectable } from 'inversify'
import 'reflect-metadata'

@injectable()
export abstract class AbstractWindowGateway {
  public abstract pathname: string

  public abstract queryParamsUrlString: string

  public getQueryParamForKey: (queryParamName: string) => string

  public goForward: (path: string) => void

  public goBack: () => void

  public replacePath: (path: string) => void
}
