import * as React from 'react'
import styled from 'styled-components'
import {colors} from "styles/colors";
import {ILeaderBoardDto, ILeaderBoardOrder} from "app/Actions/LeaderBoardList/ILeaderBoardDto";
import * as coinCountIcon from 'assets/coinCountShadow.png'
import * as moneyIcon from 'assets/moneyShadow.png'
import * as cvCountIcon from 'assets/cvCountShadow.png'
import * as badgeCash from 'assets/badgeCashColor.svg'
import * as badgeCV from 'assets/badgeCVColor.svg'
import * as badgeCulabrCoins from 'assets/badgeCulabrCoinsColor.svg'
import Avatar from 'app/Dashboard/UserAvatar'

const Wrapper = styled.div`
  border: 1px solid ${colors.border};
  margin: 0 20px 10px 0;
  display: flex;
  height: 65px;
  border-radius: 5px;
  color: ${colors.darkText};
`

const Details = styled.div`
  flex: 1;
  display: flex;
  position: relative;
`

const BadgesWrapper = styled.div`
  flex: 1;
  padding: 16px 0 0;
`
const Name = styled.div`
  color: ${colors.darkText};
  font-size: 0.8em;
`

const Badge = styled.img`
  width: 17.5px;
  height: 17.5px;
  margin-right: 5px;
`

const Index = styled.div`
  flex: 1;
  max-width: 30px;
  padding 24px 6px;
  text-align: center;
  border-right: 1px solid ${colors.border};
`

const DataWrapper = styled.div`
  flex: 1;
  flex-direction: column;
  text-align: right;
  padding: 16px 10px 0;
  max-width: 55px;
`

const DataIcon = styled.img`
  flex: 1;
  height: 18px;
`

const Data = styled.div`
  flex: 1;
  font-size: 0.7em;
  margin-bottom: 6px;
`

const BarWrapper = styled.div`
  position: absolute;
  display: flex;
  height: 3px;
  width: 100%;
  bottom: 0;
  left: 0;
  background: ${colors.border};
  border-radius: 0 0 5px 0;
`

const ProgressBar = styled.div`
  height: 100%;
  background: ${colors.mainBg};
  border-radius: 0 0 5px 0;
`

interface IProps {
  data: ILeaderBoardDto
  filter: ILeaderBoardOrder
}
export const LeaderBoardTile = (props: IProps) => {
  const {
    index,
    agentName,
    cumulativeDealFee,
    cvSubmits,
    coins,
    leadingCandidateProgress,
    badges,
    level
  } = props.data

  const {
    type
  } = props.filter

  const delimitedCoinCount = coins.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  const delimitedCvSubmitted = cvSubmits.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  const delimitedDealAgreed = cumulativeDealFee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  return (
    <Wrapper>
      <Index>{index}</Index>
      <Details>
        <Avatar {...badges} level={level} />
        <BadgesWrapper>
          <Name>{agentName}</Name>
          {badges.cumulativeDealFee && <Badge src={badgeCash} />}
          {badges.cvSubmits && <Badge src={badgeCV} />}
          {badges.coins && <Badge src={badgeCulabrCoins} />}
        </BadgesWrapper>
        <DataWrapper>
          <Data>{
            type === 'coins'
              ? delimitedCoinCount
              : type === 'cvSubmits'
                ? delimitedCvSubmitted + " CVs"
                : type === 'cumulativeDealFee'
                  ? "£ " + delimitedDealAgreed
                  : 0
          }</Data>
          <DataIcon src={
            type === 'coins'
              ? coinCountIcon
              : type === 'cvSubmits'
              ? cvCountIcon
              : type === 'cumulativeDealFee'
                ? moneyIcon
                : 0
          } />
        </DataWrapper>
        <BarWrapper>
          <ProgressBar style={{ flex: (leadingCandidateProgress / 100) }} />
        </BarWrapper>
      </Details>
    </Wrapper>
  )
}
