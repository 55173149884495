import * as React from 'react'
import styled, { css } from 'styled-components'
import { colors } from 'styles/colors'
import { IIconProps } from '../Icon/Icon'

interface IWrapperProps {
  extraStyles: string
  disabled: boolean
  theme: string
  animate?: boolean
}

const Wrapper = styled.button<IProps>`
  background-color: transparent;
  border: none;
  border-radius: 6px;
  /* width: 100%; */
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  padding: 0;
  margin: 10px;
  outline: none;
  animation: none;
  cursor: ${(props: IWrapperProps) =>
    props.disabled ? 'not-allowed' : 'pointer'};
  opacity: ${(props: IWrapperProps) => (props.disabled ? '0.5' : 'unset')};
  
  ${(props: IWrapperProps) =>
    props.animate === true &&
    css`
      span {
        animation: pulse 3s infinite;
        &:hover {
          animation: none;
        }
      }
    `}

  ${(props: IWrapperProps) =>
    props.theme === 'secondary' &&
    css`
      background-color: ${colors.mainBg};
      width: 200px;
      height: 40px;
      align-self: center;
    `}
  
  ${(props: IWrapperProps) =>
    props.theme === 'onlyBorder' &&
    css`
      border: 1px solid ${colors.mainBg};
      width: 200px;
      height: 40px;
      align-self: center;
    `}

  ${(props: IWrapperProps) =>
    props.theme === 'danger' &&
    css`
      border: 1px solid red !important;
      background-color: #de462b !important;
      width: 200px;
      height: 40px;
      align-self: center;
    `}
  
  ${(props: ITextProps) =>
    props.theme === 'iconButton' &&
    css`
      width: auto;
    `}

  ${(props: IWrapperProps) => props.extraStyles};
`

interface ITextProps {
  theme: string
  size?: number
}

const Text = styled.span<IProps>`
  background-color: ${colors.buttonBg};
  flex-grow: 2;
  box-sizing: border-box;
  white-space: nowrap;
  color: ${colors.white};
  padding-inline: 25px;
  font-size: 12px;
  height: 40px;
  border-radius: 6px;
  line-height: 41px;
  display: inline-block;
  box-sizing: border-box;
  vertical-align: bottom;
  
  &:hover {
    background-color: ${colors.buttonDarkBg};
  }
  
  ${(props: ITextProps) =>
    props.theme === 'secondary' &&
    css`
      background-color: ${colors.buttonBg};
      color: ${colors.white};
      font-size: 1em;

      &:hover {
        background-color: ${colors.buttonDarkBg};
      }
    `}
  
  ${(props: ITextProps) =>
    props.theme === 'onlyBorder' &&
    css`
      background-color: transparent;
      border: 1px solid ${colors.lightText};
      color: ${colors.lightText};
      font-size: 1em;

      &:hover {
        background-color: transparent;
        border: 1px solid ${colors.darkText};
        color: ${colors.darkText};
      }
    `}

  ${(props: ITextProps) =>
    props.theme === 'danger' &&
    css`
      background-color: ${colors.errorText};
      color: ${colors.white};
      font-size: 1em;

      &:hover {
        background-color: ${colors.errorText};
      }
    `}

  ${(props: ITextProps) =>
    props.size === 0 &&
    css`
      height: 40px;
      line-height: 41px;
    `}

  ${(props: ITextProps) =>
    props.theme === 'iconButton' &&
    css`
      background-color: transparent;
      height: ${props.size}px;
      padding: 0;
      line-height: initial;

      &:hover {
        background-color: transparent;
      }
    `}
`

interface IProps {
  className?: string
  theme?: string
  text?: string | React.ReactNode
  icon?: IIconProps
  name?: string
  size?: number
  color?: string
  onClick?: () => void
  disabled?: boolean
  extraStyles?: string
  animate?: boolean
  hidden?: boolean
}

export const Button: React.FunctionComponent<IProps> = props => {
  const {
    className,
    theme,
    text,
    size,
    extraStyles,
    onClick = () => {},
    disabled = false,
    animate,
    hidden = false
  } = props

  return (
    <Wrapper
      className={className}
      theme={theme}
      extraStyles={extraStyles}
      onClick={onClick}
      disabled={disabled}
      animate={animate}
      hidden={hidden}
    >
      <Text theme={theme} size={size}>
        {text}
      </Text>
    </Wrapper>
  )
}
