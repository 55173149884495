import { inject, injectable } from 'inversify'
import { Error } from 'tslint/lib/error'
import { AbstractServiceGateway } from 'gateways/service/AbstractServiceGateway'
import 'reflect-metadata'
import { StatusRepository } from 'app/StatusBar/StatusRepository'

export interface IRequestCreateDealDto {
  candidateId: string
  placementFee: string
  type: string
}

export interface IResponseCreateDealDto {
  success: boolean
  message: number
}

@injectable()
export class DealRepository {

  @inject(AbstractServiceGateway)
  private serviceGateway: AbstractServiceGateway

  @inject(StatusRepository)
  private coinsRepository: StatusRepository

  public async createDeal(candidateId: string, placementFee: string, type: string) {
    const requestResetDto: IRequestCreateDealDto = { candidateId, placementFee, type : type.toLowerCase() }
    const responseDto = await this.serviceGateway.post<IResponseCreateDealDto>('/create-deal', requestResetDto)
    if (responseDto.success === false) throw new Error('Could not create deal')
    await this.coinsRepository.updateCoinCount()
  }

}

