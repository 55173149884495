import { injectable } from 'inversify'
import { action, observable } from 'mobx'
import { IFullScreenAnimation } from './IFullScreenAnimation'

@injectable()
export class FullScreenAnimationPresenter {
  @observable
  public loop: boolean = false

  @observable
  public autoplay: boolean = false

  @observable.ref
  public animationData: any

  @action
  private resetAnimation() {
    this.autoplay = false
    this.animationData = null
  }

  @action
  public playAnimation(data: IFullScreenAnimation) {
    this.autoplay = true
    this.animationData = data.animationData

    setTimeout(() => {
      this.resetAnimation()
    }, data.timeout)
  }
}
