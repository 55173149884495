import { StatusRepository } from 'app/StatusBar/StatusRepository'
import { AbstractServiceGateway } from 'gateways/service/AbstractServiceGateway'
import { IActionsResponseDto } from 'gateways/service/stubs/dtos'
import { inject, injectable } from 'inversify'
import { action } from 'mobx'

@injectable()
export class ActionRepository {
  @inject(AbstractServiceGateway)
  private serviceGateway: AbstractServiceGateway

  @inject(StatusRepository)
  private coinsRepository: StatusRepository

  @action
  public async fetchActions(workflowId: string, candidateId: string) {
    const response = await this.serviceGateway
      .get<IActionsResponseDto>(`/actions?workflowId=${workflowId}&candidateId=${candidateId}`)
    return response.result;
  }

  @action
  public async fetchAllActions(workflowId: string) {
    const response = await this.serviceGateway
      .get<IActionsResponseDto>(`/actions?workflowId=${workflowId}`)
    return response.result;
  }

  @action
  public async toggleCompleted(actionId: string, candidateId: string, completed: boolean) {
    const result = await this.serviceGateway.post<any>('/action-complete', {
      completed,
      candidateId,
      actionId
    });

    await this.coinsRepository.updateCoinCount()
    return result
  }
}
