import { inject, injectable } from 'inversify'
import { action, computed, observable } from 'mobx'
import { CompanyOptionsRepository } from './CompanyOptionsRepository'
import { ICompany } from '../ICompany'
import { DropdownOption } from 'app/Shared/Dropdown/Dropdown'


type TCreateCompany = "create" | "existing"

@injectable()
export class CompanyOptionsPresenter {

    @inject(CompanyOptionsRepository)
    private companyOptionsRepository: CompanyOptionsRepository

    @observable
    public activeCompanyType: TCreateCompany = 'existing'

    @observable
    public selectedCompany: ICompany

    public newCompanyName: string

    @action
    public updateCompanyType(type: TCreateCompany) {
        this.activeCompanyType = type
    }

    @computed
    get companies(): DropdownOption<ICompany>[] {
        return this.companyOptionsRepository.companyList.map(i => {
            return {
                label: i.name,
                value: i
            }
        })
    }

    @action
    public updateCompanySelection(item: DropdownOption<ICompany>) {
        this.selectedCompany = item.value
    }

    @action
    public updateCompanyName(value: string) {
        this.newCompanyName = value
    }

    @action
    public reset() {
        this.selectedCompany = undefined
        this.newCompanyName = undefined
    }

    public toJSON() {
        return {
            companyId: this.selectedCompany ? this.selectedCompany.id : undefined,
            companyName: this.newCompanyName
        }
    }

    public async loadInitialData() {
        await this.companyOptionsRepository.loadCompany()
    }
}
