import * as React from 'react'
import { observer } from 'mobx-react'
import { RegisterPresenter } from './RegisterPresenter'
import styled from 'styled-components'
import { TextInput } from 'app/Shared/TextInput/TextInput'
import { CheckboxInput } from 'app/Shared/CheckBoxInput/CheckBoxInput'
import { Button } from 'app/Shared/Button/Button'
import { withPresenter } from 'app/withPresenter'
import { AuthenticationPageWrapper } from '../AuthenticationPageWrapper/AuthenticationPageWrapper'
import {colors} from "styles/colors";
import {Link} from "app/Shared/Link/Link";

const Form = styled.form`
  display: flex;
  align-items: center;
  flex-direction: column;
  
  a {
    color: ${colors.lightText};
    font-size: 1.1em;
  }
  
  button {
    margin-bottom: 34px;
  }

  .hide {
    display: none;
  }
`

const Inline = styled.div`
  display: flex;
  flex: 1;
`

const Error = styled.p`
  align-self: center;
  font-weight: normal;
  color: ${colors.errorText};
  margin: 0;
  padding: 4px 0;
`

const FormSubHeader = styled.h5`
  display: flex;
  justify-content: center;
  font-weight: normal;
  font-size: 1em;
`

const CheckboxWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`

const Title = styled.h1`
  margin: 0 0 30px;
`

const ManagerFormWrapper = styled.div`
  margin-bottom: 20px;
`

const nameInputStyles = {
  width: '185px'
}

interface IProps {
  presenter: RegisterPresenter
}

const RegisterComponent = observer((props: IProps) => {
  const {
    firstName,
    lastName,
    email,
    phoneNumber,
    password,
    passwordConfirm,
    managerFirstName,
    managerLastName,
    managerEmail,
    isAgencyChecked,
    isAgencySelected,
    buttonIsDisabled,
    submit,
    errors,
    backToLogin
  } = props.presenter
  return (
    <AuthenticationPageWrapper>
      <Title>Create your account</Title>

      {errors.map((message, i) => (
        <Error key={i + message}>{message}</Error>
      ))}

      <Form onSubmit={e => e.preventDefault()}>
        <Inline>
          <TextInput input={firstName}
                     theme="secondary"
                     extraStyles={nameInputStyles}/>
          <TextInput input={lastName}
                     theme="secondary"
                     extraStyles={nameInputStyles}/>
        </Inline>
        <Inline>
          <TextInput input={email}
                     theme="secondary"
                     extraStyles={nameInputStyles}/>
          <TextInput input={phoneNumber}
                     theme="secondary"
                     extraStyles={nameInputStyles}/>
        </Inline>
        <Inline>
          <TextInput input={password}
                     theme="secondary"
                     extraStyles={nameInputStyles}/>
          <TextInput input={passwordConfirm}
                     theme="secondary"
                     extraStyles={nameInputStyles}/>
        </Inline>

        <CheckboxWrapper className="hide">
          <FormSubHeader>Registering from an Agency?</FormSubHeader>
          <CheckboxInput onChange={isAgencyChecked} />
        </CheckboxWrapper>

        {isAgencySelected && (
          <ManagerFormWrapper className='hide'>

            <Inline>
              <TextInput input={managerFirstName}
                         theme={'secondary'}
                         extraStyles={nameInputStyles}/>
              <TextInput input={managerLastName}
                         theme={'secondary'}
                         extraStyles={nameInputStyles}/>
            </Inline>
            <TextInput input={managerEmail} theme={'secondary'} />
          </ManagerFormWrapper>
        )}
        <Button
          theme="secondary"
          text="Create account"
          disabled={buttonIsDisabled}
          onClick={submit}
        />
        <Link preventDefault={true} {...backToLogin} to="/login">
          Back to sign in
        </Link>
      </Form>
    </AuthenticationPageWrapper>
  )
})

export default withPresenter(RegisterPresenter)(RegisterComponent)
