import {inject, injectable} from 'inversify'
import 'reflect-metadata'
import {RoutingRepository} from "app/Routing/RoutingRepository"
import {action} from "mobx"

@injectable()
export class ResetRequestSuccessPresenter {
  @inject(RoutingRepository)
  private routingRepository: RoutingRepository

  @action
  public submit = (): void  => {
    this.routingRepository.goForward('login')
  }
}
