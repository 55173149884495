import { BadgeHistoryDialog } from 'app/Dashboard/HistoryDialog/BadgeHistoryDialog'
import { IBadgesHistory } from 'app/StatusBar/IStatusDto'
import * as baseAvatar from 'assets/baseAvatar.svg'
import * as bronzeAvatar from 'assets/bronzeAvatar.svg'
import * as goldAvatar from 'assets/goldAvatar.svg'
import * as silverAvatar from 'assets/silverAvatar.svg'
import React, { Component } from 'react'
import styled from 'styled-components'

const MainWrapper = styled.div`
  height: 100%;
  align-items: center;
  justify-content: center;
  display: grid;
  position: relative;
`

const Avatar = styled.img`
  width: 45px !important;
  height: 45px !important;
  border-radius: 60%;
`

const AvatarWrapper = styled.div`
  img {
    width: 18px;
  }

  img:nth-child(2) {
    position: absolute;
    left: 2%;
    bottom: 30%;
  }

  img:nth-child(3) {
    position: absolute;
    bottom: 15%;
    left: 35%;
  }

  img:nth-child(4) {
    position: absolute;
    bottom: 30%;
    right: 1%;
  }
`

const Badge = styled.img``

type AvatarProps = {
  coins: boolean
  cumulativeDealFee: boolean
  cvSubmits: boolean
  showBadgesHistory?: boolean
  showBadges?: boolean
  level: string
  fetchBadgesHistory?: () => Promise<IBadgesHistory[]>
}

type AvatarState = {
  isBadgeHistoryDialogOpen: boolean
  isBadgeHistoryLoading: boolean
  badges: IBadgesHistory[]
}

export default class UserAvatar extends Component<AvatarProps, AvatarState> {
  constructor(props) {
    super(props)
    this.state = {
      isBadgeHistoryDialogOpen: false,
      isBadgeHistoryLoading: false,
      badges: []
    }
  }

  private onAvatarClick(e) {
    if (!this.props.showBadgesHistory) return
    this.setState({
      isBadgeHistoryLoading: true,
      isBadgeHistoryDialogOpen: true
    })

    this.props.fetchBadgesHistory().then(badges => {
      this.setState({
        isBadgeHistoryLoading: false,
        badges: badges
      })
    })
  }

  private onBadgeHistoryDialogClose() {
    this.setState({
      isBadgeHistoryLoading: false,
      isBadgeHistoryDialogOpen: false
    })
  }

  render() {
    const avatarCursor = this.props.showBadgesHistory ? 'pointer' : 'initial'
    let userProfile = baseAvatar
    if (this.props.level === 'gold') userProfile = goldAvatar
    if (this.props.level === 'silver') userProfile = silverAvatar
    if (this.props.level === 'bronze') userProfile = bronzeAvatar

    return (
      <MainWrapper
        style={{ cursor: avatarCursor }}
        onClick={e => this.onAvatarClick(e)}
      >
        {this.state.isBadgeHistoryDialogOpen && (
          <BadgeHistoryDialog
            loading={this.state.isBadgeHistoryLoading}
            records={this.state.badges}
            onClose={() => this.onBadgeHistoryDialogClose()}
          />
        )}
        <AvatarWrapper>
          <Avatar src={userProfile} />
          {/* {this.props.showBadges && (
            <>
              {this.props.cumulativeDealFee && <Badge src={badgeCash} />}
              {this.props.cvSubmits && <Badge src={badgeCV} />}
              {this.props.coins && <Badge src={badgeCulabrCoins} />}
            </>
          )} */}
        </AvatarWrapper>
      </MainWrapper>
    )
  }
}
