import { AuthenticationRepository } from "app/Authentication/AuthenticationRepository"
import { ICompany } from 'app/ManageComapnies/ICompany'
import { CompanyListRepository } from 'app/ManageComapnies/CompanyList/CompanyListRepository'
import { IRecruiter } from "app/ManageRecruiters/IRecruiter"
import { ManageRecruiterRepository } from "app/ManageRecruiters/ManageRecruiterRepository"
import { StatusRepository } from 'app/StatusBar/StatusRepository'
import { inject, injectable } from 'inversify'
import { computed, observable } from 'mobx'
import moment from 'moment'
import 'reflect-metadata'
import { DashboardViews } from "app/Routing/TAvailableView"
import { ITokenContext } from "app/Authentication/IAuthUser"

@injectable()
export class DashboardPresenter {

  @inject(AuthenticationRepository)
  private authenticationRepository: AuthenticationRepository

  @inject(CompanyListRepository)
  private manageCompanyRepository: CompanyListRepository

  @inject(ManageRecruiterRepository)
  private manageRecruiterRepository: ManageRecruiterRepository

  @inject(StatusRepository)
  private statusRepository: StatusRepository

  @observable
  public formattedDate: string = moment().format('dddd Do MMM YYYY')

  get mainView(): DashboardViews {
    return this.authenticationRepository.mainView
  }

  get activeView(): DashboardViews {
    return this.authenticationRepository.activeView
  }

  get tokenContext(): ITokenContext {
    return this.authenticationRepository.activeTokenContext;
  }

  get showStatus(): boolean {
    if (!this.authenticationRepository.activeTokenContext) return false
    let { role } = this.authenticationRepository.activeTokenContext
    let { view } = this.authenticationRepository.activeNestedViewContext || { view: this.mainView }
    // if (role == TAuthUserRole.SUPER_ADMIN) return false
    // const isManageRecruiterView = [view, this.activeView].includes(DashboardViews.MANAGE_RECRUITERS)
    // console.log({ isManageRecruiterView })
    // if (isManageRecruiterView) return false
    // return true;

    if (view === DashboardViews.MY_DASHBOARD) return true;
    if (view === DashboardViews.MANAGER_DASHBOARD) return true;
    return false
  }


  public formatUserName(tokenContext: ITokenContext) {
    if (!tokenContext) return ""
    let { firstName, lastName } = tokenContext
    let name = `${firstName} ${lastName ? lastName.charAt(0) : ''}`
    if (name.length > 14) {
      name = `${name.slice(0, 11)}...`
    }
    return name
  }

  public formatUserId(tokenContext: ITokenContext) {
    if (!tokenContext) return ""
    return `${tokenContext.firstName.slice(0, 1).toLowerCase()}${tokenContext.lastName.slice(0, 1).toLowerCase()}-${tokenContext.userId.toString().padStart(8, '0')}`
  }

  public get badges() {
    return this.statusRepository.badges
  }

  public get level() {
    return this.statusRepository.level
  }

  public async getUserBadgesHistory() {
    return this.statusRepository.getUserBadgesHistory()
  }

  @computed
  public get companyList(): ICompany[] {
    return this.manageCompanyRepository.companyList
  }

  @computed
  public get recruiterList(): IRecruiter[] {
    return this.manageRecruiterRepository.recruiterList
  }
}

