import { LogoutPresenter } from 'app/Authentication/Logout/LogoutPresenter'
import { Button } from 'app/Shared/Button/Button'
import { Icon } from 'app/Shared/Icon/Icon'
import { withPresenter } from 'app/withPresenter'
import { observer } from 'mobx-react'
import * as React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    margin: 0;
  }
`

interface IProps {
  presenter: LogoutPresenter
}

const LogoutComponent = observer((props: IProps) => {
  const { submit } = props.presenter
  return (
    <Wrapper>
      <Button
        size={25}
        theme="iconButton"
        text={
          <Icon
            extraStyles={{ color: 'white' }}
            color={'white'}
            name={'logout'}
            size={25}
          ></Icon>
        }
        onClick={submit}
      />
    </Wrapper>
  )
})

export default withPresenter<any>(LogoutPresenter)(LogoutComponent)
