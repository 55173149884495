import { Dropdown } from 'app/Shared/Dropdown/Dropdown'
import React from 'react'
import { DeleteCompanyPresenter } from './DeleteCompanyPresenter'
import { withPresenter } from 'app/withPresenter'
import { Modal } from 'app/Shared/Modal/Modal'
import { Wrapper, CaptionText, OptionWrapper, Label } from '../styles'
import { observer } from 'mobx-react'

interface IProps {
  presenter: DeleteCompanyPresenter
}

@observer
class DeleteCompany extends React.Component<IProps> {
  render(): React.ReactNode {
    const { isOpen, deleteOptions, activeDeleteAction: activeSelection } = this.props.presenter
    if (!isOpen) return <div />
    return (
      <Modal
        closeModal={() => this.props.presenter.toggle()}
        title="Delete Company"
        showHeader
        saveButtonDisabled={!activeSelection}
        saveButtonColor={'danger'}
        onSaveClick={() => this.props.presenter.deleteCompany()}
      >
        <Wrapper>
          <CaptionText>
            Once this action is applied, it cannot be undone.
          </CaptionText>
          <OptionWrapper>
            <Label>
              Action<span>*</span>
            </Label>
            <Dropdown
              options={deleteOptions}
              onSelect={selected =>
                this.props.presenter.updatedActiveSelection(selected)
              }
            />
          </OptionWrapper>
        </Wrapper>
      </Modal>
    )
  }
}

export default withPresenter(DeleteCompanyPresenter)(DeleteCompany)
