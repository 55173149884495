import { HeaderPresenter } from "app/Header/HeaderPresenter";
import { INestedViewContext } from "app/Header/IModel";
import { inject, injectable } from "inversify";
import { ManageRecruiterRepository } from "./ManageRecruiterRepository";
import { toast } from "react-toastify";
import { LoaderPresenter } from "app/Shared/Loader/LoaderPresenter";
import { IPaginationDTO } from "gateways/service/stubs/dtos";
import { DashboardViews } from "app/Routing/TAvailableView";
@injectable()
export class ManageRecruitersPresenter {
    @inject(HeaderPresenter)
    private headerPresenter: HeaderPresenter

    @inject(ManageRecruiterRepository)
    private manageRecruiterRepository: ManageRecruiterRepository

    @inject(LoaderPresenter)
    private loaderPresenter: LoaderPresenter

    get activeView(): DashboardViews {
        return this.headerPresenter.mainView
    }

    get nestedViewContext(): INestedViewContext {
        return this.headerPresenter.nestViewContext
    }

    public async loadInitialData(pagination: IPaginationDTO) {
        try {
            this.loaderPresenter.showLoader()
            await this.manageRecruiterRepository.loadRecruiter(pagination.start, pagination.end)
            this.loaderPresenter.hideLoader()
        } catch (err) {
            toast.error(err);
        }
    }
}