import CurrentPage from 'app/Routing/CurrentPage/CurrentPage'
import FullScreenAnimation from 'app/Shared/FullScreen/FullScreenAnimation'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { createGlobalStyle, ThemeProvider } from 'styled-components'
import { Normalize } from 'styled-normalize'
import './styles/animations.css'
import './styles/pathOfAction.css'
import './styles/common.css'
import { base } from './themes'
import Loader from 'app/Shared/Loader/Loader'

const Global = createGlobalStyle`

  @import url('https://fonts.googleapis.com/css?family=Varela+Round&display=swap');
  html, body, #root {
    margin: 0;
    height: 100vh;
    font-size: 16px;
    text-rendering: optimizeLegibility;
    font-family: 'Varela Round', sans-serif;
  }
`

const theme = { ...base }

export const App = () => (
  <ThemeProvider theme={theme}>
    <>
      <Normalize />
      <Global />
      <CurrentPage />
      <FullScreenAnimation />
      <ToastContainer />
      <Loader />
    </>
  </ThemeProvider>
)

ReactDOM.render(<App />, document.getElementById('root'))
