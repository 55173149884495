import styled from "styled-components"
import { colors } from "styles/colors"

export const Wrapper = styled.div`
  color: ${colors.darkText};
  min-width: 400px;
  max-height: 600px;
`

export const OptionWrapper = styled.div`
  /* display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.spacing(3)};
  flex: 1 0 0%; */
`

export const Label = styled.label`
  text-align: left;
  font-size: ${({ theme }) => theme.fontSizes.xs};
  color: ${colors.darkText};
  line-height: 1.25;

  span {
    font-size: ${({ theme }) => theme.fontSizes.de};
    color: ${colors.errorText};
    line-height: 0.75;
  }
`
export const CaptionText = styled.p`
  color: ${colors.titleText}cc;
  font-size: ${({ theme }) => theme.fontSizes.sm};
  margin-block: -10px ${({ theme }) => theme.spacing(3)};
  line-height: 1.275;

  &.text-center {
    text-align: center;
  }
`

export const InputWrapper = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
gap: 16px;

input {
  padding-left: 0;
  width: 100%;
}
`