class CurrencyUtil {
    private ukCurrencyFormatter: Intl.NumberFormat;
    constructor() {
        this.ukCurrencyFormatter = new Intl.NumberFormat('en-GB', {
            style: 'currency',
            currency: 'GBP',
            minimumFractionDigits: 0,
            maximumFractionDigits: 2
        })
    }
    format(number: number | undefined | null) {
        return this.ukCurrencyFormatter.format(number || 0)
    }
}

export default new CurrencyUtil()