import * as React from 'react'
import styled from 'styled-components'
import { colors } from 'styles/colors'
import * as background from 'assets/background.png'
import * as logoName from 'assets/logo_name.png'
import * as logoIcon from 'assets/logo.svg'

const PageWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  background-image: url(${background});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  
  @media only screen and (max-height: 800px) {  
    div[class^="AuthenticationPageWrapper__Content"] {
      margin: 6vh auto auto;
    }
  }
`

const LogoWrapper = styled.div`
  position: absolute;
  display: flex;
  bottom: 20px;
  left: 20px;
  box-sizing: border-box;
`

const LogoIcon = styled.img`
  flex: 1;
  width: 60px;
  margin: 20px;
  height: 60px;
`
const LogoName = styled.img`
  flex: 1;
`

const Content = styled.div`
  height: auto;
  width: 480px;
  box-sizing: border-box;
  background-color: ${colors.white};
  padding: 40px;
  border-radius: 5px;
  margin: 20vh auto auto;
  text-align: center;
  color: ${colors.darkText};
  box-shadow: 0px 2px 7px -2px rgba(0, 0, 0, .2);
}
`

interface IProps {
  children: React.ReactNode
}

export const AuthenticationPageWrapper: React.FunctionComponent<
  IProps
> = props => {
  const { children } = props

  return (
    <PageWrapper>
      <LogoWrapper>
        <LogoIcon src={logoIcon} />
        <LogoName src={logoName} />
      </LogoWrapper>
      <Content>
        {children}
      </Content>
    </PageWrapper>
  )
}
