import { inject, injectable } from 'inversify'
import 'reflect-metadata'
import { action } from 'mobx'
import { AuthenticationRepository } from 'app/Authentication/AuthenticationRepository'

@injectable()
export class LogoutPresenter {

  @inject(AuthenticationRepository)
  private authenticationRepository: AuthenticationRepository

  @action
  public submit = async (): Promise<void> => {
    this.authenticationRepository.logout()
  }

}

