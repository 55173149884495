import { observable, reaction } from 'mobx'
import { inject, injectable, postConstruct } from 'inversify'
import { AbstractServiceGateway } from 'gateways/service/AbstractServiceGateway'
import 'reflect-metadata'
import { AuthenticationRepository } from 'app/Authentication/AuthenticationRepository'
import { IStatusDto, IStatusBadge, IBadgesHistory, IStatusBase, TCoinFilter, TStatusLevel, PlacementInterval } from 'app/StatusBar/IStatusDto'
import { IBaseResponse } from 'gateways/service/IBaseResponse'
import { LoaderPresenter } from 'app/Shared/Loader/LoaderPresenter'
import { toast } from 'react-toastify'
import { DashboardViews } from 'app/Routing/TAvailableView'
import { ICandidate } from 'app/Candidates/ICandidate'

@injectable()
export class StatusRepository {
  private firstPoll = true

  @inject(AbstractServiceGateway)
  private serviceGateway: AbstractServiceGateway

  @inject(AuthenticationRepository)
  private authenticationRepository: AuthenticationRepository

  @inject(LoaderPresenter)
  private loaderPresenter: LoaderPresenter

  @observable
  public mtdCoinCount: any = null

  @observable
  public ytdCoinCount: any = null

  @observable
  public coinCount: any = null

  @observable
  public cvSubmits: any = null

  @observable
  public cvPlaced: any = 0

  @observable
  public jobs: any = 0

  @observable
  public cumulativeDealFee: any = null

  @observable
  public coinFlag = 'hide'

  @observable
  public cvsFlag = 'hide'

  @observable
  public moneyFlag = 'hide'

  @observable
  public cvPlacedFlag = 'hide'

  @observable
  public jobsFlag = 'hide'

  @observable
  public showRainFallAnimation: boolean = true

  @observable
  public badges: IStatusBadge

  @observable
  public level: TStatusLevel

  public statusResponse: IStatusDto

  @observable
  public activeCoinFilter: TCoinFilter = 'currentMonth'

  @postConstruct()
  public async init() {
    reaction(
      () => this.authenticationRepository.tokenContext,
      async (tokenContext) => {
        if (tokenContext) await this.updateCoinCount()
      }
    )
    reaction(
      () => this.authenticationRepository.activeNestedViewContext,
      async (activeNestedViewContext) => {
        let tokenContext = this.authenticationRepository.activeTokenContext
        if (!activeNestedViewContext) {
          if (this.authenticationRepository.mainView === DashboardViews.MANAGER_DASHBOARD)
            return await this.updateCoinCount(null, tokenContext.companyId)
          return await this.updateCoinCount()
        }
        if (!tokenContext) return
        if (activeNestedViewContext.view === DashboardViews.MANAGER_DASHBOARD)
          return await this.updateCoinCount(null, tokenContext.companyId)
        return await this.updateCoinCount(tokenContext.userId)
      }
    )
  }

  private applyCoinCount(data: IStatusBase, isFilterAction: boolean = false) {
    if (isFilterAction) {
      this.coinCount = data.coins
      this.cumulativeDealFee = data.cumulativeDealFee
      this.cvSubmits = data.cvSubmits
      this.cvPlaced = data.cvPlaced
      this.jobs = data.jobs
      return
    }

    this.showRainFallAnimation = false
    if (this.coinCount !== data.coins) {
      this.showRainFallAnimation = data.coins > this.coinCount
      if (!this.firstPoll) {
        this.coinFlag = 'show'
        setTimeout(() => {
          this.coinFlag = 'hide'
          this.coinCount = data.coins
        }, 2001)
      } else {
        this.coinCount = data.coins
      }
    }

    if (this.cumulativeDealFee !== data.cumulativeDealFee) {
      this.showRainFallAnimation = data.cumulativeDealFee > this.cumulativeDealFee
      if (!this.firstPoll) {
        this.moneyFlag = 'show'
        setTimeout(() => {
          this.moneyFlag = 'hide'
          this.cumulativeDealFee = data.cumulativeDealFee
        }, 2001)
      } else {
        this.cumulativeDealFee = data.cumulativeDealFee
      }
    }

    if (this.cvSubmits !== data.cvSubmits) {
      this.showRainFallAnimation = data.cvSubmits > this.cvSubmits
      if (!this.firstPoll) {
        this.cvsFlag = 'show'
        setTimeout(() => {
          this.cvsFlag = 'hide'
          this.cvSubmits = data.cvSubmits
        }, 2001)
      } else {
        this.cvSubmits = data.cvSubmits
      }
    }

    if (this.cvPlaced !== data.cvPlaced) {
      this.showRainFallAnimation = data.cvPlaced > this.cvPlaced
      if (!this.firstPoll) {
        this.cvPlacedFlag = 'show'
        setTimeout(() => {
          this.cvPlacedFlag = 'hide'
          this.cvPlaced = data.cvPlaced
        }, 2001)
      } else {
        this.cvPlaced = data.cvPlaced
      }
    }

    if (this.jobs !== data.jobs) {
      this.showRainFallAnimation = data.jobs > this.jobs
      if (!this.firstPoll) {
        this.jobsFlag = 'show'
        setTimeout(() => {
          this.jobsFlag = 'hide'
          this.jobs = data.jobs
        }, 2001)
      } else {
        this.jobs = data.jobs
      }
    }
  }

  public async updateCoinCount(userId = this.authenticationRepository.user.id, companyId: any = '') {
    try {
      // this.loaderPresenter.showLoader()
      const response = await this.serviceGateway.get<IStatusDto>(`/status?userId=${userId}&companyId=${companyId}`)
      this.applyCoinCount(response[this.activeCoinFilter]) //by default total coins will displayed
      this.statusResponse = response
      this.badges = response.badges
      this.level = response.level
      this.firstPoll = false
    } catch (err) {
      toast.error(err.message)
    }
    // this.loaderPresenter.hideLoader()
  }

  public async getUserBadgesHistory() {
    const response = await this.serviceGateway.get<IBaseResponse<IBadgesHistory[]>>('/badges-history')
    if (!response.success) throw new Error(response.message)
    return response.result
  }

  public applyCoinFilter(filter: TCoinFilter) {
    this.activeCoinFilter = filter
    this.applyCoinCount(this.statusResponse[filter], true)
  }

  public async getPlacementStatus() {
    let payload: any = { interval: this.activeCoinFilter }
    if (this.authenticationRepository.activeView == DashboardViews.MANAGER_DASHBOARD)
      payload.companyId = this.authenticationRepository.activeTokenContext.companyId

    const searchParams = new URLSearchParams(payload)
    const response = await this.serviceGateway.get<IBaseResponse<ICandidate[]>>(
      `/placement-status?${searchParams.toString()}`
    )
    if (response.success) return response.result
    toast.error(response.message)
  }
}

