import React from 'react'
import Lottie from 'react-lottie'

// @ts-ignore
import * as checkboxAnimation from 'assets/animations/checkbox.json'
// @ts-ignore
import * as checkboxReverseAnimation from 'assets/animations/checkboxReverse.json'
import styled from 'styled-components'

interface IProps {
  isChecked: boolean
  onChange: (isChecked: boolean) => void
}

interface IState {
  isClicked: boolean
}

const Checkbox = styled.div`
  cursor: pointer;
`

export class LottieCheckBox extends React.Component<IProps, IState> {
  constructor(props) {
    super(props)
    this.state = {
      isClicked: false
    }
  }

  toggle() {
    this.setState({ isClicked: true })
    setTimeout(() => {
      this.props.onChange(!this.props.isChecked)
      this.setState({ isClicked: false })
    }, 205)
  }

  render(): React.ReactNode {
    return (
      <Checkbox onClick={this.toggle.bind(this)}>
        {this.props.isChecked && (
          <Lottie
            options={{
              loop: false,
              autoplay: false,
              animationData: checkboxReverseAnimation.default
            }}
            isStopped={!this.state.isClicked}
            style={{ margin: '0px 2px 0' }}
            height={40}
            width={40}
          />
        )}
        {!this.props.isChecked && (
          <Lottie
            options={{
              loop: false,
              autoplay: false,
              animationData: checkboxAnimation.default
            }}
            isStopped={!this.state.isClicked}
            style={{ margin: '0px 2px 0' }}
            height={40}
            width={40}
          />
        )}
      </Checkbox>
    )
  }
}
