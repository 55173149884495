import { AuthenticationRepository } from 'app/Authentication/AuthenticationRepository'
import { AbstractServiceGateway } from 'gateways/service/AbstractServiceGateway'
import { inject, injectable, postConstruct } from 'inversify'
import { action, observable, reaction } from 'mobx'
import { IJob } from './IJobs'
import { IJobsResponseDto } from 'gateways/service/stubs/dtos'
import { StepsRepository } from 'app/Steps/StepsRepository'

@injectable()
export class JobsRepository {
  @inject(AuthenticationRepository)
  private authenticationRepository: AuthenticationRepository

  @inject(AbstractServiceGateway)
  private serviceGateway: AbstractServiceGateway

  @inject(StepsRepository)
  private stepsRepository: StepsRepository

  @postConstruct()
  public init() {
    reaction(
      () => this.authenticationRepository.tokenContext,
      () => this.loadJobs()
    )
    reaction(
      () => this.authenticationRepository.activeNestedViewContext,
      () => this.loadJobs()
    )
  }

  @observable
  public jobs: IJob[] = []

  @action
  public setJobs(jobs = []) {
    this.jobs = jobs
  }

  public getJob(id: number): IJob {
    return this.jobs.find(i => i.id == id)
  }

  @action
  public async loadJobs() {
    const tokenContext = this.authenticationRepository.activeTokenContext
    if (!tokenContext) return
    const payload = { user_id: tokenContext.userId }
    if (tokenContext) {
      payload.user_id = tokenContext.userId
      await this.reloadSteps(true, tokenContext.workflowId)
    }

    const response = await this.serviceGateway.post<IJobsResponseDto>(
      '/jobs/list',
      payload
    )
    if (response.success) {
      this.setJobs(response.result)
    } else console.error(response.message)
  }

  @action
  public async createJob(name: string): Promise<IJobsResponseDto<IJob>> {
    const job: IJob = { name, user_id: this.authenticationRepository.user.id }
    const result = await this.serviceGateway.post<IJobsResponseDto<IJob>>(
      '/jobs/create',
      job
    )
    if (result.success) await this.loadJobs()
    return result
  }

  @action
  public async updateJob(job: IJob): Promise<IJobsResponseDto<any>> {
    const result = await this.serviceGateway.post<IJobsResponseDto<any>>(
      '/jobs/update',
      job
    )
    if (result.success) await this.loadJobs()
    return result
  }

  @action
  public async reloadSteps(authenticated, workflowId) {
    await this.stepsRepository.loadSteps(authenticated, workflowId)
  }

  @action
  public async deleteJob(job: IJob): Promise<IJobsResponseDto<any>> {
    const result = await this.serviceGateway.post<IJobsResponseDto<any>>(
      '/jobs/delete',
      job
    )
    if (result.success) {
      await this.loadJobs()
      await this.reloadSteps(
        this.authenticationRepository.authenticated,
        this.authenticationRepository.user.workflowId
      )
    }
    return result
  }
}
