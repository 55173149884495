import { Container } from 'inversify'
import 'reflect-metadata'
import { AbstractWindowGateway } from 'gateways/window/AbstractWindowGateway'
import { HttpServiceGateway } from 'gateways/service/HttpServiceGateway'
import { AbstractServiceGateway } from 'gateways/service/AbstractServiceGateway'
import { FakeServiceGateway } from 'gateways/service/FakeServiceGateway'
import { FakeWindowGateway } from 'gateways/window/FakeWindowGateway'
import { WindowGateway } from 'gateways/window/WindowGateway'
import { AbstractStorageGateway } from 'gateways/storage/AbstractStorageGateway'
import { CookieStorageGateway } from 'gateways/storage/CookieStorageGateway'
import { FakeStorageGateway } from 'gateways/storage/FakeStorageGateway'

// Use the container to bind your gateways, repositories and presenters.
// Injectables not bound here will be automatically bound to self.

const createContainer = () => {
  const container = new Container({
    autoBindInjectable: true,
    defaultScope: 'Singleton'
  })
  const inTest = process.env.NODE_ENV === 'test'

  type Class = { new (...args: any[]): any }

  const injectables: {
    base: any
    scope?: 'singleton' | 'transient' // defaults to singleton
    test?: Class // the implementation to use in test, defaults to base
    real?: Class // the implementation to use in real, defaults to base
  }[] = [
    {
      base: AbstractServiceGateway,
      real: HttpServiceGateway,
      test: FakeServiceGateway
    },
    {
      base: AbstractWindowGateway,
      real: WindowGateway,
      test: FakeWindowGateway
    },
    {
      base: AbstractStorageGateway,
      real: CookieStorageGateway,
      test: FakeStorageGateway
    }
  ]

  injectables.forEach(injectable => {
    const { base, scope = 'singleton', test = base, real = base } = injectable
    const implementation = inTest ? test : real

    if (scope === 'singleton') {
      container
        .bind(base)
        .to(implementation)
        .inSingletonScope()
    }
    if (scope === 'transient') {
      container
        .bind(base)
        .to(implementation)
        .inTransientScope()
    }
  })

  return container
}

let container = createContainer()

const resetContainer = () => {
  container = createContainer()
}

export { container, resetContainer }
