import { IPresenter } from 'config/Config'
import { observer } from 'mobx-react'
import React, { CSSProperties } from 'react'
import styled, { css } from 'styled-components'
import { withPresenter } from 'app/withPresenter'
import { OverViewPresenter } from './OverviewPresenter'

const InfoWrapper = styled.div`
  display: flex;
  height: 15%;
  gap: 16px;
`

const InfoItem = styled.div<CSSProperties>`
  border: 1px solid green;
  flex: 1;

  height: 90%;
  border-radius: 8px;
  border: 1.5px solid rgba(0, 0, 0, 0.1);

  ${props =>
    props.background &&
    css`
      background: ${props.background};
    `}

  display: flex;
  justify-content: end;
  flex-direction: column;
  padding-left: 8px;
`

const InfoNumber = styled.div`
  font-size: 36px;

  span {
    display: block;
    font-size: 15px;
    margin: 8px 0px;
    text-transform: uppercase;
  }
`

@observer
class OverViewComponent extends React.Component<IPresenter<OverViewPresenter>> {
  render(): React.ReactNode {
    const { stats } = this.props.presenter
    return (
      <>
        <InfoWrapper>
          {stats.map((item, index) => {
            return (
              <InfoItem key={index} background={item.bgColor}>
                <InfoNumber>
                  {item.count.toLocaleString()} <span>{item.name}</span>
                </InfoNumber>
              </InfoItem>
            )
          })}
        </InfoWrapper>
      </>
    )
  }
}

export default withPresenter(OverViewPresenter)(OverViewComponent)
