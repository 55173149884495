import { AbstractClassValidator, MAX_LENGTH, MIN_LENGTH, validated } from '@logicroom/validator'
import { ITextInput } from 'app/Shared/TextInput/ITextInput'
import { StepsRepository } from 'app/Steps/StepsRepository'
import { inject, injectable } from 'inversify'
import { upperFirst } from 'lodash'
import { action, computed, observable } from 'mobx'
import { ICompany, ISelectedCompany } from '../ICompany'
import { CompanyListRepository } from '../CompanyList/CompanyListRepository'

@injectable()
export class AddCompanyPresenter extends AbstractClassValidator {
  @inject(StepsRepository)
  private stepsRepository: StepsRepository

  @inject(CompanyListRepository)
  private manageCompanyRepository: CompanyListRepository

  @observable
  public isOpen: boolean = false

  @observable
  public company: ICompany = null

  @observable
  public selectedCompany: ISelectedCompany | null = null

  public setCompany(company: ISelectedCompany | null) {
    this.selectedCompany = company
    this.companyName.value = company.name
    this.firstName.value = company.firstName
    this.lastName.value = company.lastName
  }

  @observable
  public isDelete: boolean | null = null

  public setIsDelete(isDelete: boolean | null) {
    this.isDelete = isDelete
  }

  @observable
  public serverErrors = []

  @validated({
    mandatory: true,
    rules: [
      {
        rule: MAX_LENGTH(20),
        errorMessage: 'Admin first name too long, up to 20 characters allowed.'
      },
      {
        rule: MIN_LENGTH(2),
        errorMessage: 'Admin first name must be at least 3 characters long.'
      }
    ]
  })
  public firstName: ITextInput = {
    placeholder: 'Admin First Name',
    label: 'Admin First Name'
  }

  @validated({
    mandatory: true,
    rules: [
      {
        rule: MAX_LENGTH(20),
        errorMessage: 'Admin last name too long, up to 20 characters allowed.'
      },
      {
        rule: MIN_LENGTH(2),
        errorMessage: 'Admin last name must be at least 3 characters long.'
      }
    ]
  })
  public lastName: ITextInput = {
    placeholder: 'Admin Last Name',
    label: 'Admin Last Name'
  }

  @validated({
    mandatory: true,
    rules: [
      {
        rule: (value) => {
          const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
          return emailRegex.test(value)
        },
        errorMessage: 'Invalid email format.'
      },
      {
        rule: MAX_LENGTH(255),
        errorMessage: 'Email Address too long, up to 255 characters allowed.'
      },
      {
        rule: MIN_LENGTH(2),
        errorMessage: 'Email Address should be at least 3 chars'
      }
    ]
  })
  public emailAddress: ITextInput = {
    placeholder: 'Admin Email Address',
    label: 'Admin Email Address'
  }

  @validated({
    mandatory: true,
    rules: [
      {
        rule: MAX_LENGTH(255),
        errorMessage: 'Company too long, up to 255 characters allowed.'
      },
      {
        rule: MIN_LENGTH(2),
        errorMessage: 'Company should be at least 3 chars'
      }
    ]
  })
  public companyName: ITextInput = {
    placeholder: 'type here',
    label: 'Company Name'
  }

  public cancel = async () => {
    this.isOpen = false
    this.firstName.value = ''
    this.lastName.value = ''
    this.emailAddress.value = ''
    this.companyName.value = ''
    this.selectedCompany = null
  }
  
  public toggle = () => {
    this.isOpen = !this.isOpen
  }

  @action
  public upperFirstName = (value): void => {
    this.firstName.onChange(upperFirst(value))
  }

  @action
  public upperLastName = (value): void => {
    this.lastName.onChange(upperFirst(value))
  }

  @action
  public upperEmailAddress = (value): void => {
    this.emailAddress.onChange(value)
  }

  @action
  public upperCompanyName = (value): void => {
    this.companyName.onChange(upperFirst(value))
  }

  @computed
  public get updateFirstName() {
    return { ...this.firstName, onChange: this.upperFirstName }
  }

  @computed
  public get updateLastName() {
    return { ...this.lastName, onChange: this.upperLastName }
  }

  @computed
  public get updateEmailAddress() {
    return { ...this.emailAddress, onChange: this.upperEmailAddress }
  }

  @computed
  public get updateCompanyName() {
    return { ...this.companyName, onChange: this.upperCompanyName }
  }

  @computed
  public get canSubmit(): boolean {
    if (this.selectedCompany !== null) {
      return this.companyName.isValid
    } else {
      return (
        this.firstName.isValid &&
        this.lastName.isValid &&
        this.emailAddress.isValid &&
        this.companyName.isValid
      )
    }
  }

  public submitEdit = async () => {
    if (this.canSubmit) {
    }
  }

  public submit = async () => {
    if (this.canSubmit) {
      if (this.company === null) {
      this.manageCompanyRepository.createCompany(
        this.companyName.value,
        this.firstName.value, 
        this.lastName.value,
        this.emailAddress.value)
      }
      this.cancel()
    }
  }

}