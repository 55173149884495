import * as React from 'react'
import styled from 'styled-components'
import { Modal } from 'app/Shared/Modal/Modal'
import { colors } from 'styles/colors'
import { Button } from 'app/Shared/Button/Button'
import * as culGift from 'assets/culGift.svg'

const Wrapper = styled.div`
  color: ${colors.darkText};
  min-width: 600px;
  max-height: 600px;
  padding: 32px;

  button {
    margin: 24px auto;

    span {
      font-size: 18px;
    }
  }
`

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled.h2`
  font-size: 2em;
  margin: 20px 0;
  text-align: center;
`

const ButtonWrapper = styled.div`
  display: flex;

  button {
    width: 180px;
  }
`

const PrizeWrapper = styled.div`
  display: flex;
  margin: 20px;
  justify-content: space-between;

  div {
    cursor: pointer;
  }
`

const GiftWrapper = styled.div`
  padding: 8px;

  &.selected {
    background: #f2e9cd;
    border-radius: 8px;
  }
`

const Gift = styled.img`
  width: 96px;
  cursor: pointer;
`

const Description = styled.p`
  text-align: center;
`

interface IProps {
  onClose: () => void
}

interface IState {
  prizes: IPrize[]
  isSelected: boolean
}

interface IPrize {
  key: number
  selected: boolean
}

export class RewardDialog extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      isSelected: false,
      prizes: [
        { key: 0, selected: false },
        { key: 1, selected: false },
        { key: 2, selected: false }
      ]
    }
  }

  private selectPrize(prize: IPrize) {
    this.setState({
      isSelected: true,
      prizes: this.state.prizes.map(p => ({
        ...p,
        selected: p.key === prize.key
      }))
    })
  }

  public render() {
    return (
      <Modal
        closeModal={() => {
          if (this.state.isSelected) {
            this.props.onClose()
          }
        }}
        dialogWidth="700px"
        saveButtonDisabled={!this.state.isSelected}
        onSaveClick={() => this.props.onClose()}
        saveButtonText="COLLECT"
      >
        <Wrapper>
          <HeaderWrapper>
            <Title>Click a box to claim your reward</Title>
            <Description>
              Pick one of the following to win either 50, 100 or 250 culabr
              coins
            </Description>
          </HeaderWrapper>

          <PrizeWrapper>
            {this.state.prizes.map(prize => (
              <GiftWrapper
                key={prize.key}
                className={prize.selected ? 'selected' : ''}
              >
                <Gift src={culGift} onClick={() => this.selectPrize(prize)} />
              </GiftWrapper>
            ))}
          </PrizeWrapper>
        </Wrapper>
      </Modal>
    )
  }
}
