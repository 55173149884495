import * as React from 'react'
import {observer} from 'mobx-react'
import {ResetPasswordSuccessPresenter} from './ResetPasswordSuccessPresenter'
import styled from 'styled-components'
import {withPresenter} from 'app/withPresenter'
import {AuthenticationPageWrapper} from '../AuthenticationPageWrapper/AuthenticationPageWrapper'
import * as logoIcon from 'assets/logoBlue.svg'
import {Button} from "app/Shared/Button/Button";

const Logo = styled.img`
  margin: 20px 0;
  width: 90px;
`
const Paragraph = styled.p`
  margin: 30px 0 0;
  line-height: 1.5em;
  text-align: justify;
`

const Title = styled.h1`
  margin-bottom: 20px;
`

const Centered = styled.div`
  width: 100%;
  text-align: center;
  
  button { 
    margin: 40px auto 5px;
  }
`

interface IProps {
  presenter: ResetPasswordSuccessPresenter
}

const ResetPasswordSuccessComponent = observer((props: IProps) => {
  const {
    submit,
  } = props.presenter
  return (
    <AuthenticationPageWrapper>
      <Logo src={logoIcon} />
      <Title>Password reset successful</Title>

      <Paragraph>
        Great news!
        Your password has now been reset.
        Check your email and follow the instructions.
      </Paragraph>

      <Centered>
        <Button
          theme="secondary"
          text="Back to Sign in"
          onClick={submit}
        />
      </Centered>
    </AuthenticationPageWrapper>
  )
})

export default withPresenter(ResetPasswordSuccessPresenter)(ResetPasswordSuccessComponent)
