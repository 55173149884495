import axios, { AxiosError, AxiosInstance } from 'axios'
import config from 'config/Config'
import { AbstractServiceGateway } from './AbstractServiceGateway'
import { injectable, postConstruct } from 'inversify'
import 'reflect-metadata'
import { observable } from 'mobx'

@injectable()
export class HttpServiceGateway implements AbstractServiceGateway {
  public accessToken: string
  public nestedToken: string
  public axios: AxiosInstance

  @observable
  public forceLogout: boolean = false


  @postConstruct()
  public init() {
    this.axios = axios.create()
    this.axios.interceptors.response.use((response) => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      return response;
    }, (error: AxiosError) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      if (error.response.status === 401) {
        this.forceLogout = true
        setTimeout(() => {
          this.forceLogout = false
        }, 100)
      }
      return Promise.reject(error);
    })
  }

  public set token(token: string) {
    this.accessToken = token
  }

  public set nestedViewToken(token: string) {
    this.nestedToken = token
  }

  private getHeaders(): any {
    const retValue: any = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': '*'
      }
    }
    if (this.nestedToken || this.accessToken) {
      retValue.headers['Authorization'] = this.nestedToken || this.accessToken
    }
    return retValue
  }

  public async post<TResponseDto>(
    path: string,
    requestDto: {},
    headers: {} = {}
  ): Promise<TResponseDto> {
    const _headers = this.getHeaders()
    const { data } = await this.axios.post(
      `${config.host}/api${path}`,
      requestDto,
      {
        headers: {
          ..._headers.headers,
          ...headers
        }
      }
    )
    return data
  }

  public async get<TResponseDto>(path: string, headers: {} = {}): Promise<TResponseDto> {
    const _headers = this.getHeaders()
    const { data } = await this.axios.get(
      `${config.host}/api${path}`,
      {
        headers: {
          ..._headers.headers,
          ...headers
        }
      }
    )
    return data
  }

  public async delete<TResponseDto>(path: string): Promise<TResponseDto> {
    const { data } = await this.axios.delete(
      `${config.host}/api${path}`,
      this.getHeaders()
    )
    return data
  }

  public async download(method: string, path: string) {
    let axiosConfig = {
      url: `${config.host}/api${path}`,
      method,
      responseType: 'blob'
    }

    this.axios({ ...axiosConfig, ...this.getHeaders() }).then(res => {
      const url = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'Report.csv')
      document.body.appendChild(link)
      link.click()
    })
  }
}
