import LogoutComponent from 'app/Authentication/Logout/LogoutComponent'
import { DashboardPresenter } from 'app/Dashboard/DashboardPresenter'
import RightSidePanelWrapper from 'app/RightSidePanel/RightSidePanelWrapper'
import { IBadgesHistory } from 'app/StatusBar/IStatusDto'
import StatusComponent from 'app/StatusBar/StatusComponent'
import { withPresenter } from 'app/withPresenter'
import * as badgeCV from 'assets/badgeCVColor.svg'
import * as badgeCash from 'assets/badgeCashColor.svg'
import * as badgeCulabrCoins from 'assets/badgeCulabrCoinsColor.svg'
import * as badgeJobs from 'assets/badgeJobsColor.svg'
import * as logoIcon from 'assets/logo.svg'
import * as logoName from 'assets/logo_name.png'
import { ACTION_WIDTH, LOGO_HEADER_HEIGHT } from 'config/constants'
import { observer } from 'mobx-react'
import * as React from 'react'
import styled, { css } from 'styled-components'
import { colors } from 'styles/colors'
import StepsComponent from '../Steps/StepsPanel/StepsComponent'
import { BadgeHistoryDialog } from './HistoryDialog/BadgeHistoryDialog'
import Avatar from './UserAvatar'
import ManageCompanies from 'app/ManageComapnies/ManageCompanies'
import ManageRecruiter from 'app/ManageRecruiters/ManageRecruiter'
import { StepsAndJobsWrapper } from 'styles'
import CompanyStatesComponent from 'app/CompanyStats/CompanyStatesComponent'
import { DashboardViews } from 'app/Routing/TAvailableView'

const LogoHeader = styled.div`
  display: flex;
  background-color: ${colors.mainBg};
  height: ${LOGO_HEADER_HEIGHT}px;
  flex-shrink: 0;
  padding: 0 ${({ theme }) => theme.spacing(3)};
`

const LogoWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};
  align-items: center;
`

const LogoIcon = styled.img(
  ({ theme }) => `
  margin-left: ${theme.spacing(1)};
  width: ${theme.spacing(4)};
  height: ${theme.spacing(4)};
`
)

const LogoName = styled.img`
  height: 60px;
  width: 128px;
`
const Spacer = styled.div`
  flex: 1;
`

const UserWrapper = styled.div(
  ({ theme }) => `
  margin: ${theme.spacing(0, 2, 0, 4)};
  min-width: ${ACTION_WIDTH - 100}px;
  max-width: ${ACTION_WIDTH - 40}px;
  display: flex;
  gap: ${theme.spacing(1)};
`
)

const UserDetails = styled.div<any>`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${colors.whiteText};
  gap: 2px;
  align-items: start;

  ${props =>
    props.showLogout != true &&
    css`
      align-items: end;
      width: 100%;
    `}
`

const UserName = styled.span<any>`
  font-size: 1em;
  white-space: nowrap;
`
const UserID = styled.span`
  font-size: 0.6em;
`

const UserBadgesWrapper = styled.div`
  display: flex;

  img {
    padding: 1px;
    margin: 2px;
    width: 22px;
    height: 22px;
    box-sizing: border-box;
    background: ${colors.whiteBg};
    border-radius: 50%;
  }
`

const DummyLogout = styled.div`
  width: 25px;
`

const Badge = styled.img``

const ordinalIndicatedNumberMatch = new RegExp(/([0-9]{1,2})(rd|nd|th|st)/)
const ordinalIndicatorMatch = new RegExp(/^(rd|nd|th|st)$/)

const OrdinalIndicator = styled.span`
  font-size: 0.6rem;
`

interface IProps {
  presenter: DashboardPresenter
}

interface IState {
  isBadgeHistoryDialogOpen: boolean
  isBadgeHistoryLoading: boolean
  badges: IBadgesHistory[]
  splitDate: any
}

@observer
class DashboardPageComponent extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      isBadgeHistoryDialogOpen: false,
      isBadgeHistoryLoading: false,
      badges: [],
      splitDate: props.presenter.formattedDate
        .split(ordinalIndicatedNumberMatch)
        .map((substring: string) => {
          return ordinalIndicatorMatch.test(substring) ? (
            <OrdinalIndicator key={substring}>{substring}</OrdinalIndicator>
          ) : (
            <span key={substring}>{substring}</span>
          )
        })
    }
  }

  private onFetchBadgesHistoryClick = () => {
    this.setState({
      isBadgeHistoryLoading: true,
      isBadgeHistoryDialogOpen: true
    })

    this.props.presenter.getUserBadgesHistory().then(badges => {
      this.setState({
        isBadgeHistoryLoading: false,
        badges: badges
      })
    })
  }

  private onBadgeHistoryDialogClose() {
    this.setState({
      isBadgeHistoryLoading: false,
      isBadgeHistoryDialogOpen: false
    })
  }

  public render() {
    const {
      mainView,
      activeView,
      showStatus,
      tokenContext,
      formatUserName,
      formatUserId
    } = this.props.presenter

    const isMainViewIsMyDashboardView = mainView === DashboardViews.MY_DASHBOARD
    const isMyDashboardView = activeView === DashboardViews.MY_DASHBOARD
    const isMangeCompaniesView = activeView === DashboardViews.MANAGE_COMPANIES
    const showLogout = isMainViewIsMyDashboardView || isMangeCompaniesView
    const showUserDetails = showLogout || isMyDashboardView

    return (
      <>
        <LogoHeader>
          <LogoWrapper>
            <LogoIcon src={logoIcon} />
            <LogoName src={logoName} />
          </LogoWrapper>

          <Spacer />

          {showStatus && <StatusComponent />}

          <UserWrapper>
            {showLogout && (
              <Avatar
                {...this.props.presenter.badges}
                level={this.props.presenter.level}
                showBadges={false}
                showBadgesHistory={false}
              />
            )}

            {tokenContext && (
              <UserDetails
                showLogout={showLogout}
                onClick={() => {
                  if (isMyDashboardView) this.onFetchBadgesHistoryClick()
                }}
              >
                {showUserDetails && (
                  <UserName>
                    {formatUserName(tokenContext)}{' '}
                    <UserID>({formatUserId(tokenContext)})</UserID>
                  </UserName>
                )}
                {!showUserDetails && (
                  <UserName>{tokenContext.companyName}</UserName>
                )}

                {this.props.presenter.badges && isMyDashboardView && (
                  <UserBadgesWrapper>
                    {this.props.presenter.badges.cumulativeDealFee && (
                      <Badge src={badgeCash} />
                    )}
                    {this.props.presenter.badges.cvPlaced && (
                      <Badge src={badgeJobs} />
                    )}
                    {this.props.presenter.badges.cvSubmits && (
                      <Badge src={badgeCV} />
                    )}
                    {this.props.presenter.badges.coins && (
                      <Badge src={badgeCulabrCoins} />
                    )}
                  </UserBadgesWrapper>
                )}
                {/* <Date>{splitDate}</Date> */}
              </UserDetails>
            )}
          </UserWrapper>
          {showLogout && <LogoutComponent />}
          {!showLogout && <DummyLogout />}
        </LogoHeader>

        {mainView === DashboardViews.MY_DASHBOARD && (
          <StepsAndJobsWrapper>
            <StepsComponent />
            <RightSidePanelWrapper />
          </StepsAndJobsWrapper>
        )}

        {mainView === DashboardViews.MANAGE_COMPANIES && <ManageCompanies />}

        {mainView === DashboardViews.MANAGE_RECRUITERS && <ManageRecruiter />}

        {mainView === DashboardViews.MANAGER_DASHBOARD && (
          <CompanyStatesComponent />
        )}

        {this.state.isBadgeHistoryDialogOpen && (
          <BadgeHistoryDialog
            loading={this.state.isBadgeHistoryLoading}
            records={this.state.badges}
            onClose={() => this.onBadgeHistoryDialogClose()}
          />
        )}
      </>
    )
  }
}
export default withPresenter(DashboardPresenter)(DashboardPageComponent)
