import { LottieCheckBox } from 'app/Shared/CheckBoxInput/LottieCheckBox'
import { Modal } from 'app/Shared/Modal/Modal'
import { TextInput } from 'app/Shared/TextInput/TextInput'
import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  min-width: 400px;
  max-height: 600px;
`

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;

  input {
    width: 100%;
  }
`

const DeleteWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Confirmation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const ConfirmationNotes = styled.div`
  background-color: #f0f7fb;
  border-left: 4px solid #3498db;
  min-height: 40px;
  overflow-y: auto;
  width: 100%;
  padding: 8px 8px 2px 12px;
  font-size: smaller;
`

interface IAddJobProps {
  errorMessage?: string
  jobName?: string
  isEditActivity?: boolean
  handleOnCancelClick: () => void
  handleOnSubmitClick: (name: string) => void
  handleOnDeleteClick?: () => Promise<any>
}

interface IState {
  name: string
  isDeleteCheckboxChecked?: boolean
}

export class AddJob extends React.Component<IAddJobProps, IState> {
  constructor(props: IAddJobProps) {
    super(props)
    this.state = {
      name: props.jobName || ''
    }
  }

  handleOnNameChange(value: string) {
    this.setState({ name: value })
  }

  render(): React.ReactNode {
    return (
      <Modal
        closeModal={this.props.handleOnCancelClick}
        showHeader={true}
        title={`${this.props.isEditActivity ? 'Edit' : 'Add'} a Job`}
        onSaveClick={() => this.props.handleOnSubmitClick(this.state.name)}
        saveButtonDisabled={
          !this.state.name || this.state.isDeleteCheckboxChecked
        }
        onDeleteClick={this.props.handleOnDeleteClick}
        deleteButtonDisabled={!this.state.isDeleteCheckboxChecked}
        deleteButtonText={'Close'}
        hasDelete={this.props.isEditActivity}
      >
        <Wrapper>
          <InputWrapper>
            <TextInput
              input={{
                value: this.state.name,
                label: 'Job Identifier',
                onChange: this.handleOnNameChange.bind(this)
              }}
              theme="secondary"
              showAsterisk={true}
            />
          </InputWrapper>

          {this.props.isEditActivity && (
            <DeleteWrapper>
              <Confirmation>
                <div>Mark for close</div>
                <LottieCheckBox
                  isChecked={this.state.isDeleteCheckboxChecked}
                  onChange={isChecked => {
                    this.setState({ isDeleteCheckboxChecked: isChecked })
                  }}
                />
              </Confirmation>

              <ConfirmationNotes
                className={this.state.isDeleteCheckboxChecked ? '' : 'dim'}
              >
                If you close this Job, all the candidate associated to it will
                be deleted from the dashboard. Are you sure you want to
                continue?
              </ConfirmationNotes>
            </DeleteWrapper>
          )}
        </Wrapper>
      </Modal>
    )
  }
}
