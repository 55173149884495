const spacer = 8;
interface Spacing {
  (): string;
  (value: number): string;
  (topBottom: number, rightLeft: number): string;
  (top: number, rightLeft: number, bottom: number): string;
  (top: number, right: number, bottom: number, left: number): string;
}

export const base = {
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400
  },
  fonts: {
    heading: '"Varela Round", sans-serif',
    body: '"Varela Round", sans-serif',
  },
  fontSizes: {
    xs: '12px',
    sm: '14px',
    de: '16px',
    md: '20px',
    lg: '24px',
  },
  spaces: Array.from({ length: 7 }, (_, index) => `${spacer * index}px`),
  /* spacing: (all = 0, top = 0, right = 0, bottom = 0, left = 0) => {
    if (all !== 0) {
      return `${base.spaces[all]}`;
    }
    const spaces = [top, right, bottom, left].map(spaceIndex => base.spaces[spaceIndex]);
    return spaces.join(' ');
  }, */
  spacing: ((...args) => {
    switch (args.length) {
      case 0:
        return '0';
      case 1:
        return `${base.spaces[args[0]]}`;
      case 2:
        return `${base.spaces[args[0]]} ${base.spaces[args[1]]}`;
      case 3:
        return `${base.spaces[args[0]]} ${base.spaces[args[1]]} ${base.spaces[args[2]]}`;
      case 4:
        return `${base.spaces[args[0]]} ${base.spaces[args[1]]} ${base.spaces[args[2]]} ${base.spaces[args[3]]}`;
      default:
        return '0';
    }
  }) as Spacing,
}