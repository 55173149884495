export type TAvailableView =
  | 'login'
  | 'register'
  | 'registerSuccess'
  | 'registerVerification'
  | 'reset'
  | 'resetSuccess'
  | 'resetPassword'
  | 'resetPasswordSuccess'
  | 'dashboard'
  | 'adminDashboard'
  | 'manageCompanies'

export enum DashboardViews {
  MY_DASHBOARD = 'My Dashboard',
  MANAGE_COMPANIES = 'Manage Companies',
  MANAGE_RECRUITERS = 'Manage Recruiters',
  MANAGER_DASHBOARD = 'Manager Dashboard',
  VIEW_AS_VIEW = "VIEW_AS_VIEW"
}