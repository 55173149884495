import { withPresenter } from 'app/withPresenter'
import { observer } from 'mobx-react'
import React from 'react'
import styled from 'styled-components'
import { colors } from 'styles/colors'
import { AdminActionsPresenter } from './AdminActionsPresenter'

const Wrapper = styled.div`
  display: flex;
  height: 56px;
  box-sizing: border-box;
  padding: ${({ theme }) => theme.spacing(1, 2)};
  background: ${colors.whiteBg};
  align-items: 'center';
`

const Separator = styled.div`
  flex: 1;
`

const TextWrapper = styled.div`
  flex: 1;
  min-width: 600px;
`

const Title = styled.h3`
  font-size: 1.4em;
  font-weight: 500;
  color: ${colors.darkText};
  margin: 10px 0;
`
interface IProps {
  presenter: AdminActionsPresenter
}

class AdminActions extends React.Component<IProps> {
  public ref = React.createRef<any>()

  public componentDidMount() {
    document.addEventListener('click', this.handleOutsideClick)
  }

  public componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick)
  }

  public handleOutsideClick = e => {
    const inside = this.ref.current.contains(e.target)
    if (!inside) {
      this.props.presenter.closeDropdown()
    }
  }

  render() {
    return (
      <Wrapper>
        <TextWrapper>
          <Title>Admin dashboard</Title>
        </TextWrapper>

        <Separator />

        {/* {buttons.map(({ text, action, icon, modal, disabled }) => {
          return (
            <ButtonWrapper key={text}>
              <Button
                text={text}
                icon={{
                  color: colors.white,
                  name: icon as TIPropsName,
                  size: 10
                }}
                onClick={action}
                disabled={disabled}
                extraStyles={'margin-left : 10px'}
              />
              {modal && modalMap[modal]}
            </ButtonWrapper>
          )
        })} */}
      </Wrapper>
    )
  }
}

export default withPresenter(AdminActionsPresenter)(observer(AdminActions))
