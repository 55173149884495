import AdminDashboardPageComponent from 'app/AdminDashboard/AdminDashboardPageComponent'
import LoginPageComponent from 'app/Authentication/Login/LoginPageComponent'
import RegisterSuccessComponent from 'app/Authentication/RegisterSuccess/RegisterSuccessComponent'
import RegisterVerificationComponent from 'app/Authentication/RegisterVerification/RegisterVerificationComponent'
import ResetPasswordSuccessComponent from 'app/Authentication/ResetPasswordSuccess/ResetPasswordSuccessComponent'
import ResetRequestSuccessComponent from 'app/Authentication/ResetRequestSuccess/ResetRequestSuccessComponent'
import DashboardPageComponent from 'app/Dashboard/DashboardPageComponent'
import ManageCompaniesPageComponent from 'app/ManageComapnies/CompanyList/CompanyListComponent'
import { withPresenter } from 'app/withPresenter'
import { observer } from 'mobx-react'
import * as React from 'react'
import styled from 'styled-components'
import RegisterComponent from '../../Authentication/Register/RegisterComponent'
import ResetPasswordPageComponent from '../../Authentication/ResetPassword/ResetPasswordPageComponent'
import ResetRequestPageComponent from '../../Authentication/ResetRequest/ResetRequestPageComponent'
import { CurrentPagePresenter } from './CurrentPagePresenter'

const pageMap = {
  login: LoginPageComponent,
  dashboard: DashboardPageComponent,
  register: RegisterComponent,
  registerSuccess: RegisterSuccessComponent,
  registerVerification: RegisterVerificationComponent,
  reset: ResetRequestPageComponent,
  resetSuccess: ResetRequestSuccessComponent,
  resetPassword: ResetPasswordPageComponent,
  resetPasswordSuccess: ResetPasswordSuccessComponent,
  adminDashboard: AdminDashboardPageComponent,
  manageCompanies: ManageCompaniesPageComponent
}

const authRequiredPages = ['dashboard', 'adminDashboard', 'manageCompanies']

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
`

interface IProps {
  presenter: CurrentPagePresenter
}

const CurrentPage = observer((props: IProps) => {
  const { page, authenticated } = props.presenter
  const PageComponent = pageMap[page] || null
  // if (!authenticated && authRequiredPages.includes(page)) return null

  return (
    <Wrapper>
      <PageComponent />
    </Wrapper>
  )
})

export default withPresenter(CurrentPagePresenter)(CurrentPage)
