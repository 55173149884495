import * as React from 'react'
import {observer} from 'mobx-react'
import styled from 'styled-components'
import {LoginPagePresenter} from './LoginPagePresenter'
import {Link} from 'app/Shared/Link/Link'
import {Button} from 'app/Shared/Button/Button'
import {TextInput} from 'app/Shared/TextInput/TextInput'
import {withPresenter} from 'app/withPresenter'
import {AuthenticationPageWrapper} from '../AuthenticationPageWrapper/AuthenticationPageWrapper'
import * as logoIcon from 'assets/logoBlue.svg'
import {colors} from "styles/colors";

const Form = styled.form`
  display: flex;
  align-items: center;
  flex-direction: column;
`

const Error = styled.p`
  align-self: center;
  font-weight: normal;
  color: ${colors.errorText};
  margin: 0;
  padding: 4px 0;
`

const CreateAccount = styled.p`
  margin: 10px 0 10px 0;
  padding: 0;
  
  a {
    color: ${colors.lightText};
  }
`

const ForgotPassword = styled.p`
  text-align: right;
  margin: 6px 0 30px 240px;
  
  a {
    color: ${colors.lightText};
  }
`

const Logo = styled.img`
  margin: 20px 0;
  width: 90px;
`

const Title = styled.h1`
  margin-bottom: 20px;
`

interface IProps {
  presenter: LoginPagePresenter
}

const LoginPageComponent = observer((props: IProps) => {
  const {
    email,
    submit,
    password,
    buttonIsDisabled,
    noAccount,
    forgotPassword,
    errors = []
  } = props.presenter

  return (
    <AuthenticationPageWrapper>
      <Logo src={logoIcon} />
      <Title>Sign in to your account</Title>

      {errors.map((message, i) => (
        <Error key={i + message}>{message}</Error>
      ))}

      <Form onSubmit={e => e.preventDefault()}>
        <TextInput input={email} theme="secondary"/>
        <TextInput input={password} theme="secondary"/>

        <ForgotPassword>
          <Link preventDefault={true} {...forgotPassword} to="/reset">
            Forgot password?
          </Link>
        </ForgotPassword>

        <Button
          theme="secondary"
          text="Sign in"
          disabled={buttonIsDisabled}
          onClick={submit}
        />

      </Form>

      <CreateAccount>
        No account? &nbsp;
        <Link preventDefault={true} {...noAccount} to="/register">
          Create Account
        </Link>
      </CreateAccount>
    </AuthenticationPageWrapper>
  )
})

export default withPresenter(LoginPagePresenter)(LoginPageComponent)
