import * as React from 'react'

export const Logout: React.StatelessComponent<{ fill: string }> = props => (
  <svg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg'
       viewBox='0 0 240.56 240.56' fill={props.fill}>
    <defs/>
    <path id='ic_exit_to_app_24px' data-name='ic exit to app 24px' className='cls-1'
          d='M934.76,586.76,953.6,605.6l66.82-66.82L953.6,472,934.76,490.8l34.51,34.61H840v26.73H969.27ZM1053.84,418.5H866.73A26.73,26.73,0,0,0,840,445.23v53.46h26.73V445.23h187.11V632.34H866.73V578.88H840v53.46a26.72,26.72,0,0,0,26.73,26.72h187.11a26.79,26.79,0,0,0,26.72-26.72V445.23A26.8,26.8,0,0,0,1053.84,418.5Z'
          transform='translate(-840 -418.5)'/>
  </svg>
)
