import { computed } from 'mobx'
import { RoutingRepository } from '../RoutingRepository'
import { injectable, inject } from 'inversify'
import 'reflect-metadata'
import { AuthenticationRepository } from 'app/Authentication/AuthenticationRepository'

@injectable()
export class CurrentPagePresenter {
  @inject(RoutingRepository)
  private routingRepository: RoutingRepository

  @inject(AuthenticationRepository)
  private authenticationRepository: AuthenticationRepository

  @computed
  public get authenticated(): boolean {
    return this.authenticationRepository.authenticated
  }

  @computed
  public get page(): string {
    return this.routingRepository.viewId
  }
}
