import { injectable } from 'inversify'
import 'reflect-metadata'

@injectable()
export abstract class AbstractStorageGateway {
  public abstract setItem(
    key: string,
    value: string,
    daysToExpiry?: number
  ): void
  public abstract getItem(key: string): string
  public abstract removeItem(key: string): void
}
