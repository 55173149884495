import { inject, injectable, postConstruct } from "inversify";
import { IRecruiterStatsDTO } from "../DTO";
import { AbstractServiceGateway } from "gateways/service/AbstractServiceGateway";
import { observable, reaction } from "mobx";
import { IBaseResponseDTO } from "gateways/service/stubs/dtos";
import { toast } from "react-toastify";
import { StatusRepository } from "app/StatusBar/StatusRepository";
import { AuthenticationRepository } from "app/Authentication/AuthenticationRepository";
import { TAuthUserRole } from "app/Authentication/TAuthUserRole";
import { DashboardViews } from "app/Routing/TAvailableView";


export type TStatsFilter = 'currentMonth' | 'currentYear' | 'total'
@injectable()
export class RecruiterStatsRepository {
    @inject(AbstractServiceGateway)
    private serviceGateway: AbstractServiceGateway


    @inject(StatusRepository)
    private statusRepository: StatusRepository

    @inject(AuthenticationRepository)
    private authenticationRepository: AuthenticationRepository

    @postConstruct()
    private init() {
        //@ts-ignore
        this.recruiterStatsFilter = this.statusRepository.activeCoinFilter
        reaction(
            () => this.statusRepository.activeCoinFilter,
            async (filter: TStatsFilter) => {
                let { view } = this.authenticationRepository.activeNestedViewContext || {}
                const isCompanyAdmin = this.authenticationRepository.activeTokenContext.role === TAuthUserRole.COMPANY_ADMIN;
                const isNestedViewIsManagerView = view === DashboardViews.MANAGER_DASHBOARD;
                if (isCompanyAdmin || isNestedViewIsManagerView) {
                    this.recruiterStatsFilter = filter;
                    await this.getStats(filter);
                }
            }
        )
    }

    @observable
    public recruiterStatsFilter: TStatsFilter = 'currentMonth'

    @observable
    public recruiterStats: IRecruiterStatsDTO[] = []

    public async getStats(filter = this.recruiterStatsFilter) {
        const result = await this.serviceGateway.get<IBaseResponseDTO<IRecruiterStatsDTO[]>>(`/company-admin/stats/recruiter?filter=${filter}`)
        if (!result.success) return toast.error(result.message)
        this.recruiterStats = result.result;
    }
}