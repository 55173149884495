import * as React from 'react'
import styled from 'styled-components'
import {colors} from 'styles/colors'
import {observer} from 'mobx-react'
import {withPresenter} from 'app/withPresenter'
import {LeaderBoardListPresenter} from "app/Actions/LeaderBoardList/LeaderBoardListPresenter";
import {LeaderBoardTile} from "app/Actions/LeaderBoardList/LeaderBoardTile";
import {ILeaderBoardDto, menuOrderOptions, menuTimeOptions} from "app/Actions/LeaderBoardList/ILeaderBoardDto";
import {Menu} from "app/Shared/Menu/Menu";

const Wrapper = styled.div`
  background-color: ${colors.white};
  min-height: 100%;
  padding: 10px 0 10px 20px;
  box-sizing: border-box;
  grid-column: 2;
  grid-row: 1;
  display: flex;
  flex-direction: column;
  color: ${colors.darkText};
`

const Title = styled.h3`
  font-size: 1.4em;
  font-weight: 500;
  margin-top: 12px;
`

const Content = styled.div`
  max-height: 100vh;
  flex: 1;
`

const List = styled.ul`
  list-style: none;
  padding-left: 0;
`

const Paragraph = styled.p`
  margin: 0 20px 30px 0;
  line-height: 1.5em;
  font-size: 0.9em;
  
  a {
    color: ${colors.lightText};
  }
`

const Filters = styled.div`
  text-transform: uppercase;
  font-size: 0.8em;
  letter-spacing: 2px;
  position: relative;
  width: 100%;
  margin-top: 16px;
  
  div[class^="Menu__MenuWrapper"] {
    position: absolute;
    top: 0;
    right: 20px;
  }
  
  div[class^="Menu__MenuContent"] {
    position: absolute;
    top: 0;
    right: 38px;
  }
`

const Selected = styled.div`
  padding: 3px 0;
`

interface IProps {
  presenter: LeaderBoardListPresenter
}

const LeaderBoardList = observer((props: IProps) => {
  const {
    orderedList,
    selectedOrderValue,
    selectOrder,
    selectTimeFrame,
    selectedTimeFrameValue,
    selectedOrder
  } = props.presenter

  return (
    <Wrapper>
      <Content>
        <Filters>
          <Selected>{ selectedTimeFrameValue }</Selected>
          <Menu
            itemList={menuTimeOptions}
            onClick={selectTimeFrame}
          />
        </Filters>

        <Filters>
          <Selected>{ selectedOrderValue }</Selected>
          <Menu
            itemList={menuOrderOptions}
            onClick={selectOrder}
          />
        </Filters>

        <List>
          {orderedList.map((data: ILeaderBoardDto, index: number) => (
            <LeaderBoardTile
              data={data}
              filter={{ type: selectedOrder }}
              key={index}
            />
          ))}
        </List>
      </Content>
    </Wrapper>
  )
})

export default withPresenter(LeaderBoardListPresenter)(LeaderBoardList)
