import { IAction } from 'app/Actions/IAction'
import { IFormattedAction } from 'app/Actions/IFormattedAction'
import { AuthenticationRepository } from 'app/Authentication/AuthenticationRepository'
import { StepsRepository } from 'app/Steps/StepsRepository'
import { getTextColor } from 'helpers/getTextColor'
import { inject, injectable } from 'inversify'
import { computed, observable } from 'mobx'
import 'reflect-metadata'
import { ActionsRepository } from '../ActionsRepository'

/**
 * @deprecated
 */
@injectable()
export class ActionListPresenter {

  @inject(ActionsRepository)
  private actionsRepository: ActionsRepository

  @inject(AuthenticationRepository)
  private authenticationRepository: AuthenticationRepository

  @inject(StepsRepository)
  private stepsRepository: StepsRepository

  @observable
  public showCompletedActions: boolean = true

  @observable
  public showRewardDialog = false

  @observable
  public additionalCoinsAdded = 0

  @observable
  public showAdditionalCoinsDialog = false

  public rewardDialogCloseHandler = ActionListPresenter.noop

  public toggleShowingOfCompletedAction = () => {
    this.showCompletedActions = !this.showCompletedActions
  }

  /* Candidate based filter logic start */
  @observable
  public activeFilteredCandidateId:string = ""

  @observable
  public isCandidateFilterLocked:boolean = false

  private lastHoveredCandidateId:string

  public lockCandidateFilter(candidateId: string) {
    this.activeFilteredCandidateId = candidateId
    this.isCandidateFilterLocked = true
  }

  public unlockCandidateFilter() {
    this.isCandidateFilterLocked = false
  }

  public candidateHoverFilter(candidateId: string) {
    this.lastHoveredCandidateId = candidateId
    setTimeout(()=> {
      this.applyCandidateHoverFilter(candidateId)
    }, 300)
  }

  private applyCandidateHoverFilter(candidateId: string) {
    if(this.isCandidateFilterLocked) return //Ignore candidate hover filter if filter is locked.
    if(candidateId === this.lastHoveredCandidateId) {
      this.activeFilteredCandidateId = candidateId
    }
  }
  /* Candidate based filter logic end */

  @computed
  public get completedActions(): IFormattedAction[] {
    const list = this.actions.filter(action => action.completed)
    list.sort(ActionListPresenter.compareDate)

    return list
  }

  @computed
  public get uncompletedActions(): IFormattedAction[] {
    return this.actions.filter(action => !action.completed)
  }

  @computed
  private get actions(): IFormattedAction[] {
    return this.actionsRepository.actions.map((action: IAction) => {
      const {
        candidate,
        step,
        actionName,
        actionId,
        completed,
        actionTypeId,
        version_date,
        actionColor,
      } = action
      const { firstName, lastName, candidateColor, candidateId } = candidate
      const backgroundColor = candidateColor
      const dividerColor = step.stepColor
      const textColor = getTextColor(candidateColor)
      const initials = `${firstName.charAt(0)}${lastName.charAt(0)}`
      const title = `(${firstName} ${lastName}) ${actionName}`
      const toggleCompleted = () => this.toggleCompleted(actionId, actionTypeId, completed)

      return {
        candidate: {
          candidateId,
          initials,
          backgroundColor,
          textColor,
          firstName,
          lastName
        },
        dividerColor,
        completed,
        title,
        actionId,
        actionColor,
        actionTypeId,
        actionName,
        version_date,
        isCandidateFiltered: this.isCandidateFilterLocked,
        toggleCompleted
      }
    })
  }

  public hideAdditionalCoinsDialog = () => {
    this.showAdditionalCoinsDialog = false
  }

  public async toggleCompleted(actionId: string, actionTypeId: string, completed: boolean) {
    if (actionTypeId === '10' && !completed) {
      this.rewardDialogCloseHandler = async () => {
        this.showRewardDialog = false
        this.rewardDialogCloseHandler = ActionListPresenter.noop
        const result = await this.actionsRepository.toggleCompleted(actionId)
        this.additionalCoinsAdded = result.result.additionalCoinsAdded
        this.showAdditionalCoinsDialog = true
      }

      this.showRewardDialog = true
      return
    }

    await this.actionsRepository.toggleCompleted(actionId)
    await this.actionsRepository.loadActions(this.authenticationRepository.user.workflowId)
    await this.stepsRepository.loadSteps(
      this.authenticationRepository.authenticated, 
      this.authenticationRepository.user.workflowId
    )
  }

  private static compareDate(a, b) {
    const dateA = a.version_date
    const dateB = b.version_date

    let comparison = 0

    if (dateA >= dateB) {
      comparison = -1
    } else if (dateA < dateB) {
      comparison = 1
    }
    return comparison
  }

  private static noop() {
  }
}
