import { observable, action, reaction } from 'mobx'
import { inject, injectable, postConstruct } from 'inversify'
import 'reflect-metadata'
import { IAction } from 'app/Actions/IAction'
import { AuthenticationRepository } from '../Authentication/AuthenticationRepository'
import { IActionsResponseDto } from 'gateways/service/stubs/dtos'
import { AbstractServiceGateway } from 'gateways/service/AbstractServiceGateway'
import { container } from 'config/IOC'
import { StatusRepository } from 'app/StatusBar/StatusRepository'

@injectable()
export class ActionsRepository {

  @inject(AuthenticationRepository)
  private authenticationRepository: AuthenticationRepository

  @inject(AbstractServiceGateway)
  private serviceGateway: AbstractServiceGateway

  @inject(StatusRepository)
  private coinsRepository: StatusRepository

  @postConstruct()
  public init() {
    this.loadActions(this.authenticationRepository.user.workflowId)
    reaction(
      () => this.authenticationRepository.user,
      user => this.loadActions(user.workflowId)
    )
  }

  @observable
  public actions: IAction[] = []

  @action
  public async loadActions(workflowId: string) {
    if (workflowId) {
      const response = await container.get(AbstractServiceGateway).get<IActionsResponseDto>(`/actions?workflowId=${workflowId}`)
      this.setActions(response.result)
    }
  }

  @action
  public setActions(actions: IAction[]) {
    this.actions = actions
  }

  @action
  public updateActions(actions: IAction[] = []) {
    this.setActions(actions)
  }

  @action
  public async toggleCompleted(actionId: string) {
    const newActions = this.actions.map(action => {
      if (actionId === action.actionId) {
        return { ...action, completed: !action.completed, version_date: new Date().toISOString() }
      } else {
        return action
      }
    })

    this.setActions(newActions)

    const completedAction = this.actions.find(
      searchAction => searchAction.actionId === actionId
    )
    const candidateId = completedAction.candidate.candidateId
    const completed = completedAction.completed

    const result = await this.serviceGateway.post<any>('/action-complete', {
      completed,
      candidateId,
      actionId
    })

    await this.coinsRepository.updateCoinCount()

    return result
  }

  public deleteActionsForCandidate(candidateId: string) {
    const newActions = this.actions.filter(actionToFilter => {
      return actionToFilter.candidate.candidateId !== candidateId
    })
    this.setActions(newActions)
  }
}

