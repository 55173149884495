import { AuthenticationRepository } from 'app/Authentication/AuthenticationRepository'
import { AddCompanyPresenter } from 'app/ManageComapnies/AddCompany/AddCompanyPresenter'
import { RoutingRepository } from 'app/Routing/RoutingRepository'
import { HeaderButton, HeaderButtonTypes } from 'app/Header/IModel'
import { StepsRepository } from 'app/Steps/StepsRepository'
import { container } from 'config/IOC'
import { inject, injectable } from 'inversify'
import { action, computed, observable } from 'mobx'
import 'reflect-metadata'
import { AdminDashboardRepository } from '../AdminDashboardRepository'

@injectable()
export class AdminActionsPresenter {
  @inject(StepsRepository)
  private stepsRepository: StepsRepository

  @inject(RoutingRepository)
  private routing: RoutingRepository

  @inject(AdminDashboardRepository)
  private adminDashboardRepository: AdminDashboardRepository

  @inject(AddCompanyPresenter)
  private addCompanyPresenter: AddCompanyPresenter

  @observable
  public initialSelected = 'Manage Recruiters'

  @observable
  isOpen = false

  @observable
  selectedMenuItem = 'Manage Recruiters'

  @action
  toggleDropdown = () => {
    this.isOpen = !this.isOpen
  };

  @action
  handleOptionClick = (option, route) => {
    this.selectedMenuItem = option
    this.isOpen = false
    this.routing.goForward(route)
  };

  @action
  closeDropdown = () => {
    this.isOpen = false
  }

  @computed
  public get buttons(): HeaderButton[] {
    const roles = container.get(AuthenticationRepository).user.roles

    const disabled =
      this.stepsRepository.addingStep || this.stepsRepository.deletingStep

    return [
      {
        text: HeaderButtonTypes.ADD_COMPANY,
        icon: 'plus',
        action: () => this.addCompanyPresenter.toggle(),
        modal: 'addCompany',
        allowedRoles: ['RECRUITER', 'COMPANY_ADMIN']
      },
      {
        text: 'REFRESH',
        icon: 'plus',
        action: () => this.adminDashboardRepository.fetchAnalytics().then(),
        allowedRoles: ['admin']
      },
      {
        text: 'BACK',
        icon: 'plus',
        action: () => this.routing.goForward('dashboard'),
        disabled,
        modal: 'addCandidate',
        allowedRoles: ['admin'],        
      }
    ]
      .filter(button => roles.filter(role => button.allowedRoles.includes(role)).length > 0)
  }
}
