import {IRequestResetPasswordResDto} from 'app/Authentication/IRequestResetPasswordDto'
import {observable} from 'mobx'
import {AbstractServiceGateway} from 'gateways/service/AbstractServiceGateway'
import {inject, injectable} from 'inversify'
import 'reflect-metadata'
import {IRequestVerificationDto} from "app/Authentication/IRequestVerificationDto";

@injectable()
export class RegisterVerificationRepository {

  @inject(AbstractServiceGateway)
  private serviceGateway: AbstractServiceGateway

  @observable
  public resetRequestRes: any = {success: null, message: null}

  public async verify(token: string) {

    const requestVerificationDto: IRequestVerificationDto = {
      token: token,
    }

    const responseDto = await this.serviceGateway.post<IRequestResetPasswordResDto>(
      '/register-verification',
      requestVerificationDto
    )

    if (responseDto.success) {
      return {
        success: responseDto.success,
        message: responseDto.message
      }
    } else {
      throw new Error(responseDto.message)
    }
  }
}

