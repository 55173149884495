import { inject, injectable } from "inversify";
import { action, observable } from "mobx";
import { ManageRecruiterRepository } from "../ManageRecruiterRepository";
import { LoaderPresenter } from "app/Shared/Loader/LoaderPresenter";
import { toast } from "react-toastify";

@injectable()
export class DeleteRecruiterPresenter {
    @observable
    public isOpen: boolean = false

    @observable
    public recruiterId: number

    @inject(ManageRecruiterRepository)
    private manageRecruiterRepository: ManageRecruiterRepository

    @inject(LoaderPresenter)
    private loaderPresenter: LoaderPresenter

    public toggle = (id?: number) => {
        this.recruiterId = id
        this.isOpen = !this.isOpen
    }

    @action
    public async deleteRecruiter() {
        this.loaderPresenter.showLoader()
        try {
            const result = await this.manageRecruiterRepository.deleteRecruiter({ id: this.recruiterId })
            if (!result.success) toast.error(result.message)
            this.toggle()
        } catch (err) {
            toast.error(err.message)
        }
        this.loaderPresenter.hideLoader()
    }
}