import { inject, injectable } from "inversify";
import { observable } from "mobx";
import 'reflect-metadata'
import { JobsListPresenter } from "./Jobs/JobsListPresenter";

@injectable()
export class RightSidePanelWrapperPresenter {

    @inject(JobsListPresenter)
    private jobsListPresenter: JobsListPresenter

    get jobsCount(): number {
        return this.jobsListPresenter.jobs.length
    }

    @observable
    public showJobsPanel: boolean = true;

    public showJobsTab = () => {
        this.showJobsPanel = true;
    }

    public showLeaderboardTab = () => {
        this.showJobsPanel = false;
    }
}