import { inject, injectable, postConstruct } from 'inversify'
import 'reflect-metadata'
import { reaction, observable, action, computed } from 'mobx'
import { uniq } from 'lodash'
import { componentConfig } from 'config/pageConfig'
import { TAuthUserRole } from 'app/Authentication/TAuthUserRole'
import { TAvailableView } from 'app/Routing/TAvailableView'
import { AuthenticationRepository } from 'app/Authentication/AuthenticationRepository'
import { AbstractWindowGateway } from 'gateways/window/AbstractWindowGateway'
import { container } from 'config/IOC'
import { IViewConfigEntry } from './IViewConfigEntry'

@injectable()
export class RoutingRepository {

  @postConstruct()
  public init() {
    container.get(AuthenticationRepository).recoverSession()
    this.buildAuthorisedLists()
    this.goForward(this.getViewIdFromPathname(this.windowGateway.pathname))

    reaction(
      () => {
        return container.get(AuthenticationRepository).authenticated
      },
      (authenticated: boolean) => {
        this.buildAuthorisedLists()
        if (!authenticated) {
          return this.goForward('login')
        }
        this.goForward('dashboard')
      }
    )

    reaction(
      () => this.windowGateway.pathname,
      pathname => {
        this.goForward(this.getViewIdFromPathname(pathname))
      }
    )
  }

  @inject(AbstractWindowGateway)
  private windowGateway: AbstractWindowGateway

  @computed
  public get auth(): boolean {
    return container.get(AuthenticationRepository).authenticated
  }

  @observable
  public viewId: string = null

  public viewIdListener = (
    viewIdToTest: TAvailableView,
    matchingCallback: () => void,
    nonMatchingCallback: () => void
  ) => {
    this.viewId === viewIdToTest ? matchingCallback() : nonMatchingCallback()

    reaction(
      () => this.viewId,
      (viewId: TAvailableView) =>
        viewId === viewIdToTest ? matchingCallback() : nonMatchingCallback()
    )
  }

  @observable
  public authorisedViews: TAvailableView[] = []

  private getViewIdFromPathname = (pathname: string): TAvailableView => {
    const component = componentConfig.find(el => el.pathname === pathname)
    return component ? component.viewId : 'login' // TODO: 404 page?
  }

  private navigatePath(component: IViewConfigEntry) {
    if (this.windowGateway.pathname !== component.pathname) {
      this.windowGateway.goForward(component.pathname)
    }
  }

  @action
  public goForward = (viewId: TAvailableView): { success: boolean } => {
    const component = componentConfig.find(el => el.viewId === viewId)
    if (viewId == 'login') {
      this.navigatePath(component)
      this.viewId = viewId
      return
    }
    if (this.authorisedViews.indexOf(viewId) > -1) {
      this.navigatePath(component)
      this.viewId = viewId
      return { success: true }
    } else {
      this.goForward('login')
    }
  }

  @action
  public getParams(keysArr: string[]): any {
    const queryParamValues = {}
    keysArr.forEach(
      key =>
        (queryParamValues[key] = this.windowGateway.getQueryParamForKey(key))
    )
    return queryParamValues
  }

  @action
  public goBack = () => {
    this.windowGateway.goBack()
  }

  private buildAuthorisedLists = (): void => {
    let userRoles: TAuthUserRole[] = []
    if (container.get(AuthenticationRepository).authenticated) {
      userRoles = container.get(AuthenticationRepository).user.roles
    }
    const pages = componentConfig
    const authorisedViews: TAvailableView[] = []

    // Set up arrays for non authenticated users
    if (userRoles.length === 0) {
      pages.map(page => {
        if (
          page.allowedRoles.length === 0 ||
          page.allowedRoles.indexOf(TAuthUserRole.RECRUITER) > -1
        ) {
          authorisedViews.push(page.viewId)
        }
      })
    }

    // Set up arrays for authenticated users
    userRoles.map(userRole => {
      pages.map(page => {
        if (page.allowedRoles.indexOf(userRole) > -1) {
          authorisedViews.push(page.viewId)
        }
        return
      })
    })
    this.authorisedViews = uniq(authorisedViews)
  }
}
