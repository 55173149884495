import { inject, injectable, postConstruct } from "inversify";
import { ActionRepository } from "./ActionsRepository";
import { IAction } from "app/Actions/IAction";
import { action, observable, reaction } from "mobx";
import { AuthenticationRepository } from "app/Authentication/AuthenticationRepository";
import _ from "lodash";
import { CandidatesRepository } from "../CandidatesRepository";

@injectable()
export class ActionPresenter {
    @inject(AuthenticationRepository)
    private authenticationRepository: AuthenticationRepository

    @inject(ActionRepository)
    private actionRepository: ActionRepository

    @inject(CandidatesRepository)
    private candidatesRepository: CandidatesRepository


    @postConstruct()
    public init() {
        this.getAllActions()
        reaction(
            () => this.candidatesRepository.reloadActions,
            reloadActions => reloadActions && this.getAllActions()
        )
    }

    @observable
    public actions = observable.map<string, IAction[]>()

    @observable
    public activeCandidateActions: IAction[] = []

    @observable
    public showRewardDialog = false

    @observable
    public showDeleteDialog = false

    @observable
    public candidateToBeRejected

    public rewardDialogCloseHandler = ActionPresenter.noop

    @observable
    public additionalCoinsAdded = 0

    @observable
    public showAdditionalCoinsDialog = false


    @action
    public addToActions(key, value) {
        this.actions.set(key, value)
        this.activeCandidateActions = value
    }


    @action
    public async getActions(candidateId: string, force = false) {
        if (this.actions.size === 0) await this.getAllActions();
        const workflowId = this.authenticationRepository.user.workflowId;
        if (!workflowId) return []
        let cachedActions: IAction[] = this.actions.get(candidateId);
        if (!force && cachedActions) {
            return this.addToActions(candidateId, cachedActions)
        }
        this.addToActions(candidateId, []) // set empty array to avoid duplicate API calls.
        cachedActions = await this.actionRepository.fetchActions(workflowId, candidateId);
        this.addToActions(candidateId, cachedActions)
        return cachedActions;
    }



    @action
    public async getAllActions() {
        const workflowId = this.authenticationRepository.user.workflowId;
        if (!workflowId) return []
        const allActions = await this.actionRepository.fetchAllActions(workflowId);
        this.updateAllActions(allActions);
    }

    public async updateAllActions(allActions: IAction[]) {
        const groupedActions = _.groupBy(allActions, action => action.candidate.candidateId);
        for (const property in groupedActions) {
            this.addToActions(property, groupedActions[property]);
        }
    }

    @action
    public async toggleCompleted(actionId: string, actionTypeId: string, candidateId: string, completed: boolean) {
        if (actionTypeId === '100' && completed) {
            const workflowId = this.authenticationRepository.user.workflowId;
            this.candidateToBeRejected = await this.candidatesRepository
                .findCandidateById(candidateId, workflowId);
            this.showDeleteDialog = true
            return;
        }
        if (actionTypeId === '10' && completed) {
            this.rewardDialogCloseHandler = async () => {
                this.showRewardDialog = false
                this.rewardDialogCloseHandler = ActionPresenter.noop
                const result = await this.actionRepository.toggleCompleted(actionId, candidateId, completed)
                await this.getActions(candidateId, true)
                this.additionalCoinsAdded = result.result.additionalCoinsAdded
                this.showAdditionalCoinsDialog = true
            }

            this.showRewardDialog = true
            return
        }

        await this.actionRepository.toggleCompleted(actionId, candidateId, completed)
        await this.getActions(candidateId, true)
        // await this.stepsRepository.loadSteps(
        //     this.authenticationRepository.authenticated,
        //     this.authenticationRepository.user.workflowId
        // )
    }

    @action
    public async invokeRejectAPI(candidateId: string) {
        await this.candidatesRepository.deleteCandidate(candidateId)
        this.closeDeleteDialog()
    }

    public closeDeleteDialog() {
        this.candidateToBeRejected = null
        this.showDeleteDialog = false
    }

    public getRejectActionOnly(candidateId: string): IAction[] {
        return [{
            actionId: "-1",
            actionColor: "",
            stepId: "",
            actionTypeId: "-1",
            stepColor: "",
            actionName: "Reject Candidate",
            version_date: "",
            completed: false,
            candidate: null,
            step: null
        }]
    }

    public hideAdditionalCoinsDialog = () => {
        this.showAdditionalCoinsDialog = false
    }

    private static noop() {
    }
}