import { AdminDashboardRepository } from 'app/AdminDashboard/AdminDashboardRepository'
import { AuthenticationRepository } from 'app/Authentication/AuthenticationRepository'
import { ITokenContext } from 'app/Authentication/IAuthUser'
import { AddCandidatePresenter } from 'app/Candidates/AddCandidate/AddCandidatePresenter'
import { AddFeedbackPresenter } from 'app/Feedback/AddFeedback/AddFeedbackPresenter'
import { AddCompanyPresenter } from 'app/ManageComapnies/AddCompany/AddCompanyPresenter'
import { AddRecruiterPresenter } from 'app/ManageRecruiters/AddRecruiter/AddRecruiterPresenter'
import { RoutingRepository } from 'app/Routing/RoutingRepository'
import { container } from 'config/IOC'
import { inject, injectable, postConstruct } from 'inversify'
import { action, computed, observable, reaction } from 'mobx'
import moment from 'moment'
import { HiOutlineSpeakerphone } from "react-icons/hi"
import 'reflect-metadata'
import { StepsRepository } from '../Steps/StepsRepository'
import { HeaderButton, HeaderButtonTypes, INestedViewContext } from './IModel'
import { AbstractServiceGateway } from 'gateways/service/AbstractServiceGateway'
import { RecruiterEditCompanyPresenter } from 'app/ManageRecruiters/EditCompany/RecruiterEditCompanyPresenter'
import { CompanyListRepository } from 'app/ManageComapnies/CompanyList/CompanyListRepository'
import { TAuthUserRole } from 'app/Authentication/TAuthUserRole'
import { DashboardViews } from 'app/Routing/TAvailableView'
import { StatusRepository } from 'app/StatusBar/StatusRepository'

@injectable()
export class HeaderPresenter {
  @inject(StepsRepository)
  private stepsRepository: StepsRepository

  @inject(AddCandidatePresenter)
  private addCandidatePresenter: AddCandidatePresenter

  @inject(RoutingRepository)
  private routing: RoutingRepository

  @inject(AdminDashboardRepository)
  private adminRepository: AdminDashboardRepository

  @inject(AddFeedbackPresenter)
  private addFeedbackPresenter: AddFeedbackPresenter

  @inject(AddCompanyPresenter)
  private addCompanyPresenter: AddCompanyPresenter

  @inject(AddRecruiterPresenter)
  private addRecruiterPresenter: AddRecruiterPresenter

  @inject(AuthenticationRepository)
  private authenticationRepository: AuthenticationRepository

  @inject(RecruiterEditCompanyPresenter)
  private recruiterEditCompanyPresenter: RecruiterEditCompanyPresenter

  @inject(CompanyListRepository)
  private companyListRepository: CompanyListRepository

  @inject(AbstractServiceGateway)
  private serviceGateway: AbstractServiceGateway

  @inject(StatusRepository)
  private statusRepository: StatusRepository

  @observable
  public title: string = 'Steps'

  @observable
  public subtitle: string = moment().format('Do dddd')

  @observable
  public tokenContext: ITokenContext

  /**@deprecated Refactor - move it to AuthenticationRepository.activeView */
  @observable
  public mainView: DashboardViews = DashboardViews.MY_DASHBOARD

  @observable
  public nestViewContext: INestedViewContext

  @action
  public setNestedView(context: INestedViewContext) {
    this.nestViewContext = context
    this.serviceGateway.nestedToken = context.token
    this.authenticationRepository.setNestedViewContext(context)
  }

  @action
  public switchToSourceView() {
    this.authenticationRepository.popNestedToken()
    this.nestViewContext = this.authenticationRepository.activeNestedViewContext
    this.serviceGateway.nestedToken = this.authenticationRepository.activeNestedViewContext ? this.authenticationRepository.activeNestedViewContext.token : null
  }

  @observable
  public adminDropdownOptions: string[] = [DashboardViews.MY_DASHBOARD]

  @postConstruct()
  public init() {
    this.tokenContext = this.authenticationRepository.tokenContext
    reaction(
      () => this.authenticationRepository.tokenContext,
      (tokenContext: ITokenContext) => {
        this.tokenContext = tokenContext
        this.buildDropdownOptions()
      }
    )
    this.buildDropdownOptions()
  }

  private buildDropdownOptions() {
    if (!this.tokenContext) return
    let options: string[] = []
    let defaultDashboardView: DashboardViews = DashboardViews.MY_DASHBOARD

    if (this.tokenContext.role === TAuthUserRole.RECRUITER)
      options.push(DashboardViews.MY_DASHBOARD)

    if (this.tokenContext.role == 'SUPER_ADMIN') {
      options.push(DashboardViews.MANAGE_COMPANIES)
      defaultDashboardView = DashboardViews.MANAGE_COMPANIES
    }

    if (this.tokenContext.role == 'COMPANY_ADMIN') {
      options.push(DashboardViews.MANAGER_DASHBOARD)
      options.push(DashboardViews.MANAGE_RECRUITERS)
      options.push(DashboardViews.MY_DASHBOARD)
      defaultDashboardView = DashboardViews.MANAGER_DASHBOARD
    }

    this.adminDropdownOptions = options;
    this.renderUIBasedOnDropdownSelection(defaultDashboardView)
  }

  @action
  public renderUIBasedOnDropdownSelection(view: DashboardViews) {
    this.mainView = view
    this.authenticationRepository.setMainView(view)
    if (view === DashboardViews.MY_DASHBOARD) {
      this.stepsRepository.loadSteps(
        this.authenticationRepository.authenticated,
        this.authenticationRepository.user.workflowId
      )
    }

    if (view === DashboardViews.MANAGER_DASHBOARD) {
      this.statusRepository.updateCoinCount(
        null,
        this.authenticationRepository.tokenContext.companyId
      )
    }

    if (view === DashboardViews.MY_DASHBOARD) {
      this.statusRepository.updateCoinCount(this.authenticationRepository.tokenContext.userId)
    }
  }

  @computed
  public get buttons(): HeaderButton[] {
    const roles = container.get(AuthenticationRepository).user.roles
    const disabled =
      this.stepsRepository.addingStep || this.stepsRepository.deletingStep

    const dynamicButtons: HeaderButton[] = [
      {
        text: HeaderButtonTypes.ADD_COMPANY,
        icon: 'plus',
        action: () => this.addCompanyPresenter.toggle(),
        modal: 'addCompany',
        // hidden: this.activeView !== DashboardViews.MANAGE_COMPANIES,
        allowedView: DashboardViews.MANAGE_COMPANIES,
        allowedRoles: ['SUPER_ADMIN']
      },
      {
        text: HeaderButtonTypes.ADD_RECRUITER,
        icon: 'plus',
        action: () => this.addRecruiterPresenter.toggle(),
        modal: 'addRecruiter',
        // hidden: this.activeView !== DashboardViews.MANAGE_RECRUITERS,
        allowedView: DashboardViews.MANAGE_RECRUITERS,
        allowedRoles: ['SUPER_ADMIN', 'COMPANY_ADMIN']
      },
      {
        text: HeaderButtonTypes.EDIT_COMPANY,
        icon: 'plus',
        action: () => this.recruiterEditCompanyPresenter.toggle(),
        modal: 'addRecruiter',
        // hidden: this.activeView !== DashboardViews.MANAGE_RECRUITERS,
        allowedView: DashboardViews.MANAGE_RECRUITERS,
        allowedRoles: ['COMPANY_ADMIN']
      },
      {
        text: HeaderButtonTypes.ADD_CANDIDATE,
        icon: 'plus',
        animate: this.isAnimationRequired(HeaderButtonTypes.ADD_CANDIDATE),
        action: () => this.addCandidatePresenter.toggle(),
        // hidden: this.activeView !== DashboardViews.STEPS_DASHBOARD,
        modal: 'addCandidate',
        allowedView: DashboardViews.MY_DASHBOARD,
        allowedRoles: ['RECRUITER', 'COMPANY_ADMIN', 'SUPER_ADMIN']
      },
      {
        text: HeaderButtonTypes.ADD_STEP,
        icon: 'plus',
        action: () => this.stepsRepository.addStep(),
        disabled,
        // hidden: this.activeView !== DashboardViews.STEPS_DASHBOARD,
        allowedView: DashboardViews.MY_DASHBOARD,
        allowedRoles: ['RECRUITER', 'COMPANY_ADMIN', 'SUPER_ADMIN']
      },
      {
        text: HeaderButtonTypes.ANALYTICS,
        icon: 'plus',
        action: () => this.routing.goForward('adminDashboard'),
        // hidden: this.activeView !== DashboardViews.STEPS_DASHBOARD,
        allowedView: DashboardViews.MANAGE_COMPANIES,
        allowedRoles: ['SUPER_ADMIN']
      },
      {
        text: HeaderButtonTypes.REPORT,
        icon: 'plus',
        action: () => this.adminRepository.downloadReport().then(),
        // hidden: this.activeView !== DashboardViews.STEPS_DASHBOARD,
        allowedView: DashboardViews.MANAGE_COMPANIES,
        allowedRoles: ['SUPER_ADMIN']
      },
      {
        text: 'Give us feedback',
        content: HiOutlineSpeakerphone,
        action: () => this.addFeedbackPresenter.toggle(),
        modal: 'feedback',
        // hidden: this.activeView !== DashboardViews.STEPS_DASHBOARD,
        allowedRoles: ['RECRUITER', 'COMPANY_ADMIN', 'SUPER_ADMIN'],
        allowedView: DashboardViews.MY_DASHBOARD,
        className: "feedback"
      }
    ]

    if (this.nestViewContext) {
      const mainUserRole = this.tokenContext.role;
      let filtered = dynamicButtons.filter(
        button =>
          button.allowedView === this.nestViewContext.view &&
          button.allowedRoles.includes(mainUserRole)
      );
      if (this.nestViewContext.view === DashboardViews.MY_DASHBOARD) filtered = []
      // filtered.unshift({
      //   text: HeaderButtonTypes.RETURN,
      //   icon: 'plus',
      //   action: () => this.switchToSourceView(),
      //   allowedView: DashboardViews.MANAGE_COMPANIES,
      //   allowedRoles: ['SUPER_ADMIN', 'COMPANY_ADMIN']
      // })
      return filtered
    }
    return dynamicButtons.filter(button => button.allowedView === this.mainView)
  }

  public isAnimationRequired(headerButton: HeaderButtonTypes): boolean {
    if (headerButton === HeaderButtonTypes.ADD_CANDIDATE) {
      let count = this.stepsRepository.totalCandidates;
      return count === 0;
    }
    return false;
  }

  @action
  public async applyFilter() {
    this.companyListRepository.loadCompany()
  }

  get activeCompanyName() {
    return this.companyListRepository.filterCompanyName;
  }

  @action
  public companyName(value) {
    this.companyListRepository.updateCompanyFilter(value);
  }
}
