import { inject, injectable } from 'inversify'
import 'reflect-metadata'
import { container } from 'config/IOC'
import { StepsPresenter } from 'app/Steps/StepsPanel/StepsPresenter'
import {
  validated,
  MUST_BE_NUMBER
} from '@logicroom/validator'
import { ITextInput } from 'app/Shared/TextInput/ITextInput'
import { computed, observable } from 'mobx'
import { DealRepository } from 'app/Deal/DealRepository'
// @ts-ignore
import * as placementFullScreenRocketAnimation from 'assets/animations/placementFullScreenRocket.json'
import { FullScreenAnimationPresenter } from 'app/Shared/FullScreen/FullScreenAnimationPresenter'
import { JobsRepository } from 'app/RightSidePanel/Jobs/JobsRepository'
import { toast } from 'react-toastify'

@injectable()
export class DealPresenter {

  @inject(DealRepository)
  private dealRepository: DealRepository

  @inject(FullScreenAnimationPresenter)
  private fullScreenAnimation: FullScreenAnimationPresenter

  @inject(JobsRepository)
  private jobsRepository: JobsRepository

  public close = async () => {
    this.placementFee.value = ''
    const stepsPresenter = container.get(StepsPresenter)
    stepsPresenter.closeStep()
  }

  @validated({
    mandatory: true,
    rules: [
      {
        rule: MUST_BE_NUMBER,
        errorMessage: 'Must enter a number'
      }
    ]
  })
  public placementFee: ITextInput = {
    placeholder: '0',
    label: '0'
  }
  public candidateId: string
  public type: string

  @computed
  public get updatePlacementFee() {
    return { ...this.placementFee, onChange: this.placementFee.onChange }
  }

  @observable
  public formattedCandidateName: string

  @computed
  public get canSubmit(): boolean {
    return (
      this.placementFee.isValid
    )
  }

  private handlePlacementFullScreenAnimation() {
    if (this.type === 'Confirm') {
      this.fullScreenAnimation.playAnimation({
        animationData: placementFullScreenRocketAnimation,
        timeout: 6001
      })
    }
  }

  public submit = async () => {
    if (this.canSubmit) {
      try {
        await this.dealRepository.createDeal(this.candidateId, this.placementFee.value, this.type)
        await this.jobsRepository.loadJobs();
        this.placementFee.onChange('')
        this.handlePlacementFullScreenAnimation()
      } catch (ex) {
        toast.error(ex.message)
      }
    }
  }


}
