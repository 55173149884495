import { computed } from 'mobx'
import { inject, injectable } from 'inversify'
import 'reflect-metadata'
import { StatusRepository } from 'app/StatusBar/StatusRepository'
import { CandidatesRepository } from 'app/Candidates/CandidatesRepository'
import { TCoinFilter } from './IStatusDto'

@injectable()
export class StatusPresenter {

  @inject(StatusRepository)
  private statusRepository: StatusRepository

  @inject(CandidatesRepository)
  private candidatesRepository: CandidatesRepository

  @computed
  public get coinIsStopped() {
    return this.statusRepository.coinFlag === 'hide'
  }

  @computed
  public get cvsIsStopped() {
    return this.statusRepository.cvsFlag === 'hide'
  }

  @computed
  public get moneyIsStopped() {
    return this.statusRepository.moneyFlag === 'hide'
  }

  @computed
  public get isCVPlacedStopped() {
    return this.statusRepository.cvPlacedFlag === 'hide'
  }

  @computed
  public get isJobsStopped() {
    return this.statusRepository.jobsFlag === 'hide'
  }

  @computed
  public get mtdCoinCount() {
    return this.statusRepository.mtdCoinCount
  }

  @computed
  public get ytdCoinCount() {
    return this.statusRepository.ytdCoinCount
  }

  @computed
  public get coinCount() {
    return this.statusRepository.coinCount
  }

  @computed
  public get cvSubmits() {
    return this.statusRepository.cvSubmits
  }

  @computed
  public get cvPlaced() {
    return this.statusRepository.cvPlaced
  }

  @computed
  public get jobs() {
    return this.statusRepository.jobs
  }

  @computed
  public get cumulativeDealFee() {
    return this.statusRepository.cumulativeDealFee
  }

  @computed
  public get showRainfallAnimation() {
    return this.statusRepository.showRainFallAnimation
  }

  public placementStatus = async () => {
    return this.statusRepository.getPlacementStatus()
  }

  public applyCoinFilter(filter: TCoinFilter) {
    this.statusRepository.applyCoinFilter(filter)
  }

  get activeFilter(): TCoinFilter {
    return this.statusRepository.activeCoinFilter;
  }
}
