import * as React from 'react'
import { observer } from 'mobx-react'
import { withPresenter } from 'app/withPresenter'
import {
  ResetPasswordPagePresenter
} from './ResetPasswordPagePresenter'
import { Button } from 'app/Shared/Button/Button'
import { TextInput } from 'app/Shared/TextInput/TextInput'
import { AuthenticationPageWrapper } from '../AuthenticationPageWrapper/AuthenticationPageWrapper'
import styled from 'styled-components'
import {colors} from "styles/colors";
import * as logoIcon from 'assets/logoBlue.svg'

const Form = styled.form`
  display: flex;
  align-items: center;
  flex-direction: column;
  
  button {
    margin-top: 30px;
  }
`

const Error = styled.p`
  align-self: center;
  font-weight: normal;
  color: ${colors.errorText};
  margin: 0;
  padding: 4px 0;
`

const Logo = styled.img`
  margin: 20px 0;
  width: 90px;
`

const Title = styled.h1`
  margin-bottom: 20px;
`

interface IProps {
  presenter: ResetPasswordPagePresenter
}

const ResetPasswordPageComponent = observer((props: IProps) => {
  const {
    password,
    passwordConfirm,
    buttonIsDisabled,
    submit,
    errors
  } = props.presenter
  return (
    <AuthenticationPageWrapper>
      <Logo src={logoIcon} />
      <Title>Create your new password</Title>

      {errors.map((message, i) => (
        <Error key={i + message}>{message}</Error>
      ))}

      <Form onSubmit={e => e.preventDefault()}>
        <TextInput input={password} theme={'secondary'} />
        <TextInput input={passwordConfirm} theme={'secondary'} />

        <Button
          theme={'secondary'}
          text="Submit"
          disabled={buttonIsDisabled}
          onClick={submit}
        />
      </Form>
    </AuthenticationPageWrapper>
  )
})

export default withPresenter(ResetPasswordPagePresenter)(
  ResetPasswordPageComponent
)
