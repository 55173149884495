import * as React from 'react'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import { withPresenter } from 'app/withPresenter'
import StatusComponent from 'app/StatusBar/StatusComponent'
import { colors } from 'styles/colors'
import * as logoName from 'assets/logo_name.png'
import * as logoIcon from 'assets/logo.svg'
import LogoutComponent from 'app/Authentication/Logout/LogoutComponent'
import Avatar from 'app/Dashboard/UserAvatar'
import { AdminDashboardPresenter } from './AdminDashboardPresenter'
import { ACTION_WIDTH } from 'config/constants'
import AdminActionsComponent from './AdminActions/AdminActionsComponent'

const Header = styled.div`
  display: flex;
  background-color: ${colors.mainBg};
  height: 80px;
  flex-shrink: 0;
`

const LogoIcon = styled.img`
  margin: 24px 10px 24px 30px;
  width: 50px;
`

const LogoName = styled.img`
  margin: 8px 0 0;
  height: 60px;
  width: 128px;
`
const Spacer = styled.div`
  flex: 1;
`

const UserWrapper = styled.div`
  margin: 2px 0;
  width: ${ACTION_WIDTH - 40}px;
  display: flex;
  border-left: 1px solid #d9e1e2;
  `

const UserSpacer = styled.div`
  padding-left: 13.5px;
`
const UserDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const UserName = styled.span`
  font-size: 1em;
  color: ${colors.whiteText};

  span {
    padding-left: 6px;
    font-size: 0.6em;
  }
`

const Date = styled.div`
  color: ${colors.whiteText};
  position: relative;
  top: 5px;
  font-size: 0.7em;
`
const LogoutWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;

  button {
    width: 30px;
    margin: 21px 0 0px 34px;

    span {
      padding: 0;
    }
  }
`

const Wrapper = styled.div`
  min-height: calc(100vh - 96px);
  max-width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background: ${colors.dashboardBg};
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto auto;
  column-gap: 32px;
  row-gap: 32px;
  padding: 64px;
`

const Section = styled.div`
  width: 100%;
  max-width: 500px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, .2);
  background: ${colors.whiteBg};
  border-radius: 4px;

  &:nth-child(odd) {
    justify-self: end;
  }

  &:nth-child(even) {
    justify-self: start;
  }
`

const SectionHeader = styled.div`
  background: ${colors.buttonBg};
  color: ${colors.whiteText};
  padding: 12px 24px;
  border-radius: 4px 4px 0 0;
`

const SectionBody = styled.div`
  background: ${colors.whiteBg};
  padding: 16px 20px;
  border-radius: 0 0 4px 4px;
`

const AnalyticsLine = styled.p`
  background: ${colors.infoLineBg};
  margin: 0 0 8px 0;
  padding: 16px;

  &:last-child {
    margin-bottom: 0;
  }
`

const LineLabel = styled.span`
  display: inline-block;
  width: 35%;
  font-weight: bold;
  font-size: 14px;
`

const LineValue = styled.span`
`

const ordinalIndicatedNumberMatch = new RegExp(/([0-9]{1,2})(rd|nd|th|st)/)
const ordinalIndicatorMatch = new RegExp(/^(rd|nd|th|st)$/)

const OrdinalIndicator = styled.span`
  font-size: 0.6rem;
`

const currencyFormatter = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' })
const numberFormatter = new Intl.NumberFormat('en-GB', { style: 'decimal' })

const ratioFormatter = (list: number[]): string => list.map(item => numberFormatter.format(item)).join(' : ')

interface IProps {
  presenter: AdminDashboardPresenter
}

const AdminDashboardPageComponent = observer((props: IProps) => {
  const { formattedDate, userName, badges, userId, analytics, level } = props.presenter

  const splitDate = formattedDate
    .split(ordinalIndicatedNumberMatch)
    .map((substring: string) => {
      return ordinalIndicatorMatch.test(substring) ? (
        <OrdinalIndicator key={substring}>{substring}</OrdinalIndicator>
      ) : (
          <span key={substring}>{substring}</span>
        )
    })

    const onFetchBadgesHistoryClick = () => {
      return props.presenter.getUserBadgesHistory()
    }

  return (
    <>
      <Header>
        <LogoIcon src={logoIcon} />
        <LogoName src={logoName} />
        <Spacer />
        <StatusComponent />
        <UserWrapper >
          <UserSpacer/>
          <Avatar {...badges} level={level} showBadgesHistory={true} fetchBadgesHistory={onFetchBadgesHistoryClick}/>

          <UserDetails>
            <UserName>
              {userName}
              <span>({userId})</span>
            </UserName>
            <Date>{splitDate}</Date>
          </UserDetails>
        </UserWrapper>
        <LogoutWrapper>
            <LogoutComponent/>
        </LogoutWrapper>
      </Header>

      <Wrapper>
        <AdminActionsComponent />
        <Grid>
          <Section>
            <SectionHeader>RATIOS</SectionHeader>
            <SectionBody>
              <AnalyticsLine>
                <LineLabel>CVs to Interview</LineLabel>
                <LineValue>{ratioFormatter(analytics.ratio.cvToInterview)}</LineValue>
              </AnalyticsLine>

              <AnalyticsLine>
                <LineLabel>CVs to Placement</LineLabel>
                <LineValue>{ratioFormatter(analytics.ratio.cvToPlacement)}</LineValue>
              </AnalyticsLine>

              <AnalyticsLine>
                <LineLabel>Offers to Placement</LineLabel>
                <LineValue>{ratioFormatter(analytics.ratio.offerToPlacement)}</LineValue>
              </AnalyticsLine>
            </SectionBody>
          </Section>

          <Section>
            <SectionHeader>AVERAGE PLACEMENT FEE</SectionHeader>
            <SectionBody>
              <AnalyticsLine>{currencyFormatter.format(analytics.average.placementFee)}</AnalyticsLine>
            </SectionBody>
          </Section>

          <Section>
            <SectionHeader>TOTALS</SectionHeader>
            <SectionBody>
              <AnalyticsLine>
                <LineLabel>CV Submitted</LineLabel>
                <LineValue>{numberFormatter.format(analytics.total.cvSubmitted)}</LineValue>
              </AnalyticsLine>

              <AnalyticsLine>
                <LineLabel>Placement Fee</LineLabel>
                <LineValue>{currencyFormatter.format(analytics.total.placementFee)}</LineValue>
              </AnalyticsLine>

              <AnalyticsLine>
                <LineLabel>Coins Accrued</LineLabel>
                <LineValue>{numberFormatter.format(analytics.total.coinsAccrued)}</LineValue>
              </AnalyticsLine>
            </SectionBody>
          </Section>

          <Section>
            <SectionHeader>AVERAGE TIME TO FEEDBACK</SectionHeader>
            <SectionBody>
              {analytics.average.timetoFeedback
                ? <AnalyticsLine>{Math.floor(analytics.average.timetoFeedback/1200)}D {Math.floor((analytics.average.timetoFeedback % 1200)/60)}H {Math.floor(analytics.average.timetoFeedback % 60)}m</AnalyticsLine>
                : <AnalyticsLine>N/A</AnalyticsLine>}
            </SectionBody>
          </Section>
        </Grid>
      </Wrapper>
    </>
  )
})

export default withPresenter(AdminDashboardPresenter)(AdminDashboardPageComponent)
