import * as React from 'react'
import styled from 'styled-components'
import { colors } from 'styles/colors'
import { Icon } from 'app/Shared/Icon/Icon'

const Wrapper = styled.div`
  background-color: rgba(0, 0, 0, .5);
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-shrink: 0;
  flex-direction: row-reverse;
  align-items: flex-start;
  border-radius: 30px !important;
  position: absolute;
  padding-right: 10px;
  bottom: auto;
`

const Button = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
`

const ButtonWrapper = styled.div`
  flex: 1;  
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const Confirm = styled.button`
  border: none;
  color: ${colors.white};
  font-weight: bold;
  background-color: transparent;
  cursor: pointer;
  outline: none;
`

const Cancel = styled.button`
  border: none;
  color: ${colors.white};
  background-color: transparent;
  cursor: pointer;
  outline: none;
`

const Text = styled.p`
  color: ${colors.white};
  flex: 1;
  text-align: center;
  font-size: 14px;
  line-height: 16px;
  
  &:nth-child(1) {
    margin: -10px;
  }
`

const ConfirmWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-gap: 5px;
  width: 100%;
  margin: 50px;
  align-items: center;
`

interface IProps {
  onRequestClose: () => void
  color: string
  deleteStep: () => void
  confirmDeletion: (confirmed: boolean) => void
  deletionConfirmationIsOpen: boolean
}

export const StepMenu: React.FunctionComponent<IProps> = props => {
  const {
    onRequestClose,
    color,
    deleteStep,
    confirmDeletion,
    deletionConfirmationIsOpen
  } = props
  return (
    <Wrapper color={color}>
      {deletionConfirmationIsOpen ? (
        <ConfirmWrapper>
          <ButtonWrapper>
            <Cancel onClick={() => confirmDeletion(false)}>No</Cancel>
            <Confirm onClick={() => confirmDeletion(true)}>Yes</Confirm>
          </ButtonWrapper>
          <Text>
            <b>Are you sure?</b>
          </Text>
          <Text>
            This will delete this step,
            <br />
            associated actions and
            <br /> all candidates in it.
          </Text>
        </ConfirmWrapper>
      ) : (
        <>
          <Button onClick={onRequestClose}>
            <Icon name="elipsis" color={colors.white} />
          </Button>
          <Button onClick={deleteStep} style={{paddingTop: "5px"}}>
            <Icon name="bin" size={20} color={colors.white} />
          </Button>
        </>
      )}
    </Wrapper>
  )
}
