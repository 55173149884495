import { Dropdown } from 'app/Shared/Dropdown/Dropdown'
import { Modal } from 'app/Shared/Modal/Modal'
import { TextInput } from 'app/Shared/TextInput/TextInput'
import { withPresenter } from 'app/withPresenter'
import React from 'react'
import { observer } from 'mobx-react'
import { RecruiterEditCompanyPresenter } from './RecruiterEditCompanyPresenter'
import {
  CaptionText,
  InputWrapper,
  Label,
  OptionWrapper,
  Wrapper
} from 'app/ManageComapnies/styles'

interface IProps {
  presenter: RecruiterEditCompanyPresenter
}

@observer
class RecruiterEditCompany extends React.Component<IProps> {
  render(): React.ReactNode {
    const {
      isOpen,
      dropdownOptions,
      updatedCompanyName,
      canSubmit
    } = this.props.presenter
    if (!isOpen) return <div />
    return (
      <Modal
        closeModal={() => this.props.presenter.toggle()}
        title="Edit Company"
        showHeader
        onSaveClick={() => this.props.presenter.handleOnSaveClick()}
        saveButtonDisabled={!canSubmit}
      >
        <Wrapper>
          <CaptionText>Edit company details below.</CaptionText>
          <InputWrapper>
            <TextInput
              input={{
                value: updatedCompanyName,
                label: 'Company Name',
                onChange: value => {
                  this.props.presenter.updateCompanyName(value)
                }
              }}
              theme="secondary"
              showAsterisk={true}
            />
          </InputWrapper>
          <OptionWrapper>
            <Label>Change Admin</Label>
            <Dropdown
              options={dropdownOptions}
              onSelect={select =>
                this.props.presenter.handleOnDropdownUserSelection(select)
              }
            />
          </OptionWrapper>
        </Wrapper>
      </Modal>
    )
  }
}

export default withPresenter(RecruiterEditCompanyPresenter)(
  RecruiterEditCompany
)
