import { observer } from 'mobx-react'
import * as React from 'react'
import Lottie from 'react-lottie'
import styled from 'styled-components'

import { ICandidate } from 'app/Candidates/ICandidate'
import { StatusPresenter } from 'app/StatusBar/StatusPresenter'
import { withPresenter } from 'app/withPresenter'
import { colors } from 'styles/colors'
// @ts-ignore
import * as coinAnimation from 'assets/animations/coin.json'
// @ts-ignore
import * as cvsAnimation from 'assets/animations/cvs.json'
// @ts-ignore
import * as moneyAnimation from 'assets/animations/deal.json'
// @ts-ignore
import * as jobAnimation from 'assets/animations/job.json'

// @ts-ignore
import * as rainfallMoneyAnimation from 'assets/animations/rainfallMoney.json'
// @ts-ignore
import * as rainfallCulabrCoinsAnimation from 'assets/animations/rainfallCulabrCoins.json'
// @ts-ignore
import * as rainfallCVAnimation from 'assets/animations/rainfallCV.json'
// @ts-ignore
import * as rainfallJobs from 'assets/animations/rainfallJobs.json'

import { Badge } from 'app/Shared/Badge/Badge'
import { HistoryDialog } from 'app/StatusBar/HistoryDialog/HistoryDialog'
import { IconWrapper } from 'styles'

interface IProps {
  presenter: StatusPresenter
}

const Container = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(4)};
  align-items: center;
  background-color: ${colors.mainBg};
`

const Wrapper = styled.div`
  //flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 67px;
  gap: 4px;
  /* padding: 12px 18px 12px 8px;
  justify-content: center;
  border-left: 1px solid #d9e1e2;
  min-width: 150px;
  max-width: 200px; */
`

const Divider = styled.div`
  height: calc(100% - 20px);
  width: 1px;
  background-color: #74cfdb;
`

const Text = styled.div`
  color: ${colors.whiteText};
  font-size: 1.375em;
  line-height: 1.455;
`

const SubScript = styled.small`
  font-size: 0.6em;
  text-transform: uppercase;
  opacity: 0.8;
  color: ${colors.whiteText};
  line-height: 12px;
`

const StyledSelect = styled.select(
  ({ theme }) => `
  position: relative;
  height: 40px;
  width: 110px;
  border: 2px solid ${colors.whiteText};
  padding: ${theme.spacing(0, 1)};
  border-radius: ${theme.spacing(1)};
  color: ${colors.whiteText};
  text-transform: capitalize;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: ${colors.mainBg};
  background-image: url("data:image/svg+xml;utf8,<svg fill='%23ffffff' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 97%;
  background-position-y: 5px;

  &:focus {
    outline: none;
  }
`
)

const StyledOption = styled.option`
  color: ${colors.whiteText};
  background: ${colors.mainBg} !important;
`

interface IState {
  recordDialogOpen: boolean
  dropdownLoading: boolean
  candidates: ICandidate[]
}

class StatusComponentImpl extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      recordDialogOpen: false,
      dropdownLoading: false,
      candidates: []
    }
  }

  private openDialog() {
    this.setState({ recordDialogOpen: true, dropdownLoading: true })

    this.props.presenter.placementStatus().then(candidates => {
      this.setState({ dropdownLoading: false, candidates })
    })
  }

  private closeDialog() {
    this.setState({ recordDialogOpen: false })
  }

  public render() {
    const {
      cumulativeDealFee,
      cvSubmits,
      cvPlaced,
      jobs,
      coinCount,
      coinIsStopped,
      cvsIsStopped,
      activeFilter,
      isCVPlacedStopped,
      isJobsStopped,
      moneyIsStopped,
      showRainfallAnimation
    } = this.props.presenter

    const delimittedCumulativeDealFee = parseInt(cumulativeDealFee, 10)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    const iconSize = 32

    const defaultOptions = {
      loop: false,
      autoplay: false
    }

    const rainfallAnimationStyles = {
      margin: '0',
      zIndex: 100,
      position: 'absolute',
      width: '100%',
      height: '100%'
    }

    const rainfallAnimationOptions = {
      autoplay: true,
      loop: false
    }

    const onCoinFilterChange = e => {
      this.props.presenter.applyCoinFilter(e.target.value)
    }

    return (
      <Container>
        {this.state.recordDialogOpen ? (
          <HistoryDialog
            records={this.state.candidates}
            onClose={() => this.closeDialog()}
            loading={this.state.dropdownLoading}
          />
        ) : null}

        <Wrapper>
          {' '}
          {/* Filter don't need border */}
          <StyledSelect onChange={onCoinFilterChange} value={activeFilter}>
              <StyledOption value="currentMonth">Monthly</StyledOption>
            <StyledOption value="currentYear">Annual</StyledOption>
            <StyledOption value="total">Total</StyledOption>
          </StyledSelect>
        </Wrapper>

        <Wrapper style={{ minWidth: '86px', alignItems: 'flex-start' }}>
          {showRainfallAnimation && !moneyIsStopped && (
            <Lottie
              options={{
                ...rainfallAnimationOptions,
                animationData: rainfallMoneyAnimation.default
              }}
              style={rainfallAnimationStyles}
            />
          )}
          <Text>
            <span
              style={{
                fontSize: '.55em',
                marginRight: '2px',
                verticalAlign: 'middle'
              }}
            >
              £
            </span>
            {delimittedCumulativeDealFee}
          </Text>
          <SubScript>billings</SubScript>
        </Wrapper>

        <Wrapper onClick={() => this.openDialog()}>
          {showRainfallAnimation && !moneyIsStopped && (
            <Lottie
              options={{
                ...rainfallAnimationOptions,
                animationData: rainfallMoneyAnimation.default
              }}
              style={rainfallAnimationStyles}
            />
          )}
          <IconWrapper>
            <Lottie
              options={{
                ...defaultOptions,
                animationData: moneyAnimation.default
              }}
              height={iconSize}
              width={iconSize}
              style={{ margin: '0' }}
              isStopped={moneyIsStopped}
            />
            <Badge badgeContent={cvPlaced} hasBorder={true} />
          </IconWrapper>
          <SubScript>placements</SubScript>
          {/* <TextWrapper>
            <Text><span style={{ fontSize: '0.6em', marginRight: '4px' }}>£</span>{delimittedCumulativeDealFee}</Text>
          </TextWrapper> */}
        </Wrapper>

        <Wrapper>
          {showRainfallAnimation && !isJobsStopped && (
            <Lottie
              options={{
                ...rainfallAnimationOptions,
                animationData: rainfallJobs.default
              }}
              style={rainfallAnimationStyles}
            />
          )}
          <IconWrapper>
            <Lottie
              options={{
                ...defaultOptions,
                animationData: jobAnimation.default
              }}
              height={iconSize}
              width={iconSize}
              style={{ margin: '0' }}
              isStopped={isJobsStopped}
            />
            <Badge badgeContent={jobs} hasBorder={true} />
          </IconWrapper>
          <SubScript>jobs</SubScript>
        </Wrapper>

        <Wrapper>
          {showRainfallAnimation && !cvsIsStopped && (
            <Lottie
              options={{
                ...rainfallAnimationOptions,
                animationData: rainfallCVAnimation.default
              }}
              style={rainfallAnimationStyles}
            />
          )}
          <IconWrapper>
            <Lottie
              options={{
                ...defaultOptions,
                animationData: cvsAnimation.default
              }}
              height={iconSize}
              width={iconSize}
              style={{ margin: '0' }}
              isStopped={cvsIsStopped}
            />
            <Badge badgeContent={cvSubmits} hasBorder={true} />
          </IconWrapper>
          <SubScript>
            CV<span style={{ textTransform: 'lowercase' }}>s</span>
          </SubScript>
        </Wrapper>

        <Wrapper>
          {showRainfallAnimation && !coinIsStopped && (
            <Lottie
              options={{
                ...rainfallAnimationOptions,
                animationData: rainfallCulabrCoinsAnimation.default
              }}
              style={rainfallAnimationStyles}
            />
          )}
          <IconWrapper>
            <Lottie
              options={{
                ...defaultOptions,
                animationData: coinAnimation.default
              }}
              height={iconSize}
              width={iconSize}
              style={{ margin: '0' }}
              isStopped={coinIsStopped}
            />
            <Badge badgeContent={coinCount} hasBorder={true} />
          </IconWrapper>
          <SubScript>coins</SubScript>
        </Wrapper>

        <Divider />
      </Container>
    )
  }
}

export default withPresenter(StatusPresenter)(observer(StatusComponentImpl))
