import 'reflect-metadata'
import { observable, action, reaction } from 'mobx'
import { inject, injectable, postConstruct } from 'inversify'
import { AbstractServiceGateway } from 'gateways/service/AbstractServiceGateway'
import { IAnalytics } from 'gateways/service/stubs/dtos'
import { AuthenticationRepository } from 'app/Authentication/AuthenticationRepository'

@injectable()
export class AdminDashboardRepository {

  @inject(AbstractServiceGateway)
  private serviceGateway: AbstractServiceGateway

  @inject(AuthenticationRepository)
  private authenticationRepository: AuthenticationRepository

  @observable
  public analytics: IAnalytics = {
    success: true,
    message: '',

    ratio: {
      cvToInterview: [],
      cvToPlacement: [],
      offerToPlacement: []
    },

    average: {
      placementFee: 0,
      timetoFeedback: 0
    },

    total: {
      coinsAccrued: 0,
      cvSubmitted: 0,
      placementFee: 0
    }
  }

  @postConstruct()
  public init() {
    reaction(
      () => this.authenticationRepository.tokenContext,
      async (tokenContext) => {
        if (tokenContext) await this.fetchAnalytics()
      }
    )
  }

  @action
  public async fetchAnalytics() {
    this.analytics = await this.serviceGateway.get<IAnalytics>(`/analytics`)
  }

  @action
  public async downloadReport() {
    await this.serviceGateway.download('GET', '/report')
  }
}
