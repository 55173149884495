import * as React from 'react'
import { Modal } from 'app/Shared/Modal/Modal'
import styled from 'styled-components'
import { colors } from 'styles/colors'
import { Button } from 'app/Shared/Button/Button'

const Wrapper = styled.div`
  color: ${colors.darkText};
  min-width: 400px;

  button {
    margin: 24px auto;
  }
`

const ButtonWrapper = styled.div`
  display: flex;

  button {
    width: 180px;
  }
`

const Record = styled.tr`
  td {
    padding: 8px;
  }
`

const Name = styled.td``

const Amount = styled.td`
  text-align: right;
`

const DateTime = styled.td``

const ContentWrapper = styled.div`
  margin-top: 8px;
  max-height: 400px;
  overflow: auto;
`

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

interface IProps {
  loading: boolean
  records: any[]

  onClose: () => void
}

export class HistoryDialog extends React.Component<IProps> {
  private formatDate(record: any) {
    const date = new Date(record.placedDate)
    return `${months[date.getMonth()]} ${date.getFullYear()}`
  }

  private formatAmount(record) {
    if (!record.placedAmount) return 'N/A'
    return `£${record.placedAmount.toFixed(2)}`
  }

  private emptyList() {
    if (this.props.records.length === 0) {
      return <p>No data available</p>
    }
  }

  private dataTable() {
    if (this.props.records.length > 0) {
      return (
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Placed amount</th>
              <th>Placement Date</th>
            </tr>
          </thead>
          <tbody>
            {this.props.records.map(record => (
              <Record key={record.candidateId}>
                <Name>
                  {record.firstName} {record.lastName}
                </Name>
                <Amount>{this.formatAmount(record)}</Amount>
                <DateTime>{this.formatDate(record)}</DateTime>
              </Record>
            ))}
          </tbody>
        </table>
      )
    }
  }

  public render() {
    return (
      <Modal closeModal={() => this.props.onClose()} hasSubmit={false}>
        {this.props.loading ? (
          <Wrapper>
            <p>Loading...</p>
          </Wrapper>
        ) : (
          <Wrapper>
            <ContentWrapper>
              {this.emptyList()}
              {this.dataTable()}
            </ContentWrapper>
          </Wrapper>
        )}
      </Modal>
    )
  }
}
