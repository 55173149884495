import { inject, injectable } from 'inversify'
import { action, computed } from 'mobx'
import 'reflect-metadata'
import { ICompany, ISelectedCompany } from '../ICompany'
import { CompanyListRepository } from './CompanyListRepository'
import { EditCompanyPresenter } from '../EditCompany/EditCompanyPresenter'
import { DeleteCompanyPresenter } from '../DeleteCompany/DeleteCompanyPresenter'
import { IPaginationDTO, IPaginationResponseDTO } from 'gateways/service/stubs/dtos'
import { LoaderPresenter } from 'app/Shared/Loader/LoaderPresenter'
import { toast } from 'react-toastify'
import moment from 'moment'
import { ManageCompaniesPresenter } from '../ManageCompaniesPresenter'
import { ManageRecruiterRepository } from 'app/ManageRecruiters/ManageRecruiterRepository'
import { TAuthUserRole } from 'app/Authentication/TAuthUserRole'
import { DashboardViews } from 'app/Routing/TAvailableView'
const jwt = require('jsonwebtoken')

@injectable()
export class CompanyListPresenter {
  @inject(CompanyListRepository)
  private companyListRepository: CompanyListRepository

  @inject(EditCompanyPresenter)
  private editCompanyPresenter: EditCompanyPresenter

  @inject(DeleteCompanyPresenter)
  private deleteCompanyPresenter: DeleteCompanyPresenter

  @inject(LoaderPresenter)
  private loaderPresenter: LoaderPresenter

  @inject(ManageCompaniesPresenter)
  private manageCompaniesPresenter: ManageCompaniesPresenter

  @inject(ManageRecruiterRepository)
  private manageRecruiterRepository: ManageRecruiterRepository

  @computed
  public get companyList(): ICompany[] {
    return this.companyListRepository.companyList
  }

  @computed
  public get pagination(): IPaginationResponseDTO {
    return this.companyListRepository.pagination
  }

  @computed
  public get company(): ISelectedCompany[] {
    return this.companyListRepository.companyList.map(c => {
      const formattedDate = moment(c.create_date).format('DD/MM/YYYY')

      return {
        name: c.name,
        firstName: c.admin ? c.admin.firstName : '',
        lastName: c.admin ? c.admin.lastName : '',
        email: c.admin ? c.admin.email : '',
        createDate: formattedDate,
        id: c.id,
        admin_id: c.admin_id
      }
    })
  }

  @action
  public handleCompanyEditButton(company: ISelectedCompany) {
    this.editCompanyPresenter.toggle(company);
  }

  @action
  public handleOnDeleteIconClick(company: ISelectedCompany) {
    this.deleteCompanyPresenter.toggle(company);
  }

  @action
  public async handleOnViewAsClick(company: ISelectedCompany, view: DashboardViews) {
    if (!company.admin_id) {
      toast.error("Please assign a admin to view...");
      return
    }
    this.loaderPresenter.showLoader()
    try {
      const tokenContext = {
        companyId: company.id,
        userId: company.admin_id,
        role: TAuthUserRole.COMPANY_ADMIN
      };
      const result = await this.companyListRepository.getViewAsToken(tokenContext)
      if (!result.success) return
      const token = result.token
      this.manageCompaniesPresenter.switchToNestedView({
        view,
        sourceView: DashboardViews.MANAGE_COMPANIES,
        token,
        tokenContext: jwt.decode(token),
        label: DashboardViews.MANAGE_COMPANIES
      })
      await this.manageRecruiterRepository.loadRecruiter(0, ManageRecruiterRepository.NUMBER_OF_ROWS_PER_PAGE)
    } catch (err) {
      toast.error(err.message)
    }
    this.loaderPresenter.hideLoader()
  }

  @action
  public async loadCompany(pagination: IPaginationDTO) {
    this.loaderPresenter.showLoader()
    this.companyListRepository.loadCompany(pagination.start, pagination.end)
      .then(() => { })
      .catch(err => {
        toast.error(err.message)
      }).finally(() => {
        this.loaderPresenter.hideLoader()
      })
  }
}