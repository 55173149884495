import HeaderComponent from 'app/Header/HeaderComponent'
import { observer } from 'mobx-react'
import React from 'react'
import styled, { css } from 'styled-components'
import RecruiterStats from './RecruiterStats/RecruiterStats'
import OverviewComponent from './OverviewStats/OverviewComponent'
import { INestedViewComponentProps, IPresenter } from 'config/Config'
import { CompanyStatsPresenter } from './CompanyStatsPresenter'
import { withPresenter } from 'app/withPresenter'
import MonthlyStats from './MonthlyStats/MonthlyStats'
import { DashboardViews } from 'app/Routing/TAvailableView'
import { StepsAndJobsWrapper } from 'styles'
import StepsComponent from 'app/Steps/StepsPanel/StepsComponent'
import RightSidePanelWrapper from 'app/RightSidePanel/RightSidePanelWrapper'

const MainWrapper = styled.div<INestedViewComponentProps>`
  padding: 20px;
  height: 100%;

  ${props =>
    props.isNestedView &&
    css`
      height: 88%;
    `}
`

@observer
class CompanyStatesComponent extends React.Component<
  IPresenter<CompanyStatsPresenter> & INestedViewComponentProps
> {
  componentDidMount(): void {
    this.props.presenter.sync()
  }

  render(): React.ReactNode {
    const { activeView, nestedViewContext } = this.props.presenter
    const isNestedView = this.props.isNestedView || nestedViewContext

    return (
      <>
        {!isNestedView && <HeaderComponent />}
        {activeView === DashboardViews.MANAGER_DASHBOARD && (
          <MainWrapper isNestedView={this.props.isNestedView}>
            <OverviewComponent />
            <RecruiterStats />
            <MonthlyStats />
          </MainWrapper>
        )}
        {activeView === DashboardViews.MY_DASHBOARD && (
          <StepsAndJobsWrapper>
            <StepsComponent isNestedView />
            <RightSidePanelWrapper isNestedView />
          </StepsAndJobsWrapper>
        )}
      </>
    )
  }
}

export default withPresenter<INestedViewComponentProps>(CompanyStatsPresenter)(
  CompanyStatesComponent
)
