import * as React from 'react'
import Lottie from 'react-lottie'

import { Modal } from 'app/Shared/Modal/Modal'
import styled from 'styled-components'
import { colors } from 'styles/colors'

// @ts-ignore
import * as coinAnimation from 'assets/animations/coin.json'

const Wrapper = styled.div`
  color: ${colors.darkText};
  min-width: 400px;
  max-height: 600px;

  button {
    margin: 24px auto;
  }
`

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled.h2`
  margin: 20px 0;
  text-align: center;
`

const PrizeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 20px 20px;
  justify-content: center;
  align-items: center;
`

const LottieWrapper = styled.div``

const MessageWrapper = styled.div`
  text-align: center;
  font-size: 20px;
`

const Coins = styled.span``

interface IProps {
  amount: number

  onClose: () => void
}

const iconSize = 128
const coinIsStopped = false

export class AdditionalCoinsDialog extends React.Component<IProps> {
  public render() {
    return (
      <Modal
        closeModal={() => this.props.onClose()}
        saveButtonText="COLLECT"
        onSaveClick={() => this.props.onClose()}
      >
        <Wrapper>
          <HeaderWrapper>
            <Title>Your reward</Title>
          </HeaderWrapper>

          <PrizeWrapper>
            <LottieWrapper>
              <Lottie
                options={{
                  loop: false,
                  autoplay: true,
                  animationData: coinAnimation.default
                }}
                height={iconSize}
                width={iconSize}
                isStopped={coinIsStopped}
              />
            </LottieWrapper>

            <MessageWrapper>
              You have received
              <Coins> {this.props.amount} </Coins>
              additional coins!
            </MessageWrapper>
          </PrizeWrapper>
        </Wrapper>
      </Modal>
    )
  }
}
