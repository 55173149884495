import * as React from 'react'
import Select from 'react-select'
import { colors } from 'styles/colors'

export interface DropdownOption<T = any> {
  label: string
  value: T
}
interface IProps {
  options: DropdownOption[]
  onSelect: (opt: DropdownOption) => void
  default?: DropdownOption
  borderTop?: string
  borderRight?: string
  borderBottom?: string
  borderLeft?: string
}
export class Dropdown extends React.Component<IProps> {
  render(): React.ReactNode {
    return (
      <Select
        styles={{
          control: baseStyles => ({
            ...baseStyles,
            backgroundColor: colors.white,
            borderTop: this.props.borderTop || colors.white,
            borderRight: this.props.borderRight || colors.white,
            borderLeft: this.props.borderLeft || colors.white,
            borderBottom: this.props.borderBottom || colors.white,
            fontSize: '13px',
            marginBottom: '16px',
            minHeight: '35px'
          }),
          option: baseStyles => ({
            ...baseStyles,
            color: 'hsl(0, 0%, 20%)',
            fontSize: '13px'
          }),
          indicatorSeparator: styles => ({
            display: 'none'
          })
        }}
        defaultValue={this.props.default}
        onChange={item => this.props.onSelect(item)}
        options={this.props.options}
        isSearchable={true}
        theme={theme => {
          return {
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: '#F1F9FC',
              primary: '#F1F9FC'
            }
          }
        }}
      />
    )
  }
}
