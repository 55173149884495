import { observer } from 'mobx-react'
import React, { CSSProperties } from 'react'
import styled, { css } from 'styled-components'
import { colors } from 'styles/colors'
// @ts-ignore
import * as forwardArrow from 'assets/forwardArrow.svg'
// @ts-ignore
import * as backwardArrow from 'assets/backwaredArrow.svg'
import { IPresenter } from 'config/Config'
import { withPresenter } from 'app/withPresenter'
import { MonthlyStatsPresenter } from './MonthlyStatsPresenter'
import moment from 'moment'

const StatsWrapper = styled.div`
  min-height: 43%;
  max-height: 43%;
  border: 1px solid #e6e6e6;
  padding: 10px;
  border-radius: 8px;
`

const StatsTitle = styled.div`
  font-size: 20px;
  color: #000143;
  margin: 8px 0;
`

const StatsChartContainer = styled.div`
  height: 80%;
  position: relative;
`

const StatsContainerItem = styled.div<CSSProperties>`
  display: flex;
  height: 50px;
  width: 100%;
  align-items: center;

  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `}
`

const StatsLabel = styled.div<CSSProperties>`
  flex: 0 0 200px;
  align-self: end;

  ${props =>
    props.alignSelf &&
    css`
      align-self: ${props.alignSelf};
    `}
`

const StatsEmptySpace = styled.div`
  flex: 0 0 100px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  img {
    width: 32px;
    height: 32px;
    background: ${colors.white};
    border-radius: 20px;
    border: 1.5px solid rgba(0, 0, 0, 0.1);
  }
`

const StatsBar = styled.div<CSSProperties>`
  flex: 1 0 102px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  font-size: small;
`

const StatsInfo = styled.div`
  justify-self: center;
  flex: 0 0 10%;
`
const StatsValue = styled.div<CSSProperties>`
  flex: 1;
  max-height: 80%;
  width: 100%;
  border-bottom: 1px solid rgba(0, 1, 67, 0.5);
  display: flex;

  ${props =>
    props.height &&
    css`
      max-height: ${Number(props.height) || 0}%;
    `}

  div {
    min-height: 100%;
    background: ${colors.mainBg};
    width: 102px;
    margin: 0 auto;
    border-radius: 8px 8px 0 0;
    align-self: end;
  }
`
const StatsMonth = styled.div`
  flex: 0 0 10%;
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  margin-top: 12px;
`

@observer
class MonthlyStats extends React.Component<IPresenter<MonthlyStatsPresenter>> {
  render(): React.ReactNode {
    const { activeStats, topBilling, activeViewIndex } = this.props.presenter
    return (
      <StatsWrapper>
        <StatsTitle>Monthly Stats</StatsTitle>
        <StatsChartContainer>
          <StatsContainerItem height={'150px'}>
            <StatsLabel alignSelf="center">Billing</StatsLabel>
            <StatsEmptySpace>
              <img
                src={backwardArrow}
                onClick={() => {
                  this.props.presenter.switchViewTo(activeViewIndex - 1)
                }}
              />
            </StatsEmptySpace>
            {activeStats.map((item, index) => {
              const percentage = (item.billing / topBilling) * 100
              return (
                <StatsBar key={index}>
                  <StatsInfo> £{item.billing.toLocaleString()} </StatsInfo>
                  <StatsValue height={percentage.toString()}>
                    <div />
                  </StatsValue>
                  <StatsMonth>
                    {moment(item.identifier, 'YYYYMM').format("MMM'YY")}{' '}
                  </StatsMonth>
                </StatsBar>
              )
            })}
            <StatsEmptySpace>
              <img
                src={forwardArrow}
                onClick={() => {
                  this.props.presenter.switchViewTo(activeViewIndex + 1)
                }}
              />
            </StatsEmptySpace>
          </StatsContainerItem>

          <StatsContainerItem>
            <StatsLabel>Placements</StatsLabel>
            <StatsEmptySpace />
            {activeStats.map((item, index) => {
              return <StatsBar key={index}>{item.cvPlaced}</StatsBar>
            })}
            <StatsEmptySpace />
          </StatsContainerItem>

          <StatsContainerItem>
            <StatsLabel>Interviews</StatsLabel>
            <StatsEmptySpace />
            {activeStats.map((item, index) => {
              return <StatsBar key={index}>{item.interviews || 0}</StatsBar>
            })}
            <StatsEmptySpace />
          </StatsContainerItem>

          <StatsContainerItem>
            <StatsLabel>CVs</StatsLabel>
            <StatsEmptySpace />
            {activeStats.map((item, index) => {
              return <StatsBar key={index}>{item.cvSubmits}</StatsBar>
            })}
            <StatsEmptySpace />
          </StatsContainerItem>
        </StatsChartContainer>
      </StatsWrapper>
    )
  }
}

export default withPresenter(MonthlyStatsPresenter)(MonthlyStats)
