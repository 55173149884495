import { AuthenticationRepository } from "app/Authentication/AuthenticationRepository";
import { ICompany } from "app/ManageComapnies/ICompany";
import { CompanyListRepository } from "app/ManageComapnies/CompanyList/CompanyListRepository";
import { StatusRepository } from 'app/StatusBar/StatusRepository';
import { IAnalytics } from 'gateways/service/stubs/dtos';
import { inject, injectable } from 'inversify';
import { action, computed, observable } from 'mobx';
import moment from 'moment';
import 'reflect-metadata';
import { AdminDashboardRepository } from './AdminDashboardRepository';

@injectable()
export class AdminDashboardPresenter {

  @inject(AuthenticationRepository)
  private authenticationRepository: AuthenticationRepository

  @inject(AdminDashboardRepository)
  private adminDashboardRepository: AdminDashboardRepository

  @inject(CompanyListRepository)
  private manageCompanyRepository: CompanyListRepository

  @inject(StatusRepository)
  private statusRepository: StatusRepository

  @observable
  public formattedDate: string = moment().format('dddd, Do MMM YYYY')

  @computed
  public get userName() {
    let {firstName, lastName} = this.authenticationRepository.user
    let name = `${firstName} ${lastName.charAt(0)}`
    if(name.length > 14) {
      name = `${name.slice(0, 11)}...`
    }
    return name
  }

  @computed
  public get userId(){
    let { username } = this.authenticationRepository.user
    return username
  }

  @computed
  public get analytics(): IAnalytics {
    return this.adminDashboardRepository.analytics
  }

  @computed
  public get companyList(): ICompany[] {
    return this.manageCompanyRepository.companyList
  }

  @action
  public async reloadAnalytics() {
    await this.adminDashboardRepository.fetchAnalytics();
  }

  public get badges() {
    return this.statusRepository.badges
  }

  public get level() {
    return this.statusRepository.level
  }

  public async getUserBadgesHistory() {
    return this.statusRepository.getUserBadgesHistory()
  }
}
