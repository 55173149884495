import { AbstractServiceGateway } from 'gateways/service/AbstractServiceGateway'
import { IBaseResponseDTO, ICompanyResponseDto, ICompanyUsersResponseDTO, IEditCompanyRequestDTO, IPaginationResponseDTO } from 'gateways/service/stubs/dtos'
import { inject, injectable, postConstruct } from 'inversify'
import { action, observable } from 'mobx'
import { ICompany } from '../ICompany'
import { DeleteActions } from '../DeleteCompany/DeleteCompanyPresenter'
import { toast } from 'react-toastify'
import { ITokenContext } from 'app/Authentication/IAuthUser'


@injectable()
export class CompanyListRepository {
  static NUMBER_OF_ROWS_PER_PAGE = 15

  @inject(AbstractServiceGateway)
  private serviceGateway: AbstractServiceGateway


  @observable
  public companyList: ICompany[] = []

  @observable
  public pagination: IPaginationResponseDTO

  @observable
  public filterCompanyName = ""

  @action
  public updateCompanyFilter(value: string) {
    this.filterCompanyName = value
  }

  public getCompany(id: number): ICompany {
    return this.companyList.find(i => i.id == id)
  }

  @action
  public async loadCompany(start?: number, end?: number) {
    let isReload = !Boolean(start || end)
    if (isReload && this.pagination) {
      start = this.pagination.active.start
      end = this.pagination.active.end
    }
    let myParams: any = { start, end }
    if (this.filterCompanyName)
      myParams.companyName = this.filterCompanyName
    let parems = new URLSearchParams(myParams).toString();
    const response = await this.serviceGateway.get<ICompanyResponseDto>(
      `/culabr-admin/company?${parems}`
    )
    if (response.success) {
      this.companyList = response.result.data
      this.pagination = response.result.pagination
    } else toast.error(response.message)
  }

  @action
  public async createCompany(companyName: string, adminFirstName: string, adminLastName: string, adminEmailAddress: string): Promise<ICompanyResponseDto<ICompany>> {
    const company = { companyName, adminFirstName, adminLastName, adminEmailAddress }
    const result = await this.serviceGateway.post<ICompanyResponseDto<ICompany>>(
      '/culabr-admin/company',
      company
    )
    if (result.success) await this.loadCompany()
    return result
  }

  @action
  public async deleteCompany(company: ICompany, deleteAction: DeleteActions) {
    const result = await this.serviceGateway.post<ICompanyResponseDto>(
      '/culabr-admin/company/delete',
      {
        "companyId": company.id,
        "action": deleteAction
      }
    )
    if (result.success) await this.loadCompany()
    return result
  }

  @action
  public async fetchRecruiters(company: ICompany) {
    return await this.serviceGateway
      .get<ICompanyUsersResponseDTO>(
        `/culabr-admin/company/users?companyId=${company.id}`
      );
  }

  @action
  public async editCompany(payload: IEditCompanyRequestDTO) {
    const result = await this.serviceGateway.post<IBaseResponseDTO>(
      '/culabr-admin/company/edit',
      payload
    )
    if (result.success) await this.loadCompany()
    return result
  }

  @action
  public async getViewAsToken(payload: Partial<ITokenContext>) {
    return await this.serviceGateway.post<IBaseResponseDTO>(
      '/culabr-admin/company/token',
      payload
    )
  }
}
