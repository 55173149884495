import { observable, computed, action } from 'mobx'
import { STEP_CANDIDATE_LIMIT } from 'config/constants'
import { ActionsRepository } from 'app/Actions/ActionsRepository'
import { AuthenticationRepository } from 'app/Authentication/AuthenticationRepository'
import { StepsRepository } from 'app/Steps/StepsRepository'
import { ICandidate } from './ICandidate'
import { injectable, inject } from 'inversify'
import 'reflect-metadata'
import { AbstractServiceGateway } from 'gateways/service/AbstractServiceGateway'
import { container } from 'config/IOC'
import { IGetCandidateResponseDto } from 'gateways/service/stubs/dtos'
import { StatusRepository } from 'app/StatusBar/StatusRepository'
import { IBaseResponse } from 'gateways/service/IBaseResponse'
import { JobsRepository } from 'app/RightSidePanel/Jobs/JobsRepository'

@injectable()
export class CandidatesRepository {

  @inject(AuthenticationRepository)
  private authenticationRepository: AuthenticationRepository

  @inject(ActionsRepository)
  private actionsRepository: ActionsRepository

  @inject(AbstractServiceGateway)
  private serviceGateway: AbstractServiceGateway

  @inject(StatusRepository)
  private coinsRepository: StatusRepository

  @inject(StepsRepository)
  private stepsRepository: StepsRepository

  @inject(JobsRepository)
  private jobsRepository: JobsRepository

  @observable
  public candidates: ICandidate[] = []

  @observable
  public reloadActions: boolean

  @computed
  public get canAdd(): boolean {
    return container.get(StepsRepository).steps[0].candidates.length < STEP_CANDIDATE_LIMIT
  }

  @action
  public deleteCandidate = async (candidateId: string) => {
    const { stepId } = container.get(StepsRepository).steps.find(step => {
      return !!step.candidates.find(
        candidate => candidate.candidateId === candidateId
      )
    })
    container.get(StepsRepository).steps = container.get(StepsRepository).steps.map(step => {
      if (stepId !== step.stepId) return step

      const candidates = step.candidates.filter(candidate => {
        return candidate.candidateId !== candidateId
      })

      return { ...step, candidates }
    })
    await this.actionsRepository.deleteActionsForCandidate(candidateId)
    await this.serviceGateway.delete<any>(`/delete-candidate?candidateId=${candidateId}`)
    await this.loadLatestStepsAndJobs()
    await this.coinsRepository.updateCoinCount()
  }

  public loadLatestStepsAndJobs = async (reloadActions = false) => {
    await this.jobsRepository.loadJobs();
    await this.stepsRepository.loadSteps(
      this.authenticationRepository.authenticated,
      this.authenticationRepository.user.workflowId
    )
    this.reloadActions = reloadActions;
    setTimeout(() => {
      this.reloadActions = false
    }, 300)
  }

  public addCandidate = async (firstName: string, lastName: string, jobIdentifier: string, startStepId: string) => {
    if (this.canAdd) {
      const createCandidateDto = { firstName, lastName, startStepId, jobIdentifier }
      await this.serviceGateway.post<IGetCandidateResponseDto>(
        '/create-candidate',
        createCandidateDto
      )
      await this.loadLatestStepsAndJobs()
      /* Avoid candidate manual push to stage - instant reload it from database.
      const { candidateId, candidateColor, stepId } = response.result[ 0 ]
      const candidate: ICandidate = { candidateId, firstName, lastName, jobIdentifier, candidateColor, stepId }
      container.get(StepsRepository).initiateCandidate(candidate)*/
      await this.coinsRepository.updateCoinCount()
    }
  }

  public addCandidateWithJobId = async (firstName: string, lastName: string, jobId: number, startStepId: string) => {
    if (this.canAdd) {
      const createCandidateDto = { firstName, lastName, startStepId, jobId }
      await this.serviceGateway.post<IGetCandidateResponseDto>(
        '/create-candidate',
        createCandidateDto
      )
      await this.loadLatestStepsAndJobs()
      /* Avoid candidate manual push to stage - instant reload it from database.
      const { candidateId, candidateColor, stepId } = response.result[ 0 ]
      const candidate: ICandidate = { candidateId, firstName, lastName, jobIdentifier, candidateColor, stepId }
      container.get(StepsRepository).initiateCandidate(candidate)*/
      await this.coinsRepository.updateCoinCount()
    }
  }

  public listCandidates = async (status?: string) => {
    const args = Object
      .entries({
        workflowId: this.authenticationRepository.user.workflowId,
        status
      })
      .filter(([_, value]) => value !== undefined)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join('&')

    const response = await this.serviceGateway.get<IBaseResponse<ICandidate[]>>(
      `/list-candidates?${args}`
    )

    if (response.success) {
      return response.result
    } else {
      throw new Error(response.message)
    }
  }

  public editCandidate = async (
    firstName: string,
    lastName: string,
    jobIdentifier: string | number,
    workflowId: string,
    candidateId: string,
    stepName: string
  ) => {
    await this.serviceGateway.post<IBaseResponse<boolean>>(
      '/edit-candidate',
      {
        firstName: firstName,
        lastName: lastName,
        jobIdentifier: jobIdentifier,
        candidateId: candidateId,
        workflowId: workflowId
      }
    )
    const updatedCandidate = await this.findCandidateById(candidateId, workflowId, stepName)
    await this.loadLatestStepsAndJobs()
    container.get(StepsRepository).updateCandidate(updatedCandidate)
  }

  public findCandidateById = async (candidateId: string, workflowId: string, stepName: string = ""): Promise<ICandidate> => {
    const response = await this.serviceGateway.get<IBaseResponse<ICandidate>>(
      `/find-candidate?candidateId=${candidateId}&workflowId=${workflowId}&stepName=${stepName}`
    )

    if (response.success) {
      return response.result[0]
    } else {
      throw new Error(response.message)
    }
  }
}

