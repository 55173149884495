import { inject, injectable, postConstruct } from 'inversify'
import { action, computed, observable, reaction } from 'mobx'
import 'reflect-metadata'
import { JobsRepository } from './JobsRepository'
import { IJob } from './IJobs'
import { AuthenticationRepository } from 'app/Authentication/AuthenticationRepository'
import { StatusRepository } from 'app/StatusBar/StatusRepository'

@injectable()
export class JobsListPresenter {
  @inject(JobsRepository)
  private jobsRepository: JobsRepository

  @inject(StatusRepository)
  private statusRepository: StatusRepository

  @inject(AuthenticationRepository)
  private authenticationRepository: AuthenticationRepository

  @observable
  public filteredJobId: number

  @observable
  public isCandidateHoverClearLocked: boolean = false

  @postConstruct()
  public init() {
    reaction(
      () => this.authenticationRepository.tokenContext,
      () => {
        this.filteredJobId = undefined
        this.isCandidateHoverClearLocked = false
      }
    )
  }

  @action
  public updateJobFilterId(jobId: number, lockHoverClear = false) {
    this.filteredJobId = Number(jobId)
    this.isCandidateHoverClearLocked = lockHoverClear
  }

  @computed
  public get jobs(): IJob[] {
    return this.jobsRepository.jobs
  }

  public async createJob(name: string): Promise<string> {
    const result = await this.jobsRepository.createJob(name)
    if (!result.success) return result.message
    await this.statusRepository.updateCoinCount()
    return ''
  }

  public async updateJob(job: IJob): Promise<string> {
    const result = await this.jobsRepository.updateJob(job)
    if (!result.success) return result.message
    return ''
  }

  public async deleteJob(job: IJob): Promise<string> {
    const result = await this.jobsRepository.deleteJob(job)
    if (!result.success) return result.message
    return ''
  }
}
