import {
  AbstractClassValidator,
  MAX_LENGTH,
  MIN_LENGTH,
  validated
} from '@logicroom/validator'
import { ITextInput } from 'app/Shared/TextInput/ITextInput'
import { inject, injectable } from 'inversify'
import { upperFirst } from 'lodash'
import { action, computed, observable } from 'mobx'
import { IRecruiter, ISelectedRecruiter } from '../IRecruiter'
import { ManageRecruiterRepository } from '../ManageRecruiterRepository'
import {
  IAddRecruiterRequestDTO,
  ICulabrAdminEditRecruiterRequestDTO,
  IEditRecruiterRequestDTO
} from 'gateways/service/stubs/dtos'
import { LoaderPresenter } from 'app/Shared/Loader/LoaderPresenter'
import { toast } from 'react-toastify'
import { AuthenticationRepository } from 'app/Authentication/AuthenticationRepository'
import { ITokenContext } from 'app/Authentication/IAuthUser'
import { CompanyOptionsPresenter } from 'app/ManageComapnies/Shared/CompanyOptionsPresenter'
import {
  FREELANCE_COMPANY_NAME,
  TAuthUserRole
} from 'app/Authentication/TAuthUserRole'

@injectable()
export class AddRecruiterPresenter extends AbstractClassValidator {
  @inject(ManageRecruiterRepository)
  private manageRecruiterRepository: ManageRecruiterRepository

  @inject(LoaderPresenter)
  private loaderPresenter: LoaderPresenter

  @inject(AuthenticationRepository)
  private authenticationRepository: AuthenticationRepository

  @inject(CompanyOptionsPresenter)
  private companyOptionsPresenter: CompanyOptionsPresenter

  get mainTokenContext(): ITokenContext {
    return this.authenticationRepository.tokenContext
  }

  @observable
  public isOpen: boolean = false

  @observable
  public recruiter: IRecruiter = null

  @observable
  public isReleaseOptionsChecked: boolean = false

  @observable
  public selectedRecruiter: ISelectedRecruiter | null = null

  public setRecruiter(recruiter: ISelectedRecruiter | null) {
    this.selectedRecruiter = recruiter
    this.firstName.value = recruiter.firstName
    this.lastName.value = recruiter.lastName
    this.emailAddress.value = recruiter.email
    this.annualTarget.value = '£' + recruiter.annualTarget
    this.companyName.value = recruiter.companyName
  }

  @action
  public updateReleaseOption(checked: boolean) {
    this.isReleaseOptionsChecked = checked
  }

  @observable
  public serverErrors = []

  @validated({
    mandatory: true,
    rules: [
      {
        rule: MAX_LENGTH(20),
        errorMessage: 'First name too long, up to 20 characters allowed.'
      },
      {
        rule: MIN_LENGTH(2),
        errorMessage: 'First name must be at least 3 characters long.'
      }
    ]
  })
  public firstName: ITextInput = {
    placeholder: 'First Name',
    label: 'First Name'
  }

  @validated({
    mandatory: true,
    rules: [
      {
        rule: MAX_LENGTH(20),
        errorMessage: 'Last name too long, up to 20 characters allowed.'
      },
      {
        rule: MIN_LENGTH(2),
        errorMessage: 'Last name must be at least 3 characters long.'
      }
    ]
  })
  public lastName: ITextInput = {
    placeholder: 'Last Name',
    label: 'Last Name'
  }

  @validated({
    mandatory: true,
    rules: [
      {
        rule: value => {
          const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
          return emailRegex.test(value)
        },
        errorMessage: 'Invalid email format.'
      },
      {
        rule: MAX_LENGTH(255),
        errorMessage: 'Email Address too long, up to 255 characters allowed.'
      },
      {
        rule: MIN_LENGTH(2),
        errorMessage: 'Email Address should be at least 3 chars'
      }
    ]
  })
  public emailAddress: ITextInput = {
    placeholder: 'Email Address',
    label: 'Email Address'
  }

  @validated({
    mandatory: true,
    rules: [
      {
        rule: MAX_LENGTH(255),
        errorMessage: 'Company too long, up to 255 characters allowed.'
      },
      {
        rule: MIN_LENGTH(2),
        errorMessage: 'Company should be at least 3 chars'
      }
    ]
  })
  public companyName: ITextInput = {
    placeholder: 'type here',
    label: 'Company'
  }

  @validated({
    mandatory: true,
    rules: [
      {
        rule: value => {
          const numericValue = parseFloat(value.replace(/[^\d.]/g, ''))
          return (
            !isNaN(numericValue) && numericValue >= 0 && numericValue <= 1000000
          )
        },
        errorMessage:
          'Annual Target must be a valid number between 0 and 1,000,000.'
      },
      {
        rule: MIN_LENGTH(2),
        errorMessage: 'Annual Target should be at least 2 chars'
      }
    ]
  })
  public annualTarget: ITextInput = {
    placeholder: 'Annual Target',
    label: 'Annual Target'
  }

  get showCompanyOptions(): boolean {
    if (!this.selectedRecruiter) return false
    return (
      this.mainTokenContext.role === TAuthUserRole.SUPER_ADMIN &&
      this.selectedRecruiter.company.name === FREELANCE_COMPANY_NAME
    )
  }

  public cancel = async () => {
    this.isOpen = false
    this.firstName.value = ''
    this.lastName.value = ''
    this.emailAddress.value = ''
    this.annualTarget.value = ''
    this.companyName.value = ''
    this.selectedRecruiter = null
    this.isReleaseOptionsChecked = false
    this.companyOptionsPresenter.reset()
  }

  public toggle = () => {
    this.isOpen = !this.isOpen
  }

  @action
  public upperFirstName = (value): void => {
    this.firstName.onChange(upperFirst(value))
  }

  @action
  public upperLastName = (value): void => {
    this.lastName.onChange(upperFirst(value))
  }

  @action
  public upperEmailAddress = (value): void => {
    this.emailAddress.onChange(value)
  }

  @action
  public upperAnnualTarget = (value): void => {
    const numericValue = parseFloat(value.replace(/[^0-9.]/g, ''))

    if (!isNaN(numericValue)) {
      const formattedValue = `£${numericValue.toLocaleString()}`
      this.annualTarget.onChange(formattedValue)
    } else {
      this.annualTarget.onChange(value)
    }
  }

  get annualTargetNumericValue() {
    return parseFloat(this.annualTarget.value.replace(/[^0-9.]/g, ''))
  }

  @action
  public upperCompanyName = (value): void => {
    this.companyName.onChange(upperFirst(value))
  }

  @computed
  public get updateFirstName() {
    return { ...this.firstName, onChange: this.upperFirstName }
  }

  @computed
  public get updateLastName() {
    return { ...this.lastName, onChange: this.upperLastName }
  }

  @computed
  public get updateEmailAddress() {
    return { ...this.emailAddress, onChange: this.upperEmailAddress }
  }

  @computed
  public get updateAnnualTarget() {
    return { ...this.annualTarget, onChange: this.upperAnnualTarget }
  }

  @computed
  public get updateCompanyName() {
    return { ...this.companyName, onChange: this.upperCompanyName }
  }

  @computed
  public get canSubmit(): boolean {
    return (
      this.firstName.isValid &&
      this.lastName.isValid &&
      this.emailAddress.isValid &&
      this.annualTarget.isValid
    )
  }

  public async culabrAdminSubmitEdit() {
    const companyPayload = this.companyOptionsPresenter.toJSON()
    let payload: ICulabrAdminEditRecruiterRequestDTO = {
      firstName: this.firstName.value,
      lastName: this.lastName.value,
      annualTarget: Number(this.annualTargetNumericValue) || 0,
      id: this.selectedRecruiter.id,
      companyId: companyPayload.companyId,
      companyName: companyPayload.companyName
    }
    this.loaderPresenter.showLoader()
    try {
      const result = await this.manageRecruiterRepository.culabrAdminEditRecruiter(
        payload
      )
      if (!result.success) toast.error(result.message)
    } catch (err) {
      toast.error(err.message)
    }
    this.cancel()
    this.loaderPresenter.hideLoader()
  }

  public submitEdit = async () => {
    if (this.showCompanyOptions) return this.culabrAdminSubmitEdit()
    let payload: IEditRecruiterRequestDTO = {
      firstName: this.firstName.value,
      lastName: this.lastName.value,
      annualTarget: Number(this.annualTargetNumericValue) || 0,
      id: this.selectedRecruiter.id,
      isReleaseOptionsChecked: this.isReleaseOptionsChecked
    }
    this.loaderPresenter.showLoader()
    try {
      const result = await this.manageRecruiterRepository.editRecruiter(payload)
      if (!result.success) toast.error(result.message)
    } catch (err) {
      toast.error(err.message)
    }
    this.cancel()
    this.loaderPresenter.hideLoader()
  }

  public submit = async () => {
    let payload: IAddRecruiterRequestDTO = {
      firstName: this.firstName.value,
      lastName: this.lastName.value,
      annualTarget: Number(this.annualTargetNumericValue) || 0,
      email: this.emailAddress.value
    }
    this.loaderPresenter.showLoader()
    try {
      const result = await this.manageRecruiterRepository.addRecruiter(payload)
      if (!result.success) toast.error(result.message)
    } catch (err) {
      toast.error(err.message)
    }
    this.cancel()
    this.loaderPresenter.hideLoader()
  }
}
