import LeaderBoardList from 'app/Actions/LeaderBoardList/LeaderBoardList'
import { withPresenter } from 'app/withPresenter'
import {
  LOGO_HEADER_HEIGHT,
  STEP_AND_ACTION_HEADER_HEIGHT
} from 'config/constants'
import { observer } from 'mobx-react'
import React from 'react'
import styled from 'styled-components'
import { colors } from 'styles/colors'
import JobsList from './Jobs/JobsList'
import { RightSidePanelWrapperPresenter } from './RightSidePanelWrapperPresenter'
import { INestedViewContextProps } from 'app/Header/IModel'

const Wrapper = styled.div`
  background-color: ${colors.white};
`

const Tabs = styled.div`
  display: flex;
  height: ${STEP_AND_ACTION_HEADER_HEIGHT}px;

  div {
    padding: 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    text-align: center;

    &:hover {
      cursor: pointer;
      background: ${colors.hoverBg};
      color: ${colors.darkText} !important;
    }
  }
`

const Jobs = styled.div`
  flex: 1;
`

const Leaderboard = styled.div`
  flex: 1;
`
const TOP_HEIGHT = LOGO_HEADER_HEIGHT + STEP_AND_ACTION_HEADER_HEIGHT
const Content = styled.div`
  height: calc(100vh - ${TOP_HEIGHT}px);
  max-height: calc(100vh - ${TOP_HEIGHT}px);
  overflow-y: auto;
`

interface IProps {
  presenter: RightSidePanelWrapperPresenter
}

@observer
class RightSidePanelWrapper extends React.Component<
  IProps & INestedViewContextProps
> {
  render(): React.ReactNode {
    const jobStyle = {
      color: this.props.presenter.showJobsPanel
        ? colors.lightText
        : colors.unFocus,
      borderBottomColor: this.props.presenter.showJobsPanel
        ? colors.borderFocus
        : colors.border
    }
    const leaderStyle = {
      color: !this.props.presenter.showJobsPanel
        ? colors.lightText
        : colors.unFocus,
      borderBottomColor: !this.props.presenter.showJobsPanel
        ? colors.borderFocus
        : colors.border
    }
    return (
      <Wrapper>
        <Tabs>
          <Jobs onClick={this.props.presenter.showJobsTab} style={jobStyle}>
            Jobs ({this.props.presenter.jobsCount})
          </Jobs>
          {/* {!this.props.isNestedView && ( */}
          <Leaderboard
            onClick={this.props.presenter.showLeaderboardTab}
            style={leaderStyle}
          >
            Leaderboard
          </Leaderboard>
          {/* )} */}
        </Tabs>
        <Content>
          {this.props.presenter.showJobsPanel && (
            <JobsList isNestedView={this.props.isNestedView} />
          )}
          {!this.props.presenter.showJobsPanel && <LeaderBoardList />}
        </Content>
      </Wrapper>
    )
  }
}

export default withPresenter<INestedViewContextProps>(
  RightSidePanelWrapperPresenter
)(RightSidePanelWrapper)
