import { AbstractStorageGateway } from './AbstractStorageGateway'
import * as moment from 'moment'
import { injectable } from 'inversify'
import 'reflect-metadata'

@injectable()
export class CookieStorageGateway implements AbstractStorageGateway {
  private readCookies(): { [key: string]: string } {
    const result = {}
    document.cookie.split('; ').forEach(entry => {
      const [key, value] = entry.split('=')
      result[key] = value
    })
    return result
  }

  public setItem(key, value, daysToExpiry = 30) {
    const now = moment()
    const expiryDate = now.add(daysToExpiry, 'days')
    const expiryString = expiryDate.toDate()
    document.cookie = `${key}=${value}; expires=${expiryString}; path=/`
  }

  public getItem(key) {
    const cookie = this.readCookies()[key]
    if (!cookie) return null
    return cookie
  }

  public removeItem(key) {
    const daysPastExpiry = 1
    const now = moment()
    const expiryDate = now.subtract(daysPastExpiry, 'days')
    const expiryString = expiryDate.toDate()
    document.cookie = `${key}=; expires=${expiryString}; path=/`
  }
}
